<template>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="upload-content ">
        <div class="card-body upload-body" :class="{'no-height': !allowComment}">
          <div class="container upload-container" style="max-height: 350px;">
            <div class="column">
              <div class="bg-white upload-file"
                  :class="{'uploading': isUploadStarted }">
                <div
                    class="upload-drop-area"
                    @dragover.prevent="handleDragging"
                    @dragenter.prevent="handleDragging"
                    @dragleave.prevent="handleEndDragging"
                    @drop.prevent="handleDrop"
                >
                  <div class="col-12 upload-instructions-container">
                    <base-button
                        type="neutral"
                        rounded
                        @click="handleCreate"
                    >
                      <img src="/img/icons/common/folder.svg" class="image-icon"
                          @click="$refs['upload-file'].click()" />
                    </base-button>

                    <p class="upload-instruction" v-if="!imageOnly" >Drag and drop files here or upload. Max file size 5GB</p>
                    <p class="upload-instruction" v-else>Drag and drop files here or upload. Max image size 1MB</p>
                  </div>
                  <div class="col-12 upload-options-container">
                    <base-button
                        type="neutral"
                        rounded
                        @click="handleCreate"
                        v-if="allowAttachFile"
                    >
                      <input
                          type="file"
                          @change="handleFileChange"
                          class="d-none"
                          ref="upload-file"
                          :accept="acceptedFiles"
                          :multiple="allowMultipleFiles"
                          :disabled="isUploadStarted"
                      />
                      <img
                          src="/img/icons/common/clip.svg"
                          class="image-icon"
                          @click="$refs['upload-file'].click()"
                      />
                    </base-button>
                    <base-button
                        type="neutral"
                        rounded
                        @click="handleCreate"
                        v-if="allowLink"
                    >
                      <img src="/img/icons/common/link.svg" class="image-icon" />
                    </base-button>
                    <base-button
                        type="neutral"
                        rounded
                        @click="handleCreate"
                        v-if="allowGoogleLink"
                    >
                      <img src="/img/icons/common/drive.svg" class="image-icon" />
                    </base-button>
                  </div>
                </div>
                <template v-if="allowComment">  
                  <base-input
                      textarea
                      inputClasses="upload-comment-input"
                      label="Comment"
                      placeholder="Describe what changed"
                  />
                </template>
              </div>
              <div class="upload-file-list">
                <ul class="file-container no-height">
                  <li v-for="(file) in filtered_documents" :key="file.id">
                    <div class="file-item" >
                      <div class="file-item-head">
                          <div class="file-details">
                              <img class="file-type image-icon" :class="{'file-thumbnail': file.url}" :src="file.url || `/img/icons/common/${getIcon(file.type, file)}`" />
                              <div class="file-name">{{ getFileName(file) }}</div>
                          </div>

                          <div v-if="!file.time">
                              <base-button type="neutral" class="file-upload-action" rounded>
                                  <img
                                      class="image-icon"
                                      src="/img/icons/common/cancel.svg"
                                      v-if="file.percent <= 99 && file.status === 'in-progress' && !isFileCancelled(file)"
                                      @click.prevent="handleCancelUpload(file.id)"
                                  />
                                  <img
                                      class="image-icon"
                                      src="/img/icons/common/trash.svg"
                                      v-if="(file.percent === 0 && file.status === 'pending') || isFileCancelled(file)"
                                      @click.prevent="handleRemoveDocument(file.id)"
                                  />
                              </base-button>
                          </div>
                      </div>

                      <base-progress
                      type="success"
                      :show-percentage="false"
                      :class="`pt-0 file-upload-progress ${getProgressType(file.type, file)}`"
                      :value="file.percent"
                      v-if="!file.time"
                      />

                      <div class="file-upload-status" v-if="!file.time && isFileCancelled(file)">Cancelled{{ getDirectory2(file) }}</div>
                      <div class="file-upload-status" v-if="!file.time && !isFileCancelled(file) && file.percent === 0 && file.status === 'pending'">Pending{{ getDirectory2(file) }}</div>
                      <div class="file-upload-status" v-if="!file.time && !isFileCancelled(file) && file.percent <= 99 && file.status === 'in-progress'">In progress - {{ file.percent }}% done{{ getDirectory2(file) }}</div>
                      <div class="file-upload-status" v-if="!file.time && !isFileCancelled(file) && file.percent >= 100">Completed{{ getDirectory2(file) }}</div> 
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showButtons" class="card-footer d-flex justify-content-end bg-transparent">
          <div class="col col-xl-3 col-md-5">
            <base-button
                :link="false"
                type="neutral"
                textColor="default"
                class="btn-radius"
                block
                @click="handlleCloseDialog"
            >Close
            </base-button>
          </div>
          <div class="col col-xl-3 col-md-5">
            <base-button
                block
                type="success"
                class="btn-radius"
                @click="handleStartUpload"
            >{{ isUploadStarted ? 'Uploading' : 'Upload File' }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadDocument from '@/utils/upload';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import flattenDeep from 'lodash/flattenDeep';
import { v4 } from 'uuid';
import { mapState } from 'vuex';

const defaultState = () => ({
  isOpen: null,
  isUploadStarted: false,
  documents: []
});

export default {
  name: 'request-upload-container',
  components: {},
  props: {
    show: Boolean,
    title: {
      type: String,
      default: 'Attach File',
      description: 'Modal Footer css classes'
    },
    allowComment: {
      type: Boolean,
      default: false,
      description: 'show comment field or not'
    },
    allowAttachFile: {
      type: Boolean,
      default: true,
      description: 'should allow attachFile Link or not'
    },
    allowLink: {
      type: Boolean,
      default: false,
      description: 'should allow link or not'
    },
    allowGoogleLink: {
      type: Boolean,
      default: false,
      description: 'should allow google link or not'
    },
    maxFiles: {
      type: Number,
      default: 1,
      description: 'maximum numbers of files'
    },
    imageOnly: {
      type: Boolean,
      default: false,
      description: 'should allow only image type of file'
    },
    resource: {
      type: String,
      default: '',
      description: 'api node element | resource'
    },
    directory: {
      type: String,
      default: '',
      description: 'api node element | resource'
    },
    resource_id: {
      type: String,
      default: '',
      description: 'id of resource'
    },
    resource_type: {
      type: String,
      description: 'type of request either for file or profile label',
    },
    client_id: {
      type: String,
      default: '',
      description: 'need to filter the pending documents in global state'
    },
    batchId: {
      type: String,
      default: '',
      description: 'need to filter the pending documents in global state'
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  data: defaultState,
  methods: {
    getDirectory(val) {
      let dir = val.split('/')
      return val.replace(dir[0], '')
    },
    getDirectory2(file){
      if(file?.directories?.length > 1){
          const result = file.item.fullPath.replace(file.item.name, '')
          return `- ${result}`
      }  
      return ''
    },
    handleRemoveDocuments(fileId) {
      this.$store.commit('DOCUMENT/removeDocument', {
        fileId,
        client_id: this?.client_id,
        resource: this?.resource,
      });
    },
    handlleCloseDialog() {
      if (this.resource !== 'request') {
        this.filtered_documents.forEach(file_doc => (
            this.handleCancelUpload(file_doc?.id)
        ));

        this.handleRemoveDocuments();
      }

      this.isUploadStarted = false;
      this.$emit('toggle', false);
    },
    handleCreate() {

    },
    handleFileChange(event) {
      if (this.isUploadStarted)
        return;

        let files = [...event.target.files].filter(each => {
        const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
        if (this.imageOnly && !each.type && !re.exec(each?.name))
          return false;

        if (this.imageOnly && each.type && !each.type.includes('image'))
          return false;

        return true;
      }).filter((i, index) => (
          (this.filtered_documents.length + index + 1) <= this.maxFiles
      )).map(file => {
        const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
        return {
          file,
          type: file.type,
          percent: 0,
          status: 'pending',
          id: v4(),
          client_id: this.client_id,
          batchId: this.batchId,
          resource: this.resource,
          resource_id: this.resource_id,
          url: re.exec(file?.name) ? URL.createObjectURL(file) : null,
        }
      });
      console.log('files', files)
      if (files.length >= 1)
        this.$store.commit('DOCUMENT/setDocuments', this.$lodash.keyBy(files, 'id'));

      event.target.value = null;
    },
    handleDragging(event) {
      if (this.isUploadStarted)
        return;

      const element = event.target.closest('.upload-drop-area') || event.target;
      element.classList.add('upload-drop-area-dragging');
    },
    handleEndDragging(event) {
      if (this.isUploadStarted)
        return;

      const containers = ['upload-instructions-container', 'upload-options-container', 'upload-drop-area'];
      containers.forEach(each => {
        if ([...event.target.classList].includes(each)) {
          const element = event.target.closest('.upload-drop-area') || event.target;
          element.classList.remove('upload-drop-area-dragging');
        }
      });
    },
    handleRemoveDocument(fileId) {
      this.handleCancelUpload(fileId);
      this.handleRemoveDocuments(fileId);
    },
    handleCancelUpload(fileId) {
      const documents = cloneDeep(this?.filtered_documents || []);
      const index = findIndex(documents, { id: fileId });
      const file_doc = documents[index];

      if (file_doc) {
        file_doc?.upload?.cancelUpload();
        documents.splice(index, 1, { ...file_doc, status: 'cancelled' });
        this.$store.commit('DOCUMENT/setDocuments', this.$lodash.keyBy(documents, 'id'));
      }
    },
    async handleDrop(event) {
      if (this.isUploadStarted)
        return;

      this.handleEndDragging(event);
      let items = event.dataTransfer.items;
      items = [...items].filter((i, index) => (
          (this.filtered_documents.length + index + 1) <= this.maxFiles
      )).map(async item => (
          this.traverse(item.webkitGetAsEntry())
      ));
      
      let files = flattenDeep(await Promise.all(items));
      files = files.filter((i, index) => (
          (this.filtered_documents.length + index + 1) <= this.maxFiles
      )).filter(each => {
        const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
        if (this.imageOnly && !each.type && !re.exec(each?.name))
          return false;

        if (this.imageOnly && each.type && !each.type.includes('image'))
          return false;

        return true;
      });
      
      if (files.length >= 1)
        this.$store.commit('DOCUMENT/setDocuments', this.$lodash.keyBy(files, 'id'));
    },
    async handleStartUpload() {
      let files = this.filtered_documents.filter(each =>
          (each.percent === 0 && each.status === 'pending')
      );

      files = await Promise.all(files.map(async file_doc => {
        const file = file_doc?.file
            ? file_doc?.file
            : await new Promise(resolve => file_doc?.item?.file(file => resolve(file)));

            if(this.resource_type == 'profile' && file?.size > 1024 * 1024 ) {
              this.$notify({
                icon: 'fa fa-times',
                type: 'danger',
                title: 'Error',
                message: `Image size must be < 1MB`
              });
                  
              return {   status: 'error' };
            }  
            const  upload = new UploadDocument(file, {
                file: file_doc,
                id: file_doc.id,
                resource: this.resource,
                resource_id: file_doc.resource_id,
                resource_type: this.resource_type,
                onUpdatePercentage: this.handleUpdatePercentage(file_doc.id)
            });
          

            return { ...file_doc, upload, status: 'in-progress' };
      }))

      if(files?.status !== 'error') {
        this.isUploadStarted = true;
        this.$store.commit('DOCUMENT/setDocuments', this.$lodash.keyBy(files, 'id'));
      }
  },
    async traverse(item, directory = { path: '' }, directories = [directory]) {
      item = await item;
      
      if (Array.isArray(item))
        return Promise.all(item.map(each => this.traverse(each, directory, directories)));

      if (!Array.isArray(item) && item.isFile) {
        const file = await new Promise(resolve => item.file(file => resolve(file)));
        return {
          item,
          directory,
          directories,
          type: file.type,
          percent: 0,
          status: 'pending',
          id: v4(),
          client_id: this.client_id,
          batchId: this.batchId,
          resource: this.resource,
          resource_id: this.resource_id 
        };
      }

      if (!Array.isArray(item) && item.isDirectory) {
        const dir = item.createReader();
        const entries = await new Promise(resolve => dir.readEntries(entries => resolve(entries)));
        const path = {
          path: directory.path + (directory?.path ? '/' : '') + item.name
        };

        return this.traverse(entries, path, [...directories, path]);
      }
    },
    isFileCancelled(file_doc) {
      const cancel = !file_doc?.upload
          ? false
          : file_doc?.upload?.isUploadCancelled();

      return cancel;
    },
    getProgressType(type, file) {
      const name = this.getFileName(file);
      if ((type || name).includes('word'))
        return 'primary';

      if (name.includes('xlsx'))
        return 'success';

      if ((type || name).includes('image') || (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name))
        return 'pink';

      return 'green';
    },
    getIcon(type, file) {
      const name = this.getFileName(file);
      if ((type || name).includes('word'))
        return 'gdoc.svg';

      if (name.includes('xlsx'))
        return 'gsheet.svg';

      if ((type || name).includes('image') || (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(name))
        return 'image.svg';

      return 'glink.svg';
    },
    getFileName(file_doc) {
      return file_doc?.file?.name || file_doc?.item?.name || file_doc?.upload?.file?.name ||'';
    },

    handleUpdatePercentage(fileId) {
      return async (percent, s3)=> {
        // const documents = cloneDeep(this?.documents || [])
        const documents = cloneDeep(this?.filtered_documents || []);
        const index = findIndex(documents, { id: fileId });
        const file_doc = documents[index];
        const status = percent === 100 ? 'completed' : 'in-progress';

        if (percent >= 100){
          if(this.resource_type == 'profile' ) {
            if(this.resource == 'user') {
                this.$store.dispatch('USER/getUserData',  file_doc.resource_id);
              } else {
                this.$store.dispatch('CLIENT/getClientData',  file_doc.resource_id);
              }

          } else {
            const uploaded = this.uploaded_data
            uploaded.push({
              Key:  file_doc.upload.file.name,
              Size: file_doc.upload.file.size,
              LastModified: '',
              ETag: '',
              isFolder: false,
              Directory: file_doc.resource_id,
              uploader: s3[0]
            })
            this.$store.commit('DOCUMENT/setListData', uploaded);
            file_doc.file = null;
            }
        }
        
        documents.splice(index, 1, { ...file_doc, percent, status });
        this.$store.commit('DOCUMENT/setDocuments', this.$lodash.keyBy(documents, 'id'));
      };
    },
  },
  created() {
    this.$root.$refs.deliverableAssets = this;
  },
  computed: {
    ...mapState('REQUEST', {
      data: state => state.request,
    }),
    ...mapState('DOCUMENT', {
      request_documents: state => state.documents,
      uploaded_documents: state => state.data,
      current_directory: state => state.directory,
    }),
    filtered_documents() {
      let filtered = this.$lodash.values(cloneDeep(this.request_documents))?.filter(v => v).filter(({ client_id, resource }) => {
        return client_id === this.client_id && resource === this.resource;
      });
      return filtered
    },
    allowMultipleFiles() {
      return this.maxFiles > 1;
    },
    acceptedFiles() {
      return this.imageOnly ? 'image/*' : '*';
    }, 
    uploaded_data() {
      return this.uploaded_documents
    }
  },

  watch: {
    filtered_documents(latest) {
      const completed = (latest || [])
          .filter(file_doc => file_doc.status !== 'cancelled')
          .every(file_doc => file_doc.status === 'completed');

      if (this.isUploadStarted)
        this.isUploadStarted = !completed;

      if (this.show && (latest || []).length && completed) {
        setTimeout(() => {
          this.$emit('uploaded', cloneDeep(latest || []));

          if (this.maxFiles === 1) {
            this.$emit('toggle', false);
            // this.documents = []
            this.handleRemoveDocuments();
          }
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
  .no-height {
    height: unset !important;
    min-height: unset !important;
    max-height: unset !important;
  }

  .file-thumbnail {
    height: 4em;
  }
</style>
