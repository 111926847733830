<template>
  <div :class="`page-t`">
    <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8" />

    <loader :loading="loading" class="mt-4"/>
    <div class="container-fluid mt--8" v-if="!loading">
      <div class="row justify-content-end mb-2">
        <div class="col col-xl-3 col-md-6">
          <base-input
              placeholder="Search"
              class="input-group-alternative"
              alternative=""
              addon-right-icon="fas fa-search"
              v-model="search"
          />
        </div>
        <div class="col col-xl-2 col-md-5">
          <base-button
              block
              class=""
              icon="fa fa-plus"
              type="success"
              @click="handleToggleDialog"
          >Create Template
          </base-button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent no-border">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Templates</h3>
                </div>
                <div class="col text-right">
                  <!-- <base-button type="primary" size="sm">See all</base-button> -->
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table
                  class="table align-items-center table-flush"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="records"
                  :columns="columns"
                  :sortDirection="sort.direction"
                  :sortColumn="sort.column"
                  v-model="sort"
              >
                <template slot-scope="{row}">
                  <tr style="cursor: pointer" @click.ctrl.exact.prevent="handleRowClick('new', row)"
                      @click.prevent.exact="handleRowClick('redirect', row)">
                    <td class="">{{ $moment(row.created_date).format('L') }}</td>
                    <td class="">{{ $tools.capitalize(row.status) }}</td>
                    <td class="long-text">{{ row.name }}</td>              
                    <td class="long-text">{{ row.comment }}</td>
                    <td class="table-custom-action">
                      <base-button
                          type="neutral"
                          icon="fa fa-edit"
                          rounded
                          title="Edit"
                          @click.prevent.stop="handleEdit(row)"
                      />
                      <base-button
                          v-if="(row.status == 'Active') ? true:false"
                          type="neutral"
                          rounded
                          icon="fa fa-archive"
                          title="Set to Archive"
                          @click.prevent.stop="handleArchive(row)"
                      />
                       <base-button
                          v-if="(row.status == 'Archived') ? true:false"
                          type="neutral"
                          rounded
                          icon="fa fa-bolt"
                          title="Set to Active"
                          @click.prevent.stop="handleActive(row)"
                      />

                      <!-- <base-button
                          type="neutral"
                          rounded
                          icon="fa fa-trash-alt"
                          @click.prevent.stop="handleDelete(row)"
                      /> -->
                    </td>
                  </tr>
                </template>
              </base-table>
            </div>
            <div class="card-footer d-flex justify-content-between bg-transparent">
              <div class="col-md-1 col-sm-12">
                <select v-model="size" class="form-control">
                  <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
                </select>
              </div>
              <base-pagination
                  :total="Number(total)"
                  :value="Number(pagination)"
                  :perPage="Number(table.size)"
                  v-model="pagination"
              />
            </div>
          </div>
        </div>
      </div>
      <router-view />
    </div>
    <modal :show="TemplateDialog" @close="handleToggleDialog" type="mini">
      <h3 slot="header" class="modal-title" id="modal-title-default">Create Form Template</h3>
      <div>
        <base-input
            required
            autoresize
            textarea
            label="Name"
            v-model="fields.name"
            :error="errors.name"
            :valid="!errors.name ? true : false"
            @input="validate('name')"
        />
        <base-input
            autoresize
            textarea
            label="Comment"
            placeholder="e.g. Just let us know where this content will appear"
            v-model="fields.comment"
            :error="errors.comment"
            :valid="!errors.comment ? true : false"
            @input="validate('comment')"
        >
        </base-input>
       
      </div>
      <template slot="footer">
        <base-button type="link" class="ml-auto btn-radius" @click="handleToggleDialog">Close</base-button>
        <base-button type="purple" class="btn-radius" @click.prevent="handleCreateTemplate">Save changes</base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { columns, validateInput, validateSchema } from '@/container/templates/model';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import qs from 'qs';
import { mapState } from 'vuex';

export default {
  name: 'template-container',
  components: {},
  data: () => ({
    TemplateDialog: false,
    fields: {
      name: '',
      comment: '',
    
    },
    errors: {
      name: '',
      comment: '',     
    },
    columns: columns,
    table: {
      search: {
        fields: ['name', 'comment'],
        value: ''
      },
      sort: [
        {
          column: 'name',
          direction: 'asc'
        }
      ],
      page: 0,
      size: 10,
      sizeOptions: [
        10, 50, 100, 250, 500
      ]
    }
  }),
  created() {
    this.handleTableChange();
  },
  methods: {
    handleClearTemplateForm() {
      this.fields = {  
        name: '',
        comment: '',
      };
      this.errors = {
        name: '',
        comment: '',
      };
      this.TemplateDialog = false;
    },
    handleToggleDialog() {
      this.TemplateDialog = !this.TemplateDialog;
    },
    validate(field) {
      this.errors = validateInput(field, this.fields, this.errors);
    },
    async handleCreateTemplate() {
      let request;
      const { isValid, errors } = validateSchema(this.fields);
     
      if (!isValid)
        return this.errors = errors;

      if (!this.fields.id){
         request = await this.$store
            .dispatch('TEMPLATE/createTemplate', this.fields);
      }else{
        request = await this.$store
            .dispatch('TEMPLATE/updateTemplate', this.fields);
      }        

      if (request) {
        this.handleTableChange();
        this.handleClearTemplateForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Template successfully ${this.fields.id ? 'updated' : 'created'}!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleEdit(row) {
      if (!row.id)
        return;

      this.fields = { ...row };
      this.handleToggleDialog();
    },
    async handleDelete(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to Delete this template?'))
        return;

      const request = await this.$store
           .dispatch('TEMPLATE/updateTemplate', { ...row, status: 'Delete' });
      

      if (request) {
        this.handleArchiveTemplateClient(row)
        this.handleTableChange();
        this.handleClearTemplateForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Template successfully deleted!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleRowClick(type, row) {
        if (!row.id)
            return;

        if (type === 'new')
            return window.open(`/templates/${row.id}`, "_blank")

        this.$router.push(`/templates/${row.id}`)
    },

    handleArchiveTemplateClient(row){
      this.$store
          .dispatch('TEMPLATE/archiveTemplateClient', { ...row, status: 'Archived' });
    },
    handleActiveTemplateClient(row){
      this.$store
          .dispatch('TEMPLATE/archiveTemplateClient', { ...row, status: 'Active' });
    },

    async handleArchive(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to archive this template?'))
        return;

      const request = await this.$store
          .dispatch('TEMPLATE/updateTemplate', { ...row, status: 'Archived' });

      if (request) {
        this.handleArchiveTemplateClient(row)
        this.handleTableChange();
        this.handleClearTemplateForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Template successfully archived!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    async handleActive(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to activate this template?'))
        return;

      const request = await this.$store
          .dispatch('TEMPLATE/updateTemplate', { ...row, status: 'Active' });

      if (request) {
        this.handleActiveTemplateClient(row)
        this.handleTableChange();
        this.handleClearTemplateForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Template successfully active!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleTableChange() {
      this.$store.dispatch('TEMPLATE/getTemplateList', qs.stringify(omit(this.table, ['sizeOptions'])));
    }
  },
  computed: {
    ...mapState('TEMPLATE', {
      records: state => state.data,
      total: state => state.total,
      loading: state => state.loading,
    }),

    debounceSetTableLonger() {
      return debounce(this.handleTableChange, 700);
    },
    debounceSetTable() {
      return debounce(this.handleTableChange, 250);
    },
    startCase: () => startCase,
    search: {
      get() {
        return this.table.search.value;
      },
      set(keyword) {
        this.table.page = 0;
        this.table.search.value = keyword;
        this.debounceSetTableLonger();
      }
    },
    size: {
      get() {
        return this.table.size;
      },
      set(size) {
        this.table.size = size;
        this.debounceSetTable();
      }
    },
    sort: {
      get() {
        return this.table.sort[0];
      },
      set(sort) {
        this.table.sort = [sort];
        this.debounceSetTableLonger();
      }
    },
    pagination: {
      get() {
        return this.table.page + 1;
      },
      set(page) {
        this.table.page = page - 1;
        this.debounceSetTable();
      }
    }
  }
};
</script>
<style></style>
