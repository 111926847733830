<template>
  <div :class="`page-${$route.name}`">
    <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8" />

    <loader :loading="loading" class="mt-4"/>
    <div class="container-fluid mt--7"  v-if=" !loading">
      <div class="row report">
        <div class="col-md-7 mt-4">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Client information</h3>
                </div>
                <template v-if="user.role === 'Admin'">
                  <div class="col text-right">
                    <base-button type="primary" size="sm" @click.prevent="handleToggleEdit">
                      {{ toggleEdit ? 'Cancel' : 'Edit' }}
                    </base-button>
                  </div>
                </template>
              </div>
            </div>
            <div class="card-body">
              <form action="">
                <div class="mt-2">
                  <div class="row mt-4 mb-4">
                    <div class="col-lg-4">
                      <div class="card-profile" @click.prevent="handleDialogToggle(true)">
                        <img :src="client_photo" class="rounded-circle" @load.prevent="handleImageLoad">
                        <span v-if="!client_photo" >{{ $tools.getInitial(fields.name) }}</span>
                      </div>
                    </div>
                    <div class="col-md-8 p-0">
                      <div class="col-lg-12">
                        <base-input
                            label="Name"
                            @keyup="handleChange"
                            v-model="fields.name"
                            :readOnly="!this.toggleEdit"
                            :error="errors.name"
                            @input="validate('name')"
                        />
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label">Status</label>
                          <multiselect
                              v-model="status"
                              :options="statusList"
                              :multiple="false"
                              :close-on-select="true"
                              :clear-on-select="false"
                              :preserve-search="true"
                              :hide-selected="true"
                              :searchable="false"
                              :disabled="!this.toggleEdit"
                              placeholder="Select Request"
                              label="name"
                              track-by="id"
                              selectLabel=""
                              deselectLabel=""
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <base-input
                            label="Created date"
                            readOnly
                            :value="client && $moment(client.created_date).format('L')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <template v-if="toggleEdit">
              <div class="card-footer d-flex justify-content-end bg-transparent">
                <base-button type="link" class="ml-auto btn-radius" @click="handleCancelEdit">Cancel</base-button>
                <base-button type="purple" class="btn-radius" @click.prevent="handleUpdateClient">Save changes
                </base-button>
              </div>
            </template>
          </div>
        </div>
        <div class="col-md-5 mt-4">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Report</h3>
                </div>
                <div class="col text-right">
                  <!-- <base-button type="primary" size="sm">See all</base-button> -->
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <base-table
                    class="table align-items-center table-hover table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="reportData"
                    :columns="[]"
                >
                  <template slot-scope="{row}">
                    <tr>
                      <td class="">{{ $tools.capitalize(row.key) }}</td>
                      <td class="">{{ row.value }}</td>
                    </tr>
                  </template>
                </base-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col col-md-12">
          <user
              :hasParent="true"
              :client_id="$route.params.id || ''"
              :change_id="change_id"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col col-md-12">
          <request
              :hasParent="true"
              :client_ids="[$route.params.id || '']"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col col-md-12">
          <userInvite
              :hasParent="true"
              :client_id="$route.params.id || ''"
              :change_id="change_id"
          />
        </div>
      </div>
      
      

      <router-view />
    </div>
    <RequestUpload
        :imageOnly="true"
        :maxFiles="1"
        :show="showUploadDialog"
        :resource_id="this.client_id"
        resource="client"
        resource_type="profile"
        @toggle="handleDialogToggle"
        @uploaded="handleUploaded"
        :client_id="client_id"
    />
  </div>
</template>
<script>
import { clientInitialState, validateInput, validateSchema } from '@/container/client/model';
import request from '@/container/request';
import { RequestUpload, } from '@/container/request-details/component';
import user from '@/container/user';
import userInvite from '@/container/user-invite';
import { v4 } from 'uuid';
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';

export default {
  name: 'client-container',
  components: {
    user,
    request,
    RequestUpload,
    userInvite,
    Multiselect
  },
  data: () => clientInitialState(),
  created() {
    this.getClientData();
  },
  methods: {
    handleUploaded() {
      this.getClientData();
      this.$notify({
        icon: 'ni ni-check-bold',
        type: 'success',
        title: 'Success',
        message: 'Profile photo successfully updated!'
      });
    },

    handleDialogToggle(show) {
      this.showUploadDialog = show;
    },

    handleImageLoad(event) {
      if (event?.target?.src?.includes('blob'))
        URL.revokeObjectURL(event?.target?.src);
    },

    getColors(index) {
      const colors = ['bg-success', 'bg-purple', 'bg-warning', 'bg-info', 'bg-primary', 'bg-danger'];
      if (!colors[index])
        return colors[this.$tools.randomNumber(5)];
      return colors[index];
    },
    getName(user) {
      return user?.name || `${user?.first_name} ${user?.last_name}`;
    },
    validate(field) {
      this.errors = validateInput(field, this.fields, this.errors);
    },
    handleChange() {
      if (!this.$lodash.isEqual(this.refFields, this.fields))
        return this.hasChanges = true;

      if (!this.$lodash.isEqual(this.refAnswers, this.answers))
        return this.hasChanges = true;

      return this.hasChanges = false;
    },
    handleToggleEdit() {
      this.toggleEdit = !this.toggleEdit;

      if (!this.toggleEdit)
        this.handleCancelEdit();
    },
    handleCancelEdit() {
      this.errors = {};
      this.toggleEdit = false;
      this.fields = this.$lodash.pick(this.refFields, ['name', 'email', 'status']);
    },
    handleRefreshData() {
      this.errors = {};
      this.toggleEdit = false;
      this.change_id = v4();
      this.getClientData();
    },
    async handleUpdateClient() {
      let request;
      const { isValid, errors } = validateSchema(this.fields);
      if (!isValid)
        return this.errors = errors;

      request = await this.$store
          .dispatch('CLIENT/updateClient', { ...this.client, ...this.fields });

      if (request) {
        this.handleRefreshData();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Client successfully updated!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    async getClientData() {
      try {
        await this.$store.dispatch('CLIENT/getClientData', this?.$route?.params?.id);
      } catch (err) {
        this.$router.push({ name: 'client' });
      }
    },
  },
  computed: {
    ...mapState('CLIENT', {
      client: state => state.client,
      loading: state => state.loading
    }),
    ...mapState('AUTH', {
      user: state => state.user
    }),
    client_id() {
      return this?.$route?.params?.id;
    },
    client_photo() {
      return this?.base64Image || this.client?.photo?.content_link || this.client?.profile_image
    },
    reportData() {
      const data = {
        active_user: this?.client?.user_ids || 0,
        pending_invite: 0
      };

      const fields = this.$lodash.pick({ ...this?.client, ...data }, [
        'open_request', 'active_request', 'active_user', 'pending_invite'
      ]);

      return this.$lodash.entries(fields).map(([key, value]) => ({
        value: Array.isArray(value) ? value?.length : value,
        key: this.$lodash
            ?.upperFirst(key)
            ?.replace(/_/g, ' ')
      }));
    },
    status: {
      get() {
        return ({
          name: this?.fields?.status,
          id: this?.fields?.status
        });
      },

      set(object) {
        this.fields.status = object?.id;
      }
    },
    statusList() {
      return ['Active', 'Inactive']
          .map(each => ({ name: each, id: each }));
    },
  },
  watch: {
    client(latest) {
      if (!latest?.id)
        return;

      this.base64Image = latest.profile_image   
      this.fields = this.$lodash.pick(latest, ['name', 'email', 'status']);
      this.refFields = this.$lodash.pick(latest, ['name', 'email', 'status']);
      this.hasChanges = false;
    },
  },
};
</script>
<style></style>
