<template>
  <modal :show="show" @close="handleToggleDialog" type="mini" modalClasses="modal-xl">
    <h3 slot="header" class="modal-title" id="modal-title-default">Invite User</h3>
    <div>
    <div class="row">
        <div class="col-md-6">
          <base-input
            required
            label="Email"
            v-model="fields.email"
            :error="errors.email"
            @input="validate('email')"
          />
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">
              Role <span class="text-red">*</span>
            </label>
            <multiselect
              v-model="fields.userRole"
              :options="roleList"
              :close-on-select="true"
              :clear-on-select="false"
              :searchable="false"
              placeholder="Select Role"
              label="name"
              track-by="id"
              selectLabel=""
              deselectLabel=""
            />
            <div class="text-danger invalid-feedback" style="display: block;" v-if="errors.role">
              {{ errors.role }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">
              Client <span class="text-red">*</span>
            </label>
            <multiselect
              v-model="fields.client"
              :options="clientList"
              @input="getRequestList"
              :close-on-select="true"
              :clear-on-select="false"
              :searchable="false"
              placeholder="Select Request"
              label="name"
              track-by="id"
              selectLabel=""
              deselectLabel=""
            />
            <div class="text-danger invalid-feedback" style="display: block;" v-if="errors.client_id">
              {{ errors.client_id }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-control-label">Project</label>
            <multiselect
              v-model="fields.request"
              :disabled="!requestList.length"
              :options="requestList"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :hide-selected="true"
              :searchable="false"
              placeholder="Select Project"
              label="name"
              track-by="id"
              selectLabel=""
              deselectLabel=""
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Subject"
            v-model="fields.subject"
            :error="errors.subject"
          />
        </div>
        <div class="col-md-12">
          <base-input
            autoresize
            textarea
            label="Message"
            v-model="fields.message"
            :error="errors.message"
          />
        </div>
      </div>
    </div>
    <template slot="footer">
      <template v-if="loading">
          <loader :loading="true"></loader>
      </template>
      <template v-else>
        <base-button type="link" class="ml-auto btn-radius" @click="handleToggleDialog(false, $event)">Close</base-button>
        <base-button type="purple" class="btn-radius" @click.prevent="handleInviteUser">Send Invite</base-button>
      </template>
    </template>
  </modal>
</template>
<script>
  import qs from 'qs'
  import upperFirst from 'lodash/upperFirst'
  import map from 'lodash/map'
  import omit from 'lodash/omit'
  // import debounce from 'lodash/debounce'
  import {validateSchema, validateInput } from '@/container/user-invite/model'
  import Multiselect from 'vue-multiselect'
  import { mapState } from "vuex";

  const DEFAULT_FIELDS = () => ({
    fields: {
      email: '',
      client_id: '',
      subject: '',
      message: '',
      role: '',
      request_ids: [],

      userRole: null, // trash
      client: null,  // trash
      request: null, // trash
    },
    errors: {
      email: '',
      client_id: '',
      role: '',
      request_ids: [],
    },
  })

  export default {
    name: 'user-invite-dialog',
    components: {
      Multiselect
    },
    props: {
      show: Boolean,
    },
    data: DEFAULT_FIELDS,
    methods: {
      handleClearUserForm() {
        Object.assign(this.$data, DEFAULT_FIELDS())
      },
      handleToggleDialog(show = false) {
        this.$emit('toggle', show)
      },
      validate(field) {
        this.errors = validateInput(field, this.fields, this.errors)
      },
      async handleInviteUser() {
        let request;
        const { isValid, errors } = validateSchema(this.fields)
        if (!isValid)
          return this.errors = errors

        // convert email to lowercase
        this.fields.email = this.fields.email.toLowerCase() 
        if (!this.fields.id)
          request = await this.$store
            .dispatch('INVITE/createInvite', omit(this.fields, ['userRole', 'request', 'client']))

        if (request) {
          this.$emit('sent')
          this.handleToggleDialog(false)
          this.handleClearUserForm()
          this.$notify({
            icon: 'ni ni-check-bold',
            type: 'success',
            title: 'Success',
            message: `User successfully ${this.fields.id ? 'updated' : 'created'}!`
          })
        }
      },

      getClientList() {
        const params = qs.stringify({
          status: 'Active'
        })
        this.$store.dispatch('CLIENT/getAllClientListData', params)
      },

      async getRequestList() {
        this.fields.request = []
        this.fields.client_id = this.fields.client.id
        this.validate('client_id')
        if (this.errors.client_id) {
          return
        }

        const params = qs.stringify({
          status: 'Active',
          client_id: this?.fields?.client_id
        })
        await this.$store.dispatch("REQUEST/getAllRequestListData", params)
      }
    },
    computed: {
      ...mapState('CLIENT', {
        clients: state => state.data,
      }),
      ...mapState('REQUEST', {
        requests: state => state.data,
      }),
      ...mapState('INVITE', {
        loading: (state) => state.loading,
      }),
      roleList() {
        return ['Admin', 'User', 'Editor', 'Creative Director']
          .map(each => ({ name: each, id: each }))
      },
      clientList() {
        return this.clients.map(each => ({
          ...each,
          name: upperFirst((each?.name).trim())
        }))
      },
      requestList() {
        return this.requests.map(each => ({
          ...each,
          name: upperFirst((each?.name).trim())
        }))
      },
      client() {
        return this?.fields?.client
      },
      request() {
        return (this?.fields?.request || [])
      },
      userRole() {
        return this?.fields?.userRole
      },
    },
    watch: {
      show(open) {
        if (open) {
          this.getClientList()
          this.handleClearUserForm()
          this.$store.commit('REQUEST/clearState')
        } else {
          //
        }
      },

      request(data) {
        this.fields.request_ids = data.length
          ? map(data, 'id')
          : []
      },

      userRole(data) {
        if (data) {
          this.fields.role = data.id
          this.validate('role')
        }
      },
    }
  };
</script>
<style></style>
