<template>
    <div class="card shadow collab-container">
        <div class="card-body" ref='collaborators' :class="{'highlight' : colabHighlight}" >
            <div class="collab-members">
                    <div class="avatar-group">
                        <template>
                           
             <div                              
                class="avatar avatar-md rounded-circle" 
                v-for="(tc, index) in t_clients"
                :key="index"
            >
                <img :src="getPhoto(tc)" v-if="getPhoto(tc)"  :title="getName(tc)" @click="handleDeleteCollab(tc)"/>
                <span class="collab-member-initial" v-if="!getPhoto(tc)"  :title="getName(tc)" @click="handleDeleteCollab(tc)">
                    {{ $tools.getInitial(getName(tc)) }}
                </span>
            </div>
                        </template>
                    </div>
            </div> 
            <div class="row mt-4" >
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      Client <span class="text-red">*</span>
                    </label>
                    <multiselect
                      v-model="fields.client"
                      :options="clients"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :searchable="false"
                      placeholder="Select Client"
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                    />
                  </div>
                </div>
            </div>
           
        </div>
        <div class="card-body collab-input" v-if="showUsers" >
            <base-input
                addonLeftIcon="ni ni-circle-08"
                placeholder="Name or email"
                v-model="search"
                @focus.prevent="handleFocus"
                @blur="toggleFocus($event)"
            />
            <ul class="dropdown-menu dropdown-menu-right" v-if="keyword || (isFocus && clients.length)">
                <a
                    href="#"
                    class="dropdown-item"
                    v-for="({ email, first_name, last_name, id}, index) in users"
                    :key="index"
                    @click.prevent="handleAddCollab(users[index])"
                >
                    <i class="fa fa-user-plus text-gray"/>
                    <div class="dropdown-user">
                        <span class="dropdown-user-name">
                            {{ $tools.capitalize(first_name) }} {{ $tools.capitalize(last_name) }}
                        </span>
                        <small class="text-purple">{{ email }}</small>
                       
                    </div>
                </a>
                <template v-if="!users.length && !shouldInviteUser">
                    <a href="#" class="dropdown-item text-gray disabled" disabled>
                        <i class="ni ni-fat-remove"/>
                        <span class="text-gray">No results found</span>
                    </a>
                </template>
                <template v-if="shouldInviteUser">
                    <a href="#" class="dropdown-item text-purple" @click.prevent="handleSendInvite">
                        <i class="ni ni-fat-add"/>
                        <span class="text-purple">Invite '{{ keyword }}' via email</span>
                    </a>
                </template>
            </ul>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex"
    import debounce from 'lodash/debounce'
    import upperFirst from 'lodash/upperFirst'
    import Multiselect from 'vue-multiselect'
    import qs from "qs";

    export default {
        name: 'template-client-section',
        components: {
            Multiselect
        },
        data: () => ({
            keyword: '',
            isFocus: false,
            showUsers: false,
            fields: {
                client_id: '',
                client: null
            },
            hover_index: -1,
            base64Image: null, 
            colabHighlight:false 
        }),
        methods: {
            toggleFocus(event) {
                if (event?.relatedTarget?.classList?.contains('dropdown-item'))
                    return

                this.isFocus = !this.isFocus
            },
            handleFocus() {
                this.handleSearchChange()
                this.toggleFocus()
            },
            addClientToTemplate(data){
                 this.$store.dispatch('TEMPLATE/addClientToTemplate', {
                            id: this.templateId,
                            client_id: data.id                           
                    })
                this.$root.$refs.TemplateDetails.handleGetDetails(); 
                
            },
            handleToggleCollab() {
                this.showUsers = !this.showUsers
            },
            
            async handleDeleteCollab(data) {
              
               
                if(confirm(`Are you sure to remove ${name}?`)) {
                   
                    this.$store.dispatch('TEMPLATE/removeTemplateClients', {
                            id: this.templateId,
                            client_id: data.id,  
                            action: 'delete'
                    })
    
                    this.$notify({
                        icon: "ni ni-check-bold",
                        type: "success",
                        title: "Success",
                        message: `Successfully updated`,
                    })
                    this.getTemplateClient();
                }
            },
            
            handleAddCollab(user) {
                const name = `${this.$lodash.upperFirst(user.fname)} ${this.$lodash.upperFirst(user.lname)}`
                this.toggleFocus()
                this.handleToggleCollab()

                if (this.isNew)
                    this.handleAddCollabLocal(user)
                else {
                    this.$store.dispatch('REQUEST/updateCollaborator', {
                        id: this.requestId,
                        user_id: user.id,
                    })
                }

                this.$notify({
                    icon: "ni ni-check-bold",
                    type: "success",
                    title: "Success",
                    message: `${name} successfully added as collaborator`,
                })
            },
            handleSearchChange() {
                const params = qs.stringify({
                    status: 'Active',
                    // id: this.requestId,
                    keyword: this.keyword,
                    // exclude: this.request?.members,
                    // client_id: this?.fields?.client_id
                });
                this.$store.dispatch("CLIENT/getClients", {
                    params,
                    // id: this.requestId,
                })
            },
           
            getColors(index) {
                const colors = ['bg-success','bg-purple','bg-warning','bg-info','bg-primary','bg-danger']
                if (!colors[index])
                    return colors[this.$tools.randomNumber(5)]
                return colors[index]
            },
            getName(user) {
                return user?.name || `${user?.first_name} ${user?.last_name}`
            },
            async getClientList() {
                const params = qs.stringify({
                  status: 'Active',
                  fields: ['id', 'name']
                })
                await this.$store.dispatch('CLIENT/getAllClientListData', params)
            },
            async getTemplateClient() {
              this.$store
                .dispatch('TEMPLATE/getTemplateData', this.templateId);
               
            },
            getPhoto(client) {
                return client.profile_image || null
            },
        },
        computed: {
            ...mapGetters("AUTH", [
                'isUser',
                'isAdmin',
                'isUserAndClientIsNotActive'
            ]),
            ...mapState('TEMPLATE', {
                t_clients: state => state.t_clients,
                users: state => state.collaborators,
                loading: state => state.loading,
            }),
            ...mapState('CLIENT', {
                clients: state => state.data,
                custom_questions: (state) => state.custom_questions,
            }),
            clientList() {
                return this.clients.map(each => ({
                    ...each,
                    name: upperFirst((each?.name).trim()),
                }))
            },
            client() {
                return this?.fields?.client
            },
            template() {
                return this.$parent.template
            },
            client_photo() {
                return (
                    this?.request?.client?.photo?.content_link ||
                    this?.client?.photo?.content_link || this.base64Image
                )
            },
            client_name() {
                return (
                    this?.client?.name ||
                    this?.request?.client_name || ''
                )
            },
            client_id() {
                return (
                    this?.request?.client_id ||
                    this?.client?.id || ''
                )
            },
            
           
            templateId() {
                return this.$route.params.id
            },
            debounceSetTableLonger() {
                return debounce(this.handleSearchChange, 500)
            },
            search: {
                get() {
                    return this.keyword
                },
                set(keyword) {
                    this.keyword = keyword
                    this.debounceSetTableLonger()
                },
            },
            
        },
        created() {
            this.getClientList()
            // if(this.templateId){
            //      this.getTemplateClient()
            // }
            
           
        },
        watch: {
            client(data) {               
                this.addClientToTemplate(data)
                this.getTemplateClient(); 
            },
        }
    }
</script>