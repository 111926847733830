import * as yup from 'yup'

const createQuestiontScheme = yup.object().shape({
  email: yup.string().email().required(),
  client_id: yup.string().required(),
  role: yup.string().required(),
  request_ids: yup.array().of(yup.string()),
})

export const validateSchema = data => {
	try {
    createQuestiontScheme.validateSync(data, { abortEarly: false, allowUnknown: true })
    return {
      errors: {},
      isValid: true,
    }
  } catch (error) {
    const errors = error.inner.reduce((acc, el) => {
      const { path, message } = el
      acc[path] = message
        .replace(/_id/g, '')
        .replace('is a', 'is')
        .replace('field', '')
      return acc
    }, {})
    return { isValid: false, errors }
  }
}

export const validateInput = (key, fields, errors) => {
	const { errors: vErrors } = validateSchema(fields)
	return { ...errors, [key]: vErrors[key] }
}

export const columns = [

  {
    name: 'Invted By',
    key: 'invited_by_user',
    sort: true,
  },
	{
		name: 'Invitee Email',
		key: 'invitee_email',
		sort: true,
	},
	{
		name: 'Invitee Role',
		key: 'invitee_role',
		sort: true,
	},
	{
		name: 'Client',
		key: 'client_id',
		sort: true,
	},
  {
    name: 'Invitee',
    key: 'invited_user',
    sort: true,
  },
	{
		name: 'Project Invited',
		key: 'requests',
		sort: false,
	},
  {
      name: 'Invite Status',
      key: 'status',
      sort: true,
  },
  {
      name: 'Expiry Date',
      key: 'expiry_date',
      sort: true,
  },
  {
      name: 'Sent Date',
      key: 'send_date',
      sort: true,
  },
  {
      name: 'Created Date',
      key: 'created_date',
      sort: true,
  },
	{
		name: 'Accepted Date',
		key: 'accept_date',
		sort: true,
	},

  {
    name: '',
    key: '',
    sort: false,
  },
]