<template>
  <div :class="`page-${$route.name}`">
    <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8" />

    <loader :loading="loading" class="mt-4"/>
    <div class="container-fluid mt--8" v-if="!loading">
      <div class="row justify-content-end mb-2">
        <div class="col col-xl-3 col-md-6">
          <base-input
              placeholder="Search"
              class="input-group-alternative"
              alternative=""
              addon-right-icon="fas fa-search"
              v-model="search"
          />
        </div>
        <template v-if="user.role === 'Admin'">
          <div class="col col-xl-2 col-md-5">
            <base-button
                block
                class=""
                icon="fa fa-plus"
                type="success"
                @click="handleToggleDialog"
            >Create Client
            </base-button>
          </div>
        </template>
      </div>
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent no-border">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Clients</h3>
                </div>
                <div class="col text-right">
                  <!-- <base-button type="primary" size="sm">See all</base-button> -->
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table
                  class="table align-items-center table-flush"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="records"
                  :columns="columns"
                  :sortDirection="sort.direction"
                  :sortColumn="sort.column"
                  v-model="sort"
              >
                <template slot-scope="{row}">
                  <tr style="cursor: pointer" @click.ctrl.exact.prevent="handleRowClick('new', row)"
                      @click.prevent.exact="handleRowClick('redirect', row)">
                    <td class="long-text"><a :href="`/client/${row.id}`">{{ $tools.capitalize(row.name) }}</a></td>
                    <td class="long-text">{{ row.open_request }}</td>
                    <td class="long-text">{{ row.active_request }}</td>
                    <td class="">{{ $moment(row.created_date).format('L') }}</td>
                    <td class="">{{ $tools.capitalize(row.status) }}</td>
                    <td>
                      <div class="avatar-group">
                        <template>
                          <div
                              class="avatar avatar-md rounded-circle"
                              :class="getColors(index)"
                              v-for="(collaborator, index) in row.collaborators"
                              :key="index"
                          >
                            <img :src="getPhoto(collaborator)" v-if="getPhoto(collaborator)"  :title="getName(collaborator)" />
                            <span class="collab-member-initial" v-if="!getPhoto(collaborator)"  :title="getName(collaborator)">
                              {{ $tools.getInitial(getName(collaborator) || collaborator.email) }}
                            </span>
                          </div>
                        </template>
                      </div>
                    </td>
                    <td class="table-custom-action">
                      <template v-if="user.role === 'Admin'">
                        <base-button
                            type="neutral"
                            icon="fa fa-edit"
                            rounded
                            @click.prevent.stop="handleEdit(row)"
                        />
                        <base-button
                            type="neutral"
                            rounded
                            icon="fa fa-archive"
                            @click.prevent.stop="handleArchive(row)"
                        />
                        <base-button
                            type="neutral"
                            rounded
                            icon="fa fa-trash-alt"
                            @click.prevent.stop="handleDelete(row)"
                        />
                      </template>
                    </td>
                  </tr>
                </template>
              </base-table>
            </div>
            <div class="card-footer d-flex justify-content-between bg-transparent">
              <div class="col-md-1 col-sm-12">
                <select v-model="size" class="form-control">
                  <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
                </select>
              </div>
              <base-pagination
                  :total="Number(total)"
                  :value="Number(pagination)"
                  :perPage="Number(table.size)"
                  v-model="pagination"
              />
            </div>
          </div>
        </div>
      </div>
      <router-view />
    </div>
    <modal :show="ClientDialog" @close="handleToggleDialog" type="mini">
      <h3 slot="header" class="modal-title" id="modal-title-default">Client Form</h3>
      <div>
        <base-input
            required
            label="name"
            v-model="fields.name"
            :error="errors.name"
            :valid="!errors.name ? true : false"
            @input="validate('name')"
        />
      </div>
      <template slot="footer">
        <base-button type="link" class="ml-auto btn-radius" @click="handleToggleDialog">Close</base-button>
        <base-button type="purple" class="btn-radius" @click.prevent="handleCreateClient">Save changes</base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { columns, validateInput, validateSchema } from '@/container/client/model';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import qs from 'qs';
import { mapState } from 'vuex';

export default {
  name: 'client-container',
  components: {},
  data: () => ({
    ClientDialog: false,
    fields: {
      name: '',
    },
    errors: {
      name: '',
    },
    columns: columns,
    table: {
      search: {
        fields: ['name', 'status', 'open_request', 'active_request'],
        value: ''
      },
      sort: [
        {
          column: 'created_date',
          direction: 'desc'
        }
      ],
      page: 0,
      size: 10,
      sizeOptions: [
        5, 10, 50, 100, 250, 500
      ]
    }
  }),
  created() {
    this.handleTableChange();
  },
  methods: {
    getColors(index) {
      const colors = ['bg-success', 'bg-purple', 'bg-warning', 'bg-info', 'bg-primary', 'bg-danger'];
      if (!colors[index])
        return colors[this.$tools.randomNumber(5)];
      return colors[index];
    },
    getName(user) {
      return user?.name || `${user?.first_name} ${user?.last_name}`;
    },
    handleClearClientForm() {
      this.fields = {};
      this.errors = {};
      this.ClientDialog = false;
    },
    handleToggleDialog() {
      if (this.ClientDialog) {
        this.fields = {};
        this.errors = {};
      }

      this.ClientDialog = !this.ClientDialog;
    },
    handleRowClick(type, row) {
      if (!row.id)
        return;

      if (type === 'new')
        return window.open(`/client/${row.id}`, '_blank');

      this.$router.push(`/client/${row.id}`);
    },
    validate(field) {
      this.errors = validateInput(field, this.fields, this.errors);
    },
    async handleCreateClient() {
      let request;
      const { isValid, errors } = validateSchema(this.fields);
      if (!isValid)
        return this.errors = errors;

      if (!this.fields.id)
        request = await this.$store
            .dispatch('CLIENT/createClient', this.fields);
      else
        request = await this.$store
            .dispatch('CLIENT/updateClient', this.fields);

      if (request) {
        this.handleTableChange();
        this.handleClearClientForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Client successfully ${this.fields.id ? 'updated' : 'created'}!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleEdit(row) {
      if (!row.id)
        return;

      this.fields = { ...row };
      this.handleToggleDialog();
    },
    async handleDelete(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to Delete this client?'))
        return;

      const request = await this.$store
          .dispatch('CLIENT/deleteClient', row);

      if (request) {
        this.handleTableChange();
        this.handleClearClientForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Client successfully deleted!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    async handleArchive(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to archive this client?'))
        return;

      const request = await this.$store
          .dispatch('CLIENT/updateClient', { ...row, status: 'Archived' });

      if (request) {
        this.handleTableChange();
        this.handleClearClientForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Client successfully archived!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleTableChange() {
      this.$store.dispatch('CLIENT/getClientList', qs.stringify(omit(this.table, ['sizeOptions'])));
    },
    getPhoto(photo) {
      return photo?.content_link || photo.profile_image || null;
    },
  },
  computed: {
    ...mapState('CLIENT', {
      records: state => state.data,
      total: state => state.total,
      loading: state => state.loading
    }),

    ...mapState('AUTH', {
      user: state => state.user,
    }),

    debounceSetTableLonger() {
      return debounce(this.handleTableChange, 700);
    },
    debounceSetTable() {
      return debounce(this.handleTableChange, 250);
    },
    startCase: () => startCase,
    search: {
      get() {
        return this.table.search.value;
      },
      set(keyword) {
        this.table.page = 0;
        this.table.search.value = keyword;
        this.debounceSetTableLonger();
      }
    },
    size: {
      get() {
        return this.table.size;
      },
      set(size) {
        this.table.size = size;
        this.debounceSetTable();
      }
    },
    sort: {
      get() {
        return this.table.sort[0];
      },
      set(sort) {
        this.table.sort = [sort];
        this.debounceSetTableLonger();
      }
    },
    pagination: {
      get() {
        return this.table.page + 1;
      },
      set(page) {
        this.table.page = page - 1;
        this.debounceSetTable();
      }
    }
  }
};
</script>
<style></style>
