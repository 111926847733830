<template>
  <div>
    <div v-if="hasParent">
      <div class="card shadow">
        <div class="card-header bg-transparent no-border">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Users</h3>
            </div>
            <div class="col text-right">
              <!-- <base-button type="primary" size="sm">See all</base-button> -->
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <base-table
              class="table align-items-center table-flush table-hover"
              thead-classes="thead-light"
              tbody-classes="list"
              :data="records"
              :columns="columns"
              :sortDirection="sort.direction"
              :sortColumn="sort.column"
              v-model="sort"
          >
            <template slot-scope="{row}" >
              <tr @click.ctrl.exact.prevent="handleRowClick('new', row)"
                  @click.prevent.exact="handleRowClick('redirect', row)"
                  >
                <td class="">{{ $moment(row.created_date).format('L') }}</td>
                <td class="">
                  <div class="avatar-group">
                    <template>
                      <div
                          class="avatar avatar-md rounded-circle"
                          :class="getColors()"
                      >
                        <img :src="getPhoto(row)" v-if="getPhoto(row)" />
                        <span class="collab-member-initial" v-if="!getPhoto(row)">
                          {{ $tools.getInitial(getName(row) || row.email) }}
                        </span>
                      </div>
                      <span style="margin-left: 15px;"><a :href="`/user/${row.id}`">{{ $tools.capitalize(row.first_name) }}</a></span>
                    </template>
                  </div>
                </td>
                <td class=""><a :href="`/user/${row.id}`">{{ $tools.capitalize(row.last_name) }}</a></td>
                <td class="">{{ row.email }}</td>
                <td class="">
                  <span v-if="row.client_name">{{ row.client_name }}</span>
                  <span v-if="!row.client_name">N/A</span>
                </td>
                <td class="">{{ $tools.capitalize(row.role) }}</td>
                <td class="long-text">{{ row.open_request }}</td>
                <td class="long-text">{{ row.active_request }}</td>
                <td class="long-text">{{ row.total_request_user }}</td>
                <td class="">{{ $tools.capitalize(row.status) }}</td>
                <td class="long-text">{{ row.verified ? 'YES' : 'NO' }}</td>
                <td class="table-custom-action">
                  <template v-if="auth_user_id !== row.id && auth_user.role === 'Admin'">
                    <base-button
                        type="neutral"
                        rounded
                        icon="fa fa-archive"
                        @click.prevent.stop="handleArchive(row)"
                    />
                    <base-button
                        type="neutral"
                        rounded
                        icon="fa fa-trash-alt"
                        @click.prevent.stop="handleDelete(row)"
                    />
                  </template>
                </td>
              </tr>
            </template>
          </base-table>
        </div>
        <div class="card-footer d-flex justify-content-between bg-transparent">
          <div class="col-md-1 col-sm-12">
            <select v-model="size" class="form-control">
              <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
            </select>
          </div>
          <base-pagination
              :total="Number(total)"
              :value="Number(pagination)"
              :perPage="Number(table.size)"
              v-model="pagination"
          />
        </div>
      </div>
    </div>
    <div :class="`page-${$route.name}`" v-if="!hasParent">
      <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8" />

      <loader :loading="loading" class="mt-4" />
      <div class="container-fluid mt--8" v-if="!loading">
        <div class="row justify-content-end mb-2">
          <div class="col col-xl-3 col-md-6">
            <base-input
                placeholder="Search"
                class="input-group-alternative"
                alternative=""
                addon-right-icon="fas fa-search"
                v-model="search"
            />
          </div>
          <!-- <div class="col col-xl-2 col-md-5">
            <base-button
              block
              class=""
              icon="fa fa-plus"
              type="success"
              @click="handleToggleDialog"
            >Create User</base-button>
          </div> -->
        </div>
        <div class="row">
          <div class="col">
            <div class="card shadow">
              <div class="card-header bg-transparent no-border">
                <div class="row align-items-center">
                  <div class="col">
                    <h3 class="mb-0">Users</h3>
                  </div>
                  <div class="col text-right">
                    <!-- <base-button type="primary" size="sm">See all</base-button> -->
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush table-hover"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                    :columns="columns"
                    :sortDirection="sort.direction"
                    :sortColumn="sort.column"
                    v-model="sort"
                >
                  <template slot-scope="{row}">
                    <tr style="cursor: pointer" @click.ctrl.exact.prevent="handleRowClick('new', row)"
                        @click.prevent.exact="handleRowClick('redirect', row)">
                      <td class="">{{ $moment(row.created_date).format('L') }}</td>
                      <td class="">
                        <div class="avatar-group">
                          <template>
                            <div
                                class="avatar avatar-md rounded-circle"
                                :class="getColors()"
                            >
                              <img :src="getPhoto(row)" v-if="getPhoto(row)" />
                              <span class="collab-member-initial" v-if="!getPhoto(row)">
                                {{ $tools.getInitial(getName(row) || row.email) }}
                              </span>
                            </div>
                            <span style="margin-left: 15px;"><a :href="`/user/${row.id}`">{{ $tools.capitalize(row.first_name) }}</a></span>
                          </template>
                        </div>
                      </td>
                      <td class=""><a :href="`/user/${row.id}`">{{ $tools.capitalize(row.last_name) }}</a></td>
                      <td class="">{{ row.email }}</td>
                      <td class="">
                        <span v-if="row.client_name">{{  row.client_name }}</span>
                        <span v-if="!row.client_name">N/A</span>
                      </td>
                      <td class="">{{ $tools.capitalize(row.role) }}</td>
                      <td class="long-text">{{ row.open_request }}</td>
                      <td class="long-text">{{ row.active_request }}</td>
                      <td class="long-text">{{ row.total_request_user }}</td>
                      <td class="">{{ $tools.capitalize(row.status) }}</td>
                      <td class="long-text">{{ row.verified ? 'YES' : 'NO' }}</td>
                      <td class="table-custom-action">
                        <template v-if="auth_user_id !== row.id && auth_user.role === 'Admin'">
                          <base-button
                              type="neutral"
                              rounded
                              icon="fa fa-archive"
                              @click.prevent.stop="handleArchive(row)"
                          />
                          <base-button
                              type="neutral"
                              rounded
                              icon="fa fa-trash-alt"
                              @click.prevent.stop="handleDelete(row)"
                          />
                        </template>
                      </td>
                    </tr>
                  </template>
                </base-table>
              </div>
              <div class="card-footer d-flex justify-content-between bg-transparent">
                <div class="col-md-1 col-sm-12">
                  <select v-model="size" class="form-control">
                    <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
                  </select>
                </div>
                <base-pagination
                    :total="Number(total)"
                    :value="Number(pagination)"
                    :perPage="Number(table.size)"
                    v-model="pagination"
                />
              </div>
            </div>
          </div>
        </div>
        <router-view />
      </div>
      <modal :show="UserDialog" @close="handleToggleDialog" type="mini">
        <h3 slot="header" class="modal-title" id="modal-title-default">Create Form User</h3>
        <div>
          <base-input
              required
              autoresize
              textarea
              label="User"
              v-model="fields.question"
              :error="errors.question"
              :valid="!errors.question ? true : false"
              @input="validate('question')"
          />
          <base-input
              autoresize
              textarea
              label="Comment"
              placeholder="e.g. Just let us know where this content will appear"
              v-model="fields.comment"
              :error="errors.comment"
              :valid="!errors.comment ? true : false"
              @input="validate('comment')"
          />
        </div>
        <template slot="footer">
          <base-button type="link" class="ml-auto btn-radius" @click="handleToggleDialog">Close</base-button>
          <base-button type="purple" class="btn-radius" @click.prevent="handleCreateUser">Save changes</base-button>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import { columns, validateInput, validateSchema } from '@/container/user/model';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import qs from 'qs';
import { mapState } from 'vuex';

export default {
  name: 'user-container',
  components: {},
  props: {
    change_id: {
      type: String,
      default: '',
      description: 'to determine the parent has changes and force the list to update'
    },
    client_id: {
      type: String,
      default: '',
      description: 'request filter by client id'
    },
    hasParent: {
      type: Boolean,
      description: 'Whether there\'s a parent or not',
      default: false,
    },
  },
  data: () => ({
    UserDialog: false,
    fields: {
      question: '',
      comment: ''
    },
    errors: {
      question: '',
      comment: ''
    },
    columns: columns,
    table: {
      search: {
        fields: ['first_name', 'last_name', 'email', 'status'],
        value: ''
      },
      sort: [
        {
          column: 'created_date',
          direction: 'desc'
        }
      ],
      page: 0,
      size: 10,
      sizeOptions: [
        10, 50, 100, 250, 500
      ]
    }
  }),
  created() {
    if (this.client_id)
      this.table.client_id = this.client_id;

    this.handleTableChange();
  },
  methods: {
    handleRowClick(type, row) {
      if (!row.id)
        return;

      if (type === 'new')
        return window.open(`/user/${row.id}`, '_blank');

      this.$router.push(`/user/${row.id}`);
    },
    getColors(index) {
      const colors = ['bg-success', 'bg-purple', 'bg-warning', 'bg-info', 'bg-primary', 'bg-danger'];
      if (!colors[index])
        return colors[this.$tools.randomNumber(5)];
      return colors[index];
    },
    getName(user) {
      return user?.name || `${user?.first_name} ${user?.last_name}`;
    },
    getPhoto(photo) {
      return photo?.profile_image ||   null;
    },
    handleClearUserForm() {
      this.fields = {};
      this.errors = {};
      this.UserDialog = false;
    },
    handleToggleDialog() {
      if (this.UserDialog) {
        this.fields = {};
        this.errors = {};
      }

      this.UserDialog = !this.UserDialog;
    },
    validate(field) {
      this.errors = validateInput(field, this.fields, this.errors);
    },
    async handleCreateUser() {
      let request;
      const { isValid, errors } = validateSchema(this.fields);
      if (!isValid)
        return this.errors = errors;

      if (!this.fields.id)
        request = await this.$store
            .dispatch('USER/createUser', this.fields);
      else
        request = await this.$store
            .dispatch('USER/updateUser', this.fields);

      if (request) {
        this.handleTableChange();
        this.handleClearUserForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `User successfully ${this.fields.id ? 'updated' : 'created'}!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleEdit(row) {
      if (!row.id)
        return;

      this.fields = { ...row };
      this.handleToggleDialog();
    },
    async handleDelete(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to Delete this user?'))
        return;

      const request = await this.$store
          .dispatch('USER/deleteUser', row);

      if (request) {
        this.handleTableChange();
        this.handleClearUserForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `User successfully deleted!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    async handleArchive(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to archive this user?'))
        return;

      const request = await this.$store
          .dispatch('USER/updateUser', { ...row, status: 'Archived' });

      if (request) {
        this.handleTableChange();
        this.handleClearUserForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `User successfully archived!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleTableChange() {
      this.$store.dispatch('USER/getUserList', qs.stringify(omit(this.table, ['sizeOptions'])));
    }
  },
  computed: {
    ...mapState('USER', {
      records: state => state.data,
      total: state => state.total,
      loading: state => state.loading,
    }),

    ...mapState('AUTH', {
      auth_user: state => state.user,
    }),

    auth_user_id() {
      return this.auth_user?.id;
    },
    debounceSetTableLonger() {
      return debounce(this.handleTableChange, 700);
    },
    debounceSetTable() {
      return debounce(this.handleTableChange, 250);
    },
    startCase: () => startCase,
    search: {
      get() {
        return this.table.search.value;
      },
      set(keyword) {
        this.table.page = 0;
        this.table.search.value = keyword;
        this.debounceSetTableLonger();
      }
    },
    size: {
      get() {
        return this.table.size;
      },
      set(size) {
        this.table.size = size;
        this.debounceSetTable();
      }
    },
    sort: {
      get() {
        return this.table.sort[0];
      },
      set(sort) {
        this.table.sort = [sort];
        this.debounceSetTableLonger();
      }
    },
    pagination: {
      get() {
        return this.table.page + 1;
      },
      set(page) {
        this.table.page = page - 1;
        this.debounceSetTable();
      }
    }
  },
  watch: {
    change_id() {
      if (this.hasParent && this.client_id)
        this.handleTableChange();
    }
  }
};
</script>

