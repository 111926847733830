import * as yup from 'yup'

const createQuestiontScheme = yup.object().shape({
  question: yup.string().required(),
  comment: yup.string(),
  required: yup.boolean().required()
})

export const validateSchema = data => {
	try {
    createQuestiontScheme.validateSync(data, { abortEarly: false, allowUnknown: true })
    return {
      errors: {},
      isValid: true,
    }
  } catch (error) {
    const errors = error.inner.reduce((acc, el) => {
      const { path, message } = el
      acc[path] = message
      return acc
    }, {})
    return { isValid: false, errors }
  }
}

export const validateInput = (key, fields, errors) => {
	const { errors: vErrors } = validateSchema(fields)
	return { ...errors, [key]: vErrors[key] }
}

export const columns = [
	{
		name: 'Created Date',
		key: 'created_date',
		sort: true,
	},
	{
		name: 'Status',
		key: 'status',
		sort: true,
	},
    {
        name: 'Required',
        key: 'is_required',
        sort: true,
    },
	{
		name: 'Question',
		key: 'question',
		sort: true,
	},
	{
		name: 'Order',
		key: 'order',
		sort: true,
	},
	{
		name: 'Comment',
		key: 'comment',
		sort: true,
	},
	{
		name: '',
		key: '',
		sort: false,
	},
]