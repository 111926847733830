<template>
    <div class="card shadow collab-container">
        <div class="card-body" ref='collaborators' :class="{'highlight' : colabHighlight}" >
            <div class="collab-client mb-4 align-items-center" v-if="client_name && !isNew" :class="{'client-selected': client_name}"
                :style="{'cursor': 'pointer'}" @click="() => { this.$router.push(`/client/${client_id}`) }"
                title="View client details">
                <div class="d-flex align-items-center">
                    <div class="avatar avatar-md rounded-circle mr-0">
                        <img v-if="client_photo" :src="client_photo" />  
                        <span v-else class="collab-member-initial" >
                            {{ $tools.getInitial(client_name) }}
                        </span> 
                    </div>
                </div>
                <div class="collab-client-details mx-3">
                    <a :href="`/client/${client_id}`" class="collab-client-name">{{ client_name }}</a>
                    <div class="collab-client-email d-none">bluebear@gmail.com</div>
                </div>
            </div>
            <div class="row" v-if="isNew">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-control-label">
                      Client <span class="text-red">*</span>
                    </label>
                    <multiselect
                      v-model="client"
                      :options="clientList"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :searchable="true"
                      placeholder="Select Client"
                      label="name"
                      track-by="id"
                      selectLabel=""
                      deselectLabel=""
                      :disabled="!clientList.length"
                      :open-direction="openDirection"
                    >
                        <span slot="noResult">Client not found</span>
                    </multiselect>
                  </div>
                </div>
            </div>
            <template v-if="client">
                <div>
                    <label class="form-control-label">
                        Collaborators <span v-if="isNew" class="text-red">*</span>
                    </label>
                    <div v-if="isNew" class="float-right">
                        <base-checkbox v-model="notifyCollaborators" :disabled="!collaborators.length">Notify collaborators?</base-checkbox>
                    </div>
                </div>

                <div class="avatar-group flex-wrap px-2">
                    <template v-if="collaborators.length">
                        <div
                            class="avatar avatar-md rounded-circle" 
                            v-for="(collaborator, index) in collaborators"
                            :key="index"
                        >
                            <img :src="getPhoto(collaborator)" v-if="getPhoto(collaborator)"  :title="getName(collaborator)"/>
                            <span class="collab-member-initial" v-if="!getPhoto(collaborator)"  :title="getName(collaborator)">
                                {{ $tools.getInitial(getName(collaborator) || collaborator.email) }}
                            </span>
                        </div>
                    </template>
                    <div class="no-user" v-else>
                        <img src="/img/icons/common/smile.svg" class="image-icon" />
                    </div>
                    <div>
                        <base-button
                        type="neutral"
                        rounded
                        @click="handleToggleCollab"
                        v-if="!isUserAndClientIsNotActive"
                        >
                            <img src="/img/icons/common/plus-circle.svg" class="image-icon" />
                        </base-button>
                    </div>
                </div>
            </template>
            <div class="collab-input mt-2" v-if="showUsers" >
                <multiselect
                    :options="users"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :value="collaborators"
                    :custom-label="getName"
                    track-by="id"
                    placeholder="Name or email"
                    beforeList="Fetching data..."
                    :open-direction="openDirection"
                    @input="handleAddCollab"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    :taggable="true"
                    tag-placeholder=""
                    tag-position="bottom"
                    @tag="handleSendInvite"
                >   
                    <template slot="option" slot-scope="props">
                        <div class="dropdown-user-name" v-if="!props.option.isTag">
                            <i class="fa fa-user-plus text-gray"></i>
                            <span class="option__title ml-2"> {{ $tools.capitalize(props.option.first_name) }} {{ $tools.capitalize(props.option.last_name) }}</span>
                            <span class="option__small text-purple ml-3">{{ props.option.email }}</span>
                        </div>
                        <div v-else-if="requestId != 'new'">
                            <i class="fa fa-envelope text-gray"></i>
                            <small class="ml-3"> Invite '{{ props.option.label }}' via email </small>
                        </div>
                    </template>
                </multiselect>
            </div>
            <div class="mt-4">
                <label class="form-control-label">
                    Stakeholder <span v-if="isNew" class="text-red">*</span>
                </label>
                <multiselect
                    v-if="canUpdateStakeholder"
                    :disabled="!stakeholderList.length"
                    :options="stakeholderList"
                    :close-on-select="true"
                    :clear-on-select="false"
                    v-model="stakeholder"
                    :custom-label="getName"
                    track-by="id"
                    placeholder="Name or email"
                    beforeList="Fetching data..."
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    :allowEmpty="false"
                    @input="stakeholderChanged = true"
                    :open-direction="openDirection"
                    class="stakeholder-container"
                    :title="`${stakeholder.first_name} ${stakeholder.last_name}`"
                >   
                    <template slot="option" slot-scope="props">
                        <div class="dropdown-user-name">
                            <i class="fa fa-user-plus text-gray"></i>
                            <span class="option__title ml-2" v-if="props.option.first_name"> {{ $tools.capitalize(props.option.first_name) }} {{ $tools.capitalize(props.option.last_name) }}</span>
                            <span class="option__title ml-2" v-else> {{ props.option.name }}</span>
                            <span class="option__small text-purple ml-3">{{ props.option.email }}</span>
                        </div>
                    </template>
                </multiselect>
                <div v-else class="collab-members">
                    <div class="avatar-group">
                        <div class="avatar avatar-md rounded-circle">
                            <img :src="getStakeholderPhoto" v-if="getStakeholderPhoto"  :title="getName(stakeholder)"/>
                            <span class="collab-member-initial" v-if="!getStakeholderPhoto"  :title="getName(stakeholder)">
                                {{ $tools.getInitial(getName(stakeholder) || stakeholder.email) }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isNew" class="text-right mb-4 px-4">
            <div class="status-value" v-if="loading"><loader :loading="loading" size="15px" /></div>
            <template v-else>
                <base-button type="link" class="btn-radius my-1" :size="'md'" @click="handleCancelUpdateCollaborators">Cancel</base-button>
                <base-button type="success" class="btn-radius my-1" :size="'md'" @click="handleUpdateCollaborators">Update</base-button>
            </template>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from "vuex"
    import debounce from 'lodash/debounce'
    import upperFirst from 'lodash/upperFirst'
    import Multiselect from 'vue-multiselect'
    import qs from "qs";

    export default {
        name: 'request-collaborator-section-new',
        components: {
            Multiselect
        },
        props: {
            openDirection: {
                type: String,
                default: 'bottom'
            }
        },
        data: () => ({
            loading: false,
            keyword: '',
            isFocus: false,
            showUsers: false,
            hover_index: -1,
            base64Image: null, 
            colabHighlight: false
        }),
        methods: {
            toggleFocus(event) {
                if (event?.relatedTarget?.classList?.contains('dropdown-item'))
                    return

                this.isFocus = !this.isFocus
            },
            handleFocus() {
                this.handleSearchChange()
                this.toggleFocus()
            },
            handleToggleCollab() {
                if (!this.showUsers) {
                    this.handleSearchChange()
                    this.showUsers = !this.showUsers
                }
            },
            handleResetRequest(client) {
                this.$store.commit('REQUEST/resetState', client)
                this.showUsers = false
                this.stakeholder = this.loggedInUser
                this.notifyCollaborators = true
            },
            async handleAddCollab(user) {
                await this.$store.commit('REQUEST/setRequestCollaborator', user)
                
                //  stakeholder is removed from collaborators
                const stakeholdeInCollabs = user.find(v => v.id === this.stakeholder.id)
                if (!stakeholdeInCollabs) {
                    this.stakeholder = this.loggedInUser
                }
            },
            async handleUpdateCollaborators() {
                if (!this.collaborators.length) {
                    this.$notify({
                        icon: 'fa fa-times',
                        type: 'danger',
                        title: 'Error',
                        message: `Project must have a collaborator`,
                    })
                    return
                }
                
                this.loading = true
                await this.$store.dispatch('REQUEST/setCollaborators', {
                    id: this.requestId,
                    users: this.collaborators.map(v => v.id),
                    stakeholder: {
                        id: this.stakeholder.id,
                        first_name: this.stakeholder.first_name,
                        last_name: this.stakeholder.last_name,
                        name: this.stakeholder.name || `${this.stakeholder.first_name} ${this.stakeholder.last_name}`,
                        email: this.stakeholder.email,
                        role: this.stakeholder.role
                    }
                })

                this.loading = false
                this.stakeholderChanged = false

                this.$notify({
                    icon: "ni ni-check-bold",
                    type: "success",
                    title: "Success",
                    message: `Collaborators updated`,
                })

                this.showUsers = false
            },
            async handleCancelUpdateCollaborators() {
                await this.$store.dispatch('REQUEST/getRequestData', this.requestId)
                this.showUsers = false
            },
            async handleSendInvite(collab) {
                if (this.requestId == 'new') {
                    return
                }

                if (!this.$tools.validateEmail(collab)) {
                    this.$notify({
                        icon: 'fa fa-times',
                        type: 'danger',
                        title: 'Error',
                        message: `Please provide a valid email`,
                    })

                    return
                }
                    
                this.toggleFocus()
                this.handleToggleCollab()

                const request = await this.$store.dispatch('INVITE/createInvite', {
                    request_ids: [this.requestId],
                    client_id: this.client_id,
                    email: collab
                })

                if (request) {
                    this.$notify({
                        icon: "ni ni-check-bold",
                        type: "success",
                        title: "Success",
                        message: `Invitation sent to ${collab}`,
                    })
                }
            },
            handleSearchChange() {
                const params = qs.stringify({
                    id: 'new',
                    type: 'available',
                    client_id: this.client.id
                })
                this.$store.dispatch("REQUEST/getRequestCollaborators", {
                    params,
                    id: 'new'
                })
            },
            handleSelectClient(){
                this.$store.dispatch("CLIENT/getClientCustomQuestions", this?.fields?.client_id);
            },
            getColors(index) {
                const colors = ['bg-success','bg-purple','bg-warning','bg-info','bg-primary','bg-danger']
                if (!colors[index])
                    return colors[this.$tools.randomNumber(5)]
                return colors[index]
            },
            getName(user) {
                return user?.name || `${user?.first_name} ${user?.last_name}`
            },
            async getClientList() {
                const params = qs.stringify({
                  status: 'Active',
                  fields: ['id', 'name','profile_image']
                })
                await this.$store.dispatch('CLIENT/getAllClientListData', params)
            },
            getPhoto(client) {
                return client.profile_image || null
            }
        },
        computed: {
            ...mapGetters("AUTH", [
                'isUser',
                'isAdmin',
                'isUserAndClientIsNotActive'
            ]),
            ...mapState('REQUEST', {
                request: state => state.request,
                users: state => state.collaborators,
            }),
            ...mapState('CLIENT', {
                clients: state => state.data,
                custom_questions: (state) => state.custom_questions,
            }),
            ...mapState('AUTH', {
                loggedInUser: state => state.user
            }),
            clientList() {
                return this.clients.map(each => ({
                    ...each,
                    name: upperFirst((each?.name).trim()),
                }))
            },
            client: {
                get() {
                    if (this.isUser && this.loggedInUser) {
                        return this.loggedInUser.client
                    }
                    return this.request?.client || null
                },
                set(data) {
                    this.handleResetRequest(data)
                }
            },
            shouldInviteUser() {
                return !this.loading
                    && !this.users.length
                    && !this.isNew
                    && this.keyword
                    && this.$tools.validateEmail(this.keyword)
            },
            client_photo() {
                return (
                    this?.request?.client?.profile_image ||
                    this?.client?.profile_image || this.base64Image
                )
            },
            client_name() {
                return (
                    this?.client?.name ||
                    this?.request?.client_name || ''
                )
            },
            client_id() {
                return (
                    this?.request?.client_id ||
                    this?.client?.id || ''
                )
            },
            members() {
                return this.request?.members || []
            },
            stakeholderList() {
                return this.collaborators.filter(v => v.role?.toUpperCase() == 'USER' && v.id != this.loggedInUser.id)
            },
            requestId() {
                return this.$route.params.id || this.request?.id || 'new'
            },
            debounceSetTableLonger() {
                return debounce(this.handleSearchChange, 500)
            },
            search: {
                get() {
                    return this.keyword
                },
                set(keyword) {
                    this.keyword = keyword
                    this.debounceSetTableLonger()
                },
            },
            isNew() {
               return this.$route.name !== 'request details'
            },
            getStakeholderPhoto() {
                return this.collaborators.find(v => v.id == this.stakeholder.id)?.profile_image || null
            },
            canUpdateStakeholder() {
                return this.isAdmin ? true : (this.stakeholderChanged || this.stakeholder?.id == this.loggedInUser?.id || this.request?.request_status.toUpperCase() === 'DRAFT')
            },
            notifyCollaborators: {
                get() {
                    return this.request?.properties?.notifyCollaborators == undefined ? true :  this.request?.properties?.notifyCollaborators
                },
                set(data) {
                    this.$store.commit('REQUEST/setNotifyCollaborators', data)
                }
            },
            collaborators: {
                get() {
                    return this.request?.collaborators || this.request?.members || []
                },
                set() {}
            },
            stakeholder: {
                get() {
                    return this.request?.properties?.stakeholder || this.loggedInUser
                },
                set(data) {
                    this.$store.commit('REQUEST/setStakeholder', data)
                }
            }
        },
        created() {
            this.$root.$refs.Collaborator = this;
            if(this.isAdmin) {
                this.getClientList()
            }

            if (this.isNew) {
                this.stakeholder = this.loggedInUser
                this.notifyCollaborators = true
            }
        }
    }
</script>
<style>
    .stakeholder-container .multiselect__single {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 30em; 
    }
</style>