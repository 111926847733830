import * as yup from 'yup'

const createRequestScheme = yup.object().shape({
  name: yup.string().required(),
  description: yup.string()
})

export const validateSchema = data => {
	try {
    createRequestScheme.validateSync(data, { abortEarly: false, allowUnknown: true })
    return {
      errors: {},
      isValid: true,
    }
  } catch (error) {
    const errors = error.inner.reduce((acc, el) => {
      const { path, message } = el
      acc[path] = message
      return acc
    }, {})
    return { isValid: false, errors }
  }
}

export const validateInput = (key, fields, errors) => {
	const { errors: vErrors } = validateSchema(fields)
	return { ...errors, [key]: vErrors[key] }
}

export const columns = [
  {
      name: 'Created Date',
      key: 'created_date',
      sort: true,
  },
	{
		name: 'Project Name',
		key: 'name',
		sort: true,
    width: '20%'
	},
  {
    name: 'Progress',
    key: 'progress',
    sort: false,
  },
  {
    name: 'Latest Deliverables',
    key: 'latest_deliverables',
    sort: false,
    class: "text-center",
    width: '10%'
  },
  {
      name: 'Client',
      key: 'client_name',
      sort: true,
  },
	{
		name: 'Created By',
		key: 'request_by',
		sort: true,
	},
	{
		name: 'Collaborators',
		key: 'collaborators',
		sort: false,
	}
]