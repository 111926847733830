import store from '@/store';
import isNaN from 'lodash/isNaN';
import { v4 } from 'uuid';
import Vue from 'vue';
import axios from '@/utils/axios'
import Axios from 'axios'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
const SIGNATURE_ENDPOINT = process.env.VUE_APP_DOCUMENT_SIGNATURE_ENDPOINT;
const BUFFER = Buffer.from(`${process.env.VUE_APP_API_USERNAME}:${process.env.VUE_APP_API_PASSWORD}`);
const DEFAULT_OPTIONS = {
  onError: () => null,
  onSuccess: () => null,
  onCancel: () => null,
  onUpdatePercentage: () => null,
};

export default class UploadDocument {
  constructor(file, options = {}) {
   
    if (!file)
      throw 'Unable to read file';
    
    this.file = file;
    this.options = {
      id: v4(),
      ...DEFAULT_OPTIONS,
      ...options
    };
    
    this.reader = new FileReader();
    this.readFile();
    //this.initialize();
    // const body = JSON.stringify({
    //   file,
    //   resource: this?.options?.resource,
    //   resource_id: this?.options?.resource_id,
    //   directory: this?.options?.directory,
    //   resource_type: this?.options?.resource_type,
    // });

    // //
    // //
    // let formData = new FormData();
    // formData.append('file', JSON.stringify(this?.options?.file));
    // formData.append('options', this?.options);
    // formData.append('lastname', 'abuda');
    // console.log("FormData");
    // console.log(formData)
    // console.log(this?.options)
    // axios({
    //   method: "post",
    //   url: "/drive/uploadfile",
    //   data: formData,
    //   headers: { "Content-Type": "multipart/form-data" },
    // })
    //   .then(function (response) {
    //     //handle success
    //     console.log(response);
    //   })
    //   .catch(function (response) {
    //     //handle error
    //     console.log(response);
    //   });
    //  console.log(body);
  }
  
  percent = 0;
  totalChunks = 0;
  location = '';
  signatureId = '';
  minChunkSize = 25*1024*1024 // 10485760 10mb // 5242880;  // 05 mb
  maxChunkSize = 5368709120; // 05 gb
  // maxChunkSize = 15728640 // 15 mb
  // maxChunkSize = 26214400 // 25 mb
  // maxChunkSize = 1048576  // 01 mb
  
  async initialize() {
   // !this.isUploadCancelled() && await this.signDocument();
   console.log('upload initialize')
    this.readFile();
  }
  
  async signDocument() {
    const { name, type } = this.file;
    const body = JSON.stringify({
      name,
      mimeType: type,
      resource: this?.options?.resource,
      resource_id: this?.options?.resource_id,
      directory: this?.options?.directory,
      resource_type: this?.options?.resource_type,
    });
    
    const { id, location } = await this.request({
      body,
      method: 'POST',
      url: SIGNATURE_ENDPOINT,
      headers: { 'Content-Type': 'application/json' }
    })
      .then((data) => data.json())
      .catch(error => {
        Vue.prototype.$notify({
          icon: 'fa fa-exclamation-triangle',
          type: 'danger',
          title: 'Upload Error',
          message: error.message
        });
      });
    
    this.signatureId = id;
    this.location = location;
  }

  async uploadFile(file){
    console.log('uploadFile');
    console.log('resource type',this?.options?.resource_type);
    console.log('options',this?.options);
    //console.log(this.reader.readAsArrayBuffer(this.file));
     const body = JSON.stringify({
      pasa:file,
      resource: this?.options?.resource,
      resource_id: this?.options?.resource_id,
      directory: this?.options?.directory,
      resource_type: this?.options?.resource_type,
    });
    
     await this.request({
      body,
      method: 'POST',
      url: '/api/drive/upload',
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((data) => data.json())
      .catch(error => {
        Vue.prototype.$notify({
          icon: 'fa fa-exclamation-triangle',
          type: 'danger',
          title: 'Upload Error',
          message: error.message
        });
      });
  }
  
  async request(options) {
    return fetch(options?.url, {
      ...options,
      headers: {
        ...options?.headers,
        'x-csrf-token': store.getters['AUTH/csrf'],
        'Authorization': `Basic ${BUFFER.toString('base64')}`
      }
    });
  }
  
  async beginUpload({ url, method }, unit8) {
    const that = this;
    const chunks = that.getChunks();
    this.totalChunks = chunks.length;
    
    return (function upload(index) {
      const chunk = chunks[index];
      
      if (!chunk)
        throw new Error('Chunk file is out of bounce!');
      
      const { range, startByte, endByte } = chunk;
      const headers = { 'Content-Range': range };
      const body = unit8.slice(startByte, endByte + 1);
      
      if (that.isUploadCancelled()) {
        return that.options?.onCancel();
      }
      
      return that.request({ headers, body, url, method }).then(async (response) => {
        const status = response.status;
        
        if (that.isUploadCancelled()) {
          return that.options?.onCancel();
        }
        
        if ([500, 429, 404, 403, 401, 400].includes(status)) {
          const jsonError = await response.json();
          throw new Error((jsonError?.error?.message || DEFAULT_ERROR_MESSAGE));
        }
        
        if (status === 308) {
          that.updatePercentage(index);
          return upload(index + 1);
        }
        
        if (status === 200) {
          that.updatePercentage(index);
          return response.json();
        }
      });
    })(0);
  }
  
  async readFile() {
    let fileName = this?.options?.resource_id;

    if(this?.options?.file?.item) {
      fileName = this?.options?.resource_id + this?.options?.file?.item?.fullPath
    } else {
      fileName = `${this?.options?.resource_id}/${this?.options?.file?.file?.name}`
    }

    if(this.file.size > this.minChunkSize) {
      let instance = await this.CreateMultiPartInstance(fileName)
      this.StartMultiPartUpload3(instance);
    } else {
      const reader = new FileReader
      reader.readAsDataURL(this.file)
      reader.onload = (e) => {
        this.StartUpload(e.target.result, fileName)
      }
    }  
  }

  convertFileToUInt8Array(value){
    var fileByteArray = [];

    var arrayBuffer = value,
      array = new Uint8Array(arrayBuffer)

      for (let i = 0; i < array.length; i++) {
          fileByteArray.push(array[i])        
      }
    
    return fileByteArray
  }

  getChunks() {
    const fileSize = this?.file?.size;
    const maxChunkSize = this.maxChunkSize;
    
    const endByte = ((size, length) => {
      const content = size % length;
      return content !== 0 ? content : 0;
    })(fileSize, maxChunkSize);
    
    const totalChunks = Math.ceil(fileSize / maxChunkSize);
   
    return Array(totalChunks)
      .fill(0)
      .map((chunk, index) => {
        let start = index * maxChunkSize;
        
        if (index + 1 === totalChunks) {
          const end = endByte > 0 ? start + endByte - 1 : start + maxChunkSize - 1;
          
          return {
            range: `bytes ${start}-${end}/${fileSize}`,
            startByte: start,
            endByte: end,
            length: maxChunkSize,
            total: fileSize,
          };
        }
        
        if (index < totalChunks) {
          const end = start + maxChunkSize - 1;
          
          return {
            range: `bytes ${start}-${end}/${fileSize}`,
            startByte: start,
            endByte: end,
            length: maxChunkSize,
            total: fileSize,
          };
        }
      })
      .filter((chunk) => chunk); // make sure no undefined
  }
  
  isUploadCancelled() {
    return Boolean(sessionStorage.getItem(this.options.id));
  }
  
  cancelUpload() {
    sessionStorage.setItem(this.options.id, 'canceled');
  }
  
  updatePercentage(chunkIndex = 0, totalChunks = this.totalChunks) {
    let percent = Math.ceil(((chunkIndex + 1) / totalChunks) * 100);
    
    // this.totalChunks = totalChunks
    // this.percent = Math.ceil(((chunkIndex + 1) / this.totalChunks) * 100)
    
    if (isNaN(percent))
      percent = 0;
    
    if (percent >= 100)
      this.file = null;
    
    this.percent = percent;
    this.options?.onUpdatePercentage(percent);
  }

  async StartUpload(file, key) {
    if((this.options.resource == 'user' || this.options.resource == 'client') && this.options.resource_type == 'profile') {
      // key = `user-profile-pics/${this?.options?.resource_id}/profile_image`
      this.uploadProfilePic(file);
      return;
    }
    console.log("Pang s3 ni xa");
    console.log(this.options);
    let uploadData = {
      file: file,
      resourceId: key,
      contentType: this.file.type,
      resource: this.options.resource,
      request_id: this.options.resource_id
    }
   
    try {
      if (this.isUploadCancelled()) {
        return this.options?.onCancel();
      }

      return axios.request(
              { url: '/s3/upload',
                method: 'POST',
                data: uploadData,
              },
      ).then(async (response) => {
        const success = response.success;

        if (this.isUploadCancelled()) {
          return this.options?.onCancel();
        }

        if (success) {
          this.options?.onUpdatePercentage(100, response.s3);
          return response;
        }
      }) 
    } catch (e) {
      console.log('Error! \nAn error occured: \n', e)
     alert('Error! \nAn error occured: \n' + e);
    }
   
   }

  async uploadProfilePic(file) {
    try {
      if (this.isUploadCancelled()) {
        return this.options?.onCancel();
      }

      let uploadData = {
        user_id: this?.options?.resource_id,
        base64: file,
      }
      
      return axios.request(
              { url: `/${this.options.resource}/profile-image`,
                method: 'PUT',
                data: uploadData,
              },
      ).then(async (response) => {
        console.log('response', response);
        const success = response.success;

        if (this.isUploadCancelled()) {
          return this.options?.onCancel();
        }

        if (success) {
          this.options?.onUpdatePercentage(100);
          return response;
        }
      }) 
    } catch (e) {
      console.log('Error! \nAn error occured: \n', e)
     alert('Error! \nAn error occured: \n' + e);
    }
  } 

   // StartMultiPartUpload not working on video file and increases the file size when uploaded to s3
  async StartMultiPartUpload(createInstance){
    if (this.isUploadCancelled()) {
      return this.options?.onCancel();
    }

    let fileSize = this.file.size
    // let multipartMap = { Parts: [] }
    let multipartMap = { Parts: [] }
    let axioss = Axios.create()
    delete axioss.defaults.headers.put['Content-Type']

    var UploadInstance = async (response) => {

      let partParams = {
        // body: response.data,
        fileName: createInstance.fileName,
        partNumber: String(response.partNum),
        uploadId: createInstance.uploadId,
        // maxUploadRetries: 3
      }
      let uploadSignedUrl = await this.UploadMultipartSignedUrl(partParams)
      console.log('uploadSignedUrl: ', uploadSignedUrl)

      const resParts = await Promise.all([axioss.put(uploadSignedUrl.result, response.data)])
      resParts.map((part) => {
        multipartMap.Parts.push({
          ETag: part.headers.etag,
          PartNumber: response.partNum
        })
      })

      let percent = Math.ceil((response.offset / fileSize) * 100)
      if (isNaN(percent))
      percent = 0;

      if (percent >= 100)
      this.file = null;

      this.percent = percent;
      this.options?.onUpdatePercentage(percent);
    }
    
    var CompleteInstance = async () => {
      const params = {
        fileName: createInstance.fileName,
        multipartMap: multipartMap,
        uploadId: createInstance.uploadId,
      }
  
      await this.CompleteMultiPartInstance(params); 
      console.log('CompleteInstance')
    }

    var AbortInstance = async () => {
      const params = {
        fileName: createInstance.fileName,
        uploadId: createInstance.uploadId,
      }
      let result = await this.AbortMultipartInstance(params);  
      console.log('res: ', result)
    }

    this.parseFile(this.file, async function(response) {
      if(response.success){
       await UploadInstance(response)
      }

      if(response.error) {
        console.log('Err: ', response.data)
        await AbortInstance()
        return
      }

      if(response.done){
        console.log('Done')
        await CompleteInstance()
        return
      }

    })
  }

  getChunkSize(){
    const fileSize = this.file.size

    if(fileSize >= (200000*1024*1024)) { // 200gb
      return fileSize * 0.0001 
    }  
    return this.minChunkSize
  }

  async StartMultiPartUpload3(createInstance) {
    try {
      const chunkSize = this.getChunkSize();
      const  fileSize = this.file.size
      const params = {
        file: this.file,
        fileSize: fileSize,
        chunkSize: chunkSize,
        chunks: Math.ceil(fileSize/chunkSize, chunkSize) - 1,
        fileName: createInstance.fileName,
        uploadId: createInstance.uploadId,
      }
  
      if (this.isUploadCancelled()) {
        console.log('Cancelled')
        console.log('Aborting Operation')
        const params = {
          fileName: createInstance.fileName,
          uploadId: createInstance.uploadId,
        }
        await this.AbortMultipartInstance(params);  
        return this.options?.onCancel();
      }

      let multipartMap = { Parts: [] }

      this.PrepareSignedURLS(params, (urls) => {
        this.UploadDataToUrls(params, urls, (parts) => {
          parts.map((part, index) => {
            multipartMap.Parts.push({
              ETag: part.headers.etag,
              PartNumber: index + 1
            })
          })

          const params = {
            fileName: createInstance.fileName,
            multipartMap: multipartMap,
            uploadId: createInstance.uploadId,
          }
            
          this.CompleteMultiPartInstance(params); 
        })
      })
     
    } catch (error) {
      console.log('error: ', error)
    }
  }

  UploadDataToUrls(params, urls, callback) {
    try {
      this.notification('Preparing to Upload the Assets...', 'Please wait')
      let chunk = 0
      let promises = [];

      const axioss = Axios.create()
      delete axioss.defaults.headers.put['Content-Type']

      urls.forEach(url => {
        const offset = chunk * params.chunkSize
        const blob = params.file.slice(offset, offset+params.chunkSize)

        promises.push(axioss.put(url.result, blob))
        chunk++
      });
      this.notification('Uploading Assets...', 'Please wait')
      this.runPromises(promises, (p) => {
        console.log('uploading: ', p.toFixed(2))
        this.options?.onUpdatePercentage(p.toFixed(2));
      }, (parts) => {
        callback(parts)
      })


    } catch (error) {
      console.log('error: ', error)
    }
  }

  PrepareSignedURLS(params, callback) {
    try {
      this.notification('Initializing upload...', 'Please wait')
      let chunk = 0
      let partNum = 1

      let promises = [];

      while (chunk <= params.chunks) {
        let partParams = {
          fileName: params.fileName,
          partNumber: String(partNum),
          uploadId: params.uploadId,
        }
        promises.push(axios.request({url: 's3/upload-multipart-signedurl', method: 'POST', data: partParams }))
        chunk++
        partNum++
      }

      this.runPromises(promises, (p) => {
          console.log('fetching urls: ', p.toFixed(2))
      }, (urls) => {
        callback(urls)
      })

    } catch (error) {
      console.log('error: ', error)
    }
  }

  async StartMultiPartUpload2(createInstance){
    try {
      const file = this.file
      const fileSize = file.size
      const chunkSize = this.getChunkSize()
      console.log('fileSize: ', fileSize)
      console.log('chunkSize: ', chunkSize)
      const chunks = Math.ceil(fileSize/chunkSize, chunkSize) - 1
      console.log('chunks: ', chunks)
      let chunk = 0
      let partNum = 1

      let multipartMap = { Parts: [] }
      const axioss = Axios.create()
      delete axioss.defaults.headers.put['Content-Type']

      try {
        while (chunk <= chunks) {
          console.log('chunk: ', chunk)
          if (this.isUploadCancelled()) {
            console.log('Cancelled')
            console.log('Aborting Operation')
            const params = {
              fileName: createInstance.fileName,
              uploadId: createInstance.uploadId,
            }
            await this.AbortMultipartInstance(params);  
            return this.options?.onCancel();
          }
          
          const offset = chunk * chunkSize
          const blob = file.slice(offset, offset+chunkSize)
          
          let partParams = {
            fileName: createInstance.fileName,
            partNumber: String(partNum),
            uploadId: createInstance.uploadId,
          }
          
          let uploadSignedUrl = await this.UploadMultipartSignedUrl(partParams)
  
          const resParts = await Promise.all([axioss.put(uploadSignedUrl.result, blob)])
          resParts.map((part) => {
            multipartMap.Parts.push({
              ETag: part.headers.etag,
              PartNumber: partNum
            })
          })
    
          let percent = Math.ceil((chunk / chunks) * 100)
          if (isNaN(percent))
          percent = 0;
    
          if (percent >= 100)
          this.file = null;
    
          this.percent = percent;
          this.options?.onUpdatePercentage(percent);
  
          chunk++
          partNum++
        }
      
        if(chunk > chunks){
          console.log('CompleteInstance')
          const params = {
            fileName: createInstance.fileName,
            multipartMap: multipartMap,
            uploadId: createInstance.uploadId,
          }
            
          await this.CompleteMultiPartInstance(params); 
        }


      } catch (error) {
        console.log('error: ', error)
        console.log('Aborting Operation')
        const params = {
          fileName: createInstance.fileName,
          uploadId: createInstance.uploadId,
        }
        await this.AbortMultipartInstance(params);  
      }

          } catch (error) {
      console.log('error: ', error)

    }
  }

  async runPromises(proms, progress_cb, callback) {
    let d = 0;
    progress_cb(0);
    for (const p of proms) {
      p.then(()=> {    
        d ++;
        const percent = (d * 100) / proms.length;
        progress_cb(percent);
      });
    }
    Promise.all(proms).then((values) => callback(values));
}

  async CreateMultiPartInstance(fileName){
    let data = {
      fileName: fileName,
      contentType: this.file.type
    }

    const response =  await axios({url: 's3/create-multipart', method: 'POST', data: data }) 
    return {
      fileName: fileName,
      uploadId: response.multipart.UploadId
    }
  }

  async UploadMultiPartInstance(data){
    return await axios({url: 's3/upload-multipart', method: 'POST', data: data }) 
  }

  async CompleteMultiPartInstance(data){
    if (this.isUploadCancelled()) {
      return this.options?.onCancel();
    }

    const response = await axios({url: 's3/complete-multipart', method: 'POST', data: data }) 
    this.notification('Your assets have been successfully uploaded')
    console.log('CompleteMultiPartInstance', response)
    this.options?.onUpdatePercentage(100, response.s3)
    return response
  }

  async AbortMultipartInstance(data) {
    return await axios({url: 's3/abort-multipart', method: 'POST', data: data }) 
  }

  async UploadMultipartSignedUrl(data){
    return await axios({url: 's3/upload-multipart-signedurl', method: 'POST', data: data }) 
  }

  parseFile(file, callback){
    var fileSize  = file.size
    var chunkSize = this.minChunkSize
    var offset    = 0
    var chunkReaderBlock = null
    var partNum   = 0

    var readEventHandler = async (e) => {
      var result = e.target.result
     
      if(result.error == null){
        offset += result.byteLength
        partNum++
        const arrResult = Array.from(new Uint8Array(result))
        console.log('offset: ', offset)
        let callbackResult = await callback({
          success: true,
          data: arrResult,
          partNum: partNum,
          offset: offset
        })
        console.log('callbackResult: ', callbackResult)
      } else {
        console.log('Err: ', result.error)
        callback({
          error: true,
          data: result.error
        })
        return
      }

      if(offset >= fileSize){
        console.log('offset: ', offset)
        console.log('fileSize: ', fileSize)
        console.log('Done Reading')
        callback({
          done: true,
        })
        return
      }

      chunkReaderBlock(offset, chunkSize, file)
    }

    chunkReaderBlock = (_offset, length, _file) => {
        if (this.isUploadCancelled()) {
          console.log('Cancelled')
          callback({
            error: true,
            data: 'Cancelled'
          })
          return this.options?.onCancel();
        }
        
        var r = new FileReader()
        var blob = _file.slice(_offset, length + _offset)
        r.onload = readEventHandler
        r.readAsArrayBuffer(blob)
    }

    chunkReaderBlock(offset, chunkSize, file)
  }

  notification(title, message, error = false){
    let icon = 'fa fa-info-circle'
    let type = 'success'
    if (error) {
        icon = 'fa fa-exclamation-triangle'
        type = 'danger'
    }

    Vue.prototype.$notify({
        icon: icon,
        type: type,
        title: title,
        message: message
    });
}

}

