<template>
  <div :class="`page-${$route.name}`">
    <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8" />

    <loader :loading="loading" class="mt-4"/>
    <div class="container-fluid mt--8" v-if="!loading">
      <div class="row justify-content-end mb-2">
        <div class="col col-xl-3 col-md-6">
          <base-input
              placeholder="Search"
              class="input-group-alternative"
              alternative=""
              addon-right-icon="fas fa-search"
              v-model="search"
          />
        </div>
        <div class="col col-xl-2 col-md-5">
          <base-button
              block
              class=""
              icon="fa fa-plus"
              type="success"
              @click="handleToggleDialog"
          >Create Question
          </base-button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent no-border">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Questions</h3>
                </div>
                <div class="col text-right">
                  <!-- <base-button type="primary" size="sm">See all</base-button> -->
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <base-table
                  class="table align-items-center table-flush"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="records"
                  :columns="columns"
                  :sortDirection="sort.direction"
                  :sortColumn="sort.column"
                  v-model="sort"
              >
                <template slot-scope="{row}">
                  <tr>
                    <td class="">{{ $moment(row.created_date).format('L') }}</td>
                    <td class="">{{ $tools.capitalize(row.status) }}</td>
                    <td class="long-text">{{ row.is_required }}</td>
                    <td class="long-text">{{ $tools.capitalize(row.question) }}</td>
                    <td class="long-text">{{ row.order }}</td>
                    <td class="long-text">{{ row.comment }}</td>
                    <td class="table-custom-action">
                      <base-button
                          type="neutral"
                          icon="fa fa-edit"
                          rounded
                          @click.prevent="handleEdit(row)"
                      />
                      <base-button
                          type="neutral"
                          rounded
                          icon="fa fa-archive"
                          @click.prevent="handleArchive(row)"
                      />
                      <base-button
                          type="neutral"
                          rounded
                          icon="fa fa-trash-alt"
                          @click.prevent="handleDelete(row)"
                      />
                    </td>
                  </tr>
                </template>
              </base-table>
            </div>
            <div class="card-footer d-flex justify-content-between bg-transparent">
              <div class="col-md-1 col-sm-12">
                <select v-model="size" class="form-control">
                  <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
                </select>
              </div>
              <base-pagination
                  :total="Number(total)"
                  :value="Number(pagination)"
                  :perPage="Number(table.size)"
                  v-model="pagination"
              />
            </div>
          </div>
        </div>
      </div>
      <router-view />
    </div>
    <modal :show="QuestionDialog" @close="handleToggleDialog" type="mini">
      <h3 slot="header" class="modal-title" id="modal-title-default">Create Form Question</h3>
      <div>
        <base-input
            required
            autoresize
            textarea
            label="Question"
            v-model="fields.question"
            :error="errors.question"
            :valid="!errors.question ? true : false"
            @input="validate('question')"
        />
        <base-input
            autoresize
            textarea
            label="Comment"
            placeholder="e.g. Just let us know where this content will appear"
            v-model="fields.comment"
            :error="errors.comment"
            :valid="!errors.comment ? true : false"
            @input="validate('comment')"
        >
        </base-input>
        <base-input
            number
            label="Order"
            v-model="fields.order"
            :error="errors.order"
            :valid="!errors.order ? true : false"
            @input="validate('order')"
        >
        </base-input>
        <base-checkbox
            v-model="fields.required"
            :error="errors.required"
            :valid="!errors.required ? true : false"
            :checked="fields.required"
            @input="validate('required')"
        >Required this field?
        </base-checkbox>
      </div>
      <template slot="footer">
        <base-button type="link" class="ml-auto btn-radius" @click="handleToggleDialog">Close</base-button>
        <base-button type="purple" class="btn-radius" @click.prevent="handleCreateQuestion">Save changes</base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { columns, validateInput, validateSchema } from '@/container/question/model';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import qs from 'qs';
import { mapState } from 'vuex';

export default {
  name: 'question-container',
  components: {},
  data: () => ({
    QuestionDialog: false,
    fields: {
      question: '',
      comment: '',
      required: false,
      order: '1',
    },
    errors: {
      question: '',
      comment: '',
      required: '',
      order: '',
    },
    columns: columns,
    table: {
      search: {
        fields: ['question', 'comment'],
        value: ''
      },
      sort: [
        {
          column: 'order',
          direction: 'asc'
        }
      ],
      page: 0,
      size: 10,
      sizeOptions: [
        10, 50, 100, 250, 500
      ]
    }
  }),
  created() {
    this.handleTableChange();
  },
  methods: {
    handleClearQuestionForm() {
      this.fields = {  
        question: '',
        comment: '',
        required: false,
      };
      this.errors = {
        question: '',
        comment: '',
        required: '',
      };
      this.QuestionDialog = false;
    },
    handleToggleDialog() {
      this.QuestionDialog = !this.QuestionDialog;
    },
    validate(field) {
      this.errors = validateInput(field, this.fields, this.errors);
    },
    async handleCreateQuestion() {
      console.log(this.fields);
      let request;
      const { isValid, errors } = validateSchema(this.fields);
      if (!isValid)
        return this.errors = errors;

      if (!this.fields.id)
        request = await this.$store
            .dispatch('QUESTION/createQuestion', this.fields);
      else
        request = await this.$store
            .dispatch('QUESTION/updateQuestion', this.fields);

      if (request) {
        this.handleTableChange();
        this.handleClearQuestionForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Question successfully ${this.fields.id ? 'updated' : 'created'}!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleEdit(row) {
      if (!row.id)
        return;

      this.fields = { ...row };
      this.handleToggleDialog();
    },
    async handleDelete(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to Delete this question?'))
        return;

      const request = await this.$store
          .dispatch('QUESTION/deleteQuestion', row);

      if (request) {
        this.handleTableChange();
        this.handleClearQuestionForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Question successfully deleted!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    async handleArchive(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to archive this question?'))
        return;

      const request = await this.$store
          .dispatch('QUESTION/updateQuestion', { ...row, status: 'Archived' });

      if (request) {
        this.handleTableChange();
        this.handleClearQuestionForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Question successfully archived!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleTableChange() {
      this.$store.dispatch('QUESTION/getQuestionList', qs.stringify(omit(this.table, ['sizeOptions'])));
    }
  },
  computed: {
    ...mapState('QUESTION', {
      records: state => state.data,
      total: state => state.total,
      loading: state => state.loading,
    }),

    debounceSetTableLonger() {
      return debounce(this.handleTableChange, 700);
    },
    debounceSetTable() {
      return debounce(this.handleTableChange, 250);
    },
    startCase: () => startCase,
    search: {
      get() {
        return this.table.search.value;
      },
      set(keyword) {
        this.table.page = 0;
        this.table.search.value = keyword;
        this.debounceSetTableLonger();
      }
    },
    size: {
      get() {
        return this.table.size;
      },
      set(size) {
        this.table.size = size;
        this.debounceSetTable();
      }
    },
    sort: {
      get() {
        return this.table.sort[0];
      },
      set(sort) {
        this.table.sort = [sort];
        this.debounceSetTableLonger();
      }
    },
    pagination: {
      get() {
        return this.table.page + 1;
      },
      set(page) {
        this.table.page = page - 1;
        this.debounceSetTable();
      }
    }
  }
};
</script>
<style></style>
