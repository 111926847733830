<template>
  
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header bg-transparent no-border">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">{{template.name}}</h3>
                </div>
               
              </div>
            </div>
            <div class="table-responsive">
              <base-table
                  class="table align-items-center table-flush"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="records"
                  :columns="columns"
                  :sortDirection="sort.direction"
                  :sortColumn="sort.column"
                  v-model="sort"
              >
                <template slot-scope="{row}">
                  <tr>
                    <td class="">{{ $moment(row.created_date).format('L') }}</td>
                    <td class="">{{ $tools.capitalize(row.status) }}</td>
                    <td class="long-text">{{ row.is_required }}</td>
                    <td class="long-text">{{ $tools.capitalize(row.question) }}</td>
                    <td class="long-text">{{ $tools.capitalize(row.order) }}</td>
                    <td class="long-text">{{ $tools.capitalize(row.comment) }}</td>
                    <td class="table-custom-action">
                      <base-button
                          type="neutral"
                          icon="fa fa-edit"
                          rounded
                          @click.prevent="handleEdit(row)"
                      />
                      <base-button
                           v-if="(row.status == 'Active') ? true:false"
                          type="neutral"
                          rounded
                          icon="fa fa-archive"
                          @click.prevent="handleArchive(row)"
                      />
                      <base-button
                          v-if="(row.status == 'Archived') ? true:false"
                          type="neutral"
                          rounded
                          icon="fa fa-bolt"
                          title="Set to Active"
                          @click.prevent.stop="handleActive(row)"
                      />
                    </td>
                  </tr>
                </template>
              </base-table>
            </div>
            <div class="card-footer d-flex justify-content-between bg-transparent">
              <div class="col-md-1 col-sm-12">
                <select v-model="size" class="form-control">
                  <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
                </select>
              </div>
              <base-pagination
                  :total="Number(total)"
                  :value="Number(pagination)"
                  :perPage="Number(table.size)"
                  v-model="pagination"
              />
            </div>
          </div>
        </div>
        <modal :show="QuestionDialog" @close="handleToggleDialog" type="mini">
      <h3 slot="header" class="modal-title" id="modal-title-default">Custom Form Question</h3>
      <div>
        <base-input
            required
            autoresize
            textarea
            label="Question"
            v-model="fields.question"
            :error="errors.question"
            :valid="!errors.question ? true : false"
            @input="validate('question')"
        />
        <base-input
            autoresize
            textarea
            label="Comment"
            placeholder="e.g. Just let us know where this content will appear"
            v-model="fields.comment"
            :error="errors.comment"
            :valid="!errors.comment ? true : false"
            @input="validate('comment')"
        >
        </base-input>
        <base-input
            number
            label="Order"
            v-model="fields.order"
            :error="errors.order"
            :valid="!errors.order ? true : false"
            @input="validate('order')"
        >
        </base-input>
        <base-checkbox
            v-model="fields.required"
            :error="errors.required"
            :valid="!errors.required ? true : false"
            :checked="fields.required"
            @input="validate('required')"
        >Required this field?
        </base-checkbox>
      </div>
      <template slot="footer">
        <base-button type="link" class="ml-auto btn-radius" @click="handleToggleDialog">Close</base-button>
        <base-button type="purple" class="btn-radius" @click.prevent="handleCreateCustomQuestion">Save changes</base-button>
      </template>
    </modal>
      </div>
      
</template>
<script>
import { columns, validateInput, validateSchema } from '@/container/template-details/model';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import qs from 'qs';
import { mapState } from 'vuex';

export default {
  name: 'template-question-container',
  components: {},
  data: () => ({
    QuestionDialog: false,
    fields: {
      question: '',
      comment: '',
      required: false,
      order: '1',
    },
    errors: {
      question: '',
      comment: '',
      required: '',
      order: '',
    },
    columns: columns,
    table: {
      search: {
        fields: ['question', 'comment'],
        value: ''
      },
      sort: [
        {
          column: 'order',
          direction: 'asc'
        }
      ],
      page: 0,
      size: 10,
      sizeOptions: [
        10, 50, 100, 250, 500
      ]
    }
  }),
  created() {
    console.log(this);
    this.$root.$refs.TemplateQuestion = this;
   
  },
 
  methods: {
    handleClearQuestionForm() {
      this.fields = {  
        question: '',
        comment: '',
        required: false,
      };
      this.errors = {
        question: '',
        comment: '',
        required: '',
      };
      this.QuestionDialog = false;
    },
    handleToggleDialog() {
      this.QuestionDialog = !this.QuestionDialog;
    },
    validate(field) {
      this.errors = validateInput(field, this.fields, this.errors);
    },
    async handleCreateCustomQuestion() {
     
     console.log("here");
      let request;
      const { isValid, errors } = validateSchema(this.fields);
      if (!isValid)
        return this.errors = errors;

      if (!this.fields.id)
        request = await this.$store
            .dispatch('CUSTOMQUESTION/createCustomQuestion', this.fields);
      else
        request = await this.$store
            .dispatch('CUSTOMQUESTION/updateCustomQuestion', this.fields);

      if (request) {
        this.handleTableList();
        this.handleClearQuestionForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Question successfully ${this.fields.id ? 'updated' : 'created'}!`
        });
        this.$root.$refs.TemplateDetails.handleGetDetails();
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
      
    },
    handleEdit(row) {
      if (!row.id)
        return;

      this.fields = { ...row };
      this.handleToggleDialog();
    },
    async handleDelete(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to Delete this question?'))
        return;

      const request = await this.$store
          .dispatch('CUSTOMQUESTION/deleteCustomQuestion', row);

      if (request) {
        this.handleTableList();
        this.handleClearQuestionForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Question successfully deleted!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    async handleArchive(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to archive this question?'))
        return;

      const request = await this.$store
          .dispatch('CUSTOMQUESTION/updateCustomQuestion', { ...row, status: 'Archived' });

      if (request) {
        this.handleTableList();
        this.handleClearQuestionForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Question successfully archived!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    
    async handleActive(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to activate this question?'))
        return
        

      const request = await this.$store
          .dispatch('CUSTOMQUESTION/updateCustomQuestion', { ...row, status: 'Active' });

      if (request) {
        this.handleTableList();
        this.handleClearQuestionForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Question successfully active!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    async handleTableList() {
     console.log("TEMPLATE QUESTION");
       await this.$store
          .dispatch('TEMPLATE/getTemplateQuestionsList', qs.stringify(omit(this.table, ['sizeOptions']))+'&template_id='+this.$route.params.id);
    }
  },
  computed: {
    ...mapState('TEMPLATE', {
     records: state => state.t_questions,
      total: state => state.total,
      loading: state => state.loading,
     
    }),
     template() {
       return this.$parent.template
      },
    t_question() {
      return this.$parent.template.c_question
    },
    
    debounceSetTableLonger() {
      return debounce(this.handleTableList, 700);
    },
    debounceSetTable() {
      return debounce(this.handleTableList, 250);
    },
    startCase: () => startCase,
    search: {
      get() {
        return this.table.search.value;
      },
      set(keyword) {
        this.table.page = 0;
        this.table.search.value = keyword;
        this.debounceSetTableLonger();
      }
    },
    computedOrder: {
       get() {
        return this.field.order.toString();
      },
      set(val) {
        this.field.order = Number(val)
      }
    },
    size: {
      get() {
        return this.table.size;
      },
      set(size) {
        this.table.size = size;
        this.debounceSetTable();
      }
    },
    sort: {
      get() {
        return this.table.sort[0];
      },
      set(sort) {
        this.table.sort = [sort];
        this.debounceSetTableLonger();
      }
    },
    pagination: {
      get() {
        return this.table.page + 1;
      },
      set(page) {
        this.table.page = page - 1;
        this.debounceSetTable();
      }
    }
  }
};
</script>
<style></style>
