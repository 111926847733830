<template>
     <div class="page-request-details" :key="componentKey">
        <b-tabs 
            content-class=""
            active-nav-item-class="font-weight-bold"
            nav-wrapper-class="mx-3"
            :no-key-nav="true"
            fill lazy
            >
            <b-tab v-if="!addDeliverableOnly" title="Details" :active="currentSectionIdx == 0" @click="currentSectionIdx = 0"></b-tab>
            <b-tab title="Order" :active="currentSectionIdx == 1" :disabled="!requestID" @click="currentSectionIdx = 1; handleOrderMenuClick()"></b-tab>
            <b-tab :active="currentSectionIdx == 2" :disabled="!requestID" @click="currentSectionIdx = 2">
                <template #title>
                    <div :title="infoMessage">
                        Summary <i v-if="infoMessage" class="fa fa-info-circle info-circle" aria-hidden="true"></i>
                    </div>
                </template>
            </b-tab>
            <b-tab v-if="!addDeliverableOnly"  title="Assets" :active="currentSectionIdx == 3" :disabled="!requestID"  @click="currentSectionIdx = 3"></b-tab>
            <b-tab v-if="!addDeliverableOnly" title="Notes" :active="currentSectionIdx == 4" :disabled="!requestID"  @click="currentSectionIdx = 4"></b-tab>
        </b-tabs>
        <small v-if="sections[currentSectionIdx] == 'deliverableSummary' && hasOrders" class="px-3"><i>Please select all the deliverables needed</i></small>
        <div class="body p-3" v-switch="sections[currentSectionIdx]">
            <div v-case="'projectDetails'" :class="{'project-details': sections[currentSectionIdx] == 'projectDetails'}" v-if="sections[currentSectionIdx] == 'projectDetails'">
                <base-input
                    required
                    input
                    :error="projectDetails.title.error"
                    label="Order Title"
                    v-model="projectDetails.title.value"
                    @input="validate(projectDetails.title)"
                    @blur="validate(projectDetails.title)"
                ></base-input>
                <base-input
                    inputClasses="p-2"
                    textarea
                    label="Project Overview"
                    placeholder="E.g. A collection of Meta, PMAX and Amazon videos for our Spring launch."
                    v-model="projectDetails.description.value"
                >
                </base-input>
                <div class="type mb-4">
                    <label class="form-control-label">
                        Is this paid or organic?
                    </label>
                    <div class="row">
                        <base-radio name="paid" v-model="projectDetails.type" @input="deliverables.platform.value = null">Paid</base-radio>
                        <base-radio name="organic" v-model="projectDetails.type" @input="deliverables.platform.value = null">Organic</base-radio>
                    </div>
                </div>
                <div class="collaborators-section mt-4">
                    <request-collaborator-section-v3 :open-direction="'above'"></request-collaborator-section-v3>
                </div>
            </div>
            <div v-case="'deliverables'" :class="{'deliverables': sections[currentSectionIdx] == 'deliverables'}" :key="deliverables.key">
                <div class="row">
                    <div class="col-lg">
                        <div class="mb-4">
                            <label class="form-control-label">
                                Platform
                                <span class="text-red">*</span>
                            </label>
                            <multiselect
                                :disabled="deliverables.platform.disabled"
                                :options="platforms"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :allow-empty="false"
                                placeholder="--- Select ---"
                                v-model="deliverables.platform.value"
                                @input="validate(deliverables.platform); handlePlatformChange()"
                            ></multiselect>
                            <slot>
                                <div v-if="deliverables.type.error" class="text-danger invalid-feedback" style="display: block;">
                                {{ deliverables.platform.error }}
                                </div>
                            </slot>
                        </div>
                        <div class="mb-4">
                            <label class="form-control-label">
                                Type
                                <span class="text-red">*</span>
                            </label>
                            <multiselect
                                :disabled="deliverables.type.disabled || !deliverables.platform.value"
                                :options="platformTypes"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :allow-empty="false"
                                placeholder="--- Select ---"
                                v-model="deliverables.type.value"
                                @input="validate(deliverables.type); handleDeliverableTypeChange()"
                            ></multiselect>
                            <slot>
                                <div v-if="deliverables.type.error" class="text-danger invalid-feedback" style="display: block;">
                                {{ deliverables.type.error }}
                                </div>
                            </slot>
                        </div>
                        <div class="mb-4" v-if="platformPlacements.length">
                            <label class="form-control-label">
                                Placement
                                <span class="text-red">*</span>
                            </label>
                            <multiselect
                                :disabled="deliverables.placement.disabled || !deliverables.type.value"
                                :options="platformPlacements"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :allow-empty="false"
                                track-by="id"
                                label="label"
                                placeholder="--- Select ---"
                                v-model="deliverables.placement.value"
                                @input="validate(deliverables.placement); handlePlacementChange()"
                            ></multiselect>
                            <slot>
                                <div v-if="deliverables.placement.error" class="text-danger invalid-feedback" style="display: block;">
                                {{ deliverables.placement.error }}
                                </div>
                            </slot>
                        </div>
                        <template v-if="deliverables.type.value && ['video', 'gif'].includes(deliverables.type.value.toLowerCase())">
                            <div class="mb-4">
                                <label class="form-control-label">
                                    Length (secs)
                                    <span class="text-red">*</span>
                                    <i class="fa fa-info-circle info-circle" aria-hidden="true"
                                        title="Please select how many different length versions of the video you would like.">
                                    </i>
                                </label>
                                <multiselect
                                    :disabled="!deliverables.placement.value && deliverables.placement.required"
                                    :options="deliverableTypeLength"
                                    :multiple="true"
                                    :searchable="true"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    placeholder="--- Select or input custom value ---"
                                    v-model="deliverables.length.value"
                                    :taggable="true"
                                    tag-placeholder="Add this as custom length"
                                    tag-position="bottom"
                                    @tag="handleAddCustomLength"
                                    @input="validate(deliverables.length)"
                                    @select="validate(deliverables.dimension)"
                                ></multiselect>
                                <slot>
                                    <div v-if="deliverables.length.error" class="text-danger invalid-feedback" style="display: block;">
                                    {{ deliverables.length.error }}
                                    </div>
                                </slot>
                            </div>
                        </template>
                        <div class="mb-4" v-if="deliverables.platform.value && deliverables.dimension.required">
                            <label class="form-control-label">
                                Dimensions
                                <span v-if="deliverables.dimension.required" class="text-red">*</span>
                                <i class="fa fa-info-circle info-circle" aria-hidden="true"
                                    title="Please select how many different sized versions of the video you would like. You can enter which dimensions you need for which lengths in the next step.">
                                </i>
                            </label>
                            <multiselect
                                :disabled="disableDimensions"
                                :options="platformDimensions"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :multiple="true"
                                :searchable="false"
                                placeholder="--- Select ---"
                                v-model="deliverables.dimension.value"
                                @input="validate(deliverables.dimension)"
                                @select="validate(deliverables.dimension)"
                            ></multiselect>
                            <slot>
                                <div v-if="deliverables.dimension.required && deliverables.dimension.error" class="text-danger invalid-feedback" style="display: block;">
                                {{ deliverables.dimension.error }}
                                </div>
                            </slot>
                        </div>
                        <div class="mt-4" v-if="deliverables.platform.value">
                            <label class="form-control-label">
                                {{ customDimensionsCTA }}
                                <span v-if="deliverables.customDimension.required" class="text-red">*</span>
                                <i class="fa fa-info-circle info-circle" aria-hidden="true"
                                    title="If your dimensions aren’t listed above, please tell us how many other versions you would like in different dimensions. You can enter the dimensions afterwards.">
                                </i>
                            </label>
                            <multiselect
                                :disabled="(!deliverables.placement.value && deliverables.placement.required) || !deliverables.type.value"
                                :options="customDimensions"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :allow-empty="false"
                                placeholder="--- Select ---"
                                v-model="deliverables.customDimension.length"
                                open-direction="above"
                                @select="deliverables.customDimension.error = ''"
                            ></multiselect>
                            <slot>
                                <div v-if="deliverables.customDimension.required && deliverables.customDimension.error" class="text-danger invalid-feedback" style="display: block;">
                                {{ deliverables.customDimension.error }}
                                </div>
                            </slot>
                            <div v-if="deliverables.customDimension.length" class="custom-dimensions-container mt-4">
                                <base-input
                                    v-for="index in deliverables.customDimension.length" :key="index"
                                    input
                                    :required="deliverables.customDimension.valuesRequired"
                                    :error="deliverables.customDimension.valuesRequired ? deliverables.customDimension.valuesValidator['required'](deliverables.customDimension.value[index-1]) : ''"
                                    :label="deliverables.placement.value && deliverables.placement.value.label.toLowerCase() == 'facebook carousel ad' ? 
                                        `Key Messaging for Image ${index}` : `Dimension ${index} size`"
                                    v-model="deliverables.customDimension.value[index-1]"
                                    @input="deliverables.customDimension.error = ''"
                                    @blur="deliverables.customDimension.valuesRequired &&
                                        deliverables.customDimension.valuesValidator['required'](deliverables.customDimension.value[index-1])"
                                ></base-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg">
                        <base-input
                            v-if="deliverables.keyMessaging.required"
                            autoresize
                            :required="deliverables.keyMessaging.required"
                            textarea
                            label="Key messaging"
                            placeholder="E.g. New product launch. 5 new summer styles. Dropping this weekend. "
                            v-model="deliverables.keyMessaging.value"
                            :error="deliverables.keyMessaging.error"
                            @input="validate(deliverables.keyMessaging)"
                            @blur="validate(deliverables.keyMessaging)"
                        ></base-input>
                        <base-input
                            autoresize
                            textarea
                            label="CTA"
                            placeholder="E.g. Pre-order now"
                            v-model="deliverables.CTA.value"
                        ></base-input>
                        <base-input
                            autoresize
                            textarea
                            label="What is the concept?"
                            placeholder="E.g. A fast-paced, stop-motion style video ad similar to this - https://pin.it/5mqvEqOdf. Big bold text + using music in time to the transitions."
                            v-model="deliverables.notes.value"
                        ></base-input>
                    </div>
                </div>
            </div>
            <div v-case="'deliverableSummary'" class="deliverables-summary" v-if="sections[currentSectionIdx] == 'deliverableSummary'" :key="v4">
                <div class="row">
                    <div v-for="(value, index) of deliverableSummary" v-bind:key="index" class="deliverable-container col-md"
                        :class="{'mr-2': index != (Object.keys(deliverableSummary).length - 1)}">
                        <b>{{ index.toUpperCase() }}</b>
                        <div v-for="(v, i) of value" v-bind:key="i" class="mt-4 mb-6">
                            <hr />
                            <div class="summary-details-platform-info my-2">
                                <img v-bind:src="platformLogo(v.platform, v.placement.label).src" 
                                :style="{ 'width': platformLogo(v.platform, v.placement.label).width || '5%' }"
                                :title="platformLogo(v.platform, v.placement.label).title"/>
                                <span class="ml-3">{{ v.placement.label }}</span>
                            </div>
                            <div class="summary-details">
                                <table>
                                    <thead>
                                        <th></th>
                                        <template v-if="deliverableHasLength(v.type.toLowerCase())">
                                            <th v-for="dimension of sort(v.dimensions).concat(v.customDimensions.filter(c => c))" 
                                                v-bind:key="dimension">
                                                {{ dimension }}
                                            </th>
                                            <th class="details-cell">Length</th>
                                        </template>
                                        <template v-else>
                                            <th></th>
                                        </template>
                                        <th class="details-cell">Dimension</th>
                                        <th class="details-cell long-text">Key Messaging</th>
                                        <th class="details-cell long-text">CTA</th>
                                        <th class="details-cell text-right">Action</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="details of v.summary" v-bind:key="details.id">
                                            <td class="length-cell"><b v-if="!details.skipDimension">{{ details.row }}{{ details.rowType == 'length' ? 's' : ''}}</b></td>
                                            <template v-if="details.rowType == 'length'">
                                                <td class="checkbox" v-for="dimension of sort(v.dimensions).concat(v.customDimensions.filter(c => c))" v-bind:key="dimension">
                                                    <base-checkbox v-if="!details.skipDimension" :checked="isDeliverableSelected(`${details.id}x${details.row}x${dimension}`, v)" 
                                                        @input="handleDeliverableSelected($event, { summary: details, deliverable: v, dimension, id: `${details.id}x${details.row}x${dimension}` })"></base-checkbox>
                                                </td>
                                            </template>
                                            <td class="checkbox" v-else>
                                                <base-checkbox v-if="!details.skipDimension" :checked="isDeliverableSelected(`${details.id}x${details.row}`, v)" 
                                                    @input="handleDeliverableSelected($event, { summary: details, deliverable: v, id: `${details.id}x${details.row}` })"></base-checkbox>
                                            </td>
                                            <template v-if="details.selected">
                                                <td v-if="deliverableHasLength(v.type.toLowerCase())" class="details-cell">{{ details.selected.length }}s</td>
                                                <td class="details-cell">{{ details.selected.dimension }}</td>
                                                <td class="details-cell long-text" :title="formatKeyMessaging(details.selected.keyMessaging, true)">
                                                    <template v-if="details.isDuplicate">
                                                        <base-input v-for="(messaging, index) of details.selected.keyMessaging.split('--and--')" :key="index"
                                                            class="editable mb-2"
                                                            required
                                                            textarea
                                                            placeholder="E.g. New product launch. 5 new summer styles. Dropping this weekend."
                                                            :value="messaging"
                                                            @input="handleEditSelectedDetails({deliverable: v, summary: details, field: 'keyMessaging', value: $event, index})"
                                                        ></base-input>
                                                    </template>
                                                    <span v-else v-html="formatKeyMessaging(details.selected.keyMessaging)"></span>
                                                </td>
                                                <td class="details-cell long-text" :title="details.selected.CTA">
                                                    <base-input v-if="details.isDuplicate"
                                                        class="editable mb-2"
                                                        textarea
                                                        placeholder="E.g. Pre-order now"
                                                        v-model="details.selected.CTA"
                                                        @input="handleEditSelectedDetails({deliverable: v, summary: details, field: 'CTA', value: details.selected.CTA})"
                                                    ></base-input>
                                                    <span v-else>
                                                        {{ details.selected.CTA }}
                                                    </span>
                                                </td>
                                                <td class="float-right text-right" style="width: 5vw">
                                                    <base-button v-if="details.isDuplicate" type="link" class="btn-radius" @click="handleSaveEditDuplicate({summary: details, deliverable: v})" :size="'sm'" title="Save"><i class="fa fa-check" aria-hidden="true"></i></base-button>
                                                    <base-button v-else type="link" class="btn-radius" @click="handleDuplicateDeliverable({summary: details, deliverable: v})" :size="'sm'"><i class="fa fa-copy" aria-hidden="true" title="Duplicate"></i></base-button>
                                                    <base-button type="link" class="btn-radius" @click="handleUnselectDeliverable({summary: details, deliverable: v})" :size="'sm'" title="Remove"><i class="fa fa-times" aria-hidden="true"></i></base-button>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td v-if="deliverableHasLength(v.type.toLowerCase())"></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="float-right mt-4">
                                <base-button type="success" class="btn-radius" @click="handleEditDeliverable(v)" :size="'sm'">Edit</base-button>
                                <base-button type="danger" class="btn-radius" @click="handleRemoveDeliverable(v)" :size="'sm'">Remove</base-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!hasOrders" class="text-center mb-4">
                    <small>No orders found</small>
                </div>
                <div v-else class="mt-2 mx-2 float-right form-control-label">
                    Total deliverables: {{ deliverablesSelectedCount }}
                </div>
            </div>
            <div v-case="'assets'" class="assets" v-if="sections[currentSectionIdx] == 'assets'">
                <request-upload-container :maxFiles="100" 
                    :client_id="projectDetails.client.id"
                    :resource_id="requestID"
                    resource="request"
                    resource_type="file"
                    :batchId="v4"
                    :showButtons="false"></request-upload-container>
            </div>
            <div v-case="'notes'" class="notes" v-if="sections[currentSectionIdx] == 'notes'">
                <base-input
                    class="mt-2"
                    inputClasses="p-2"
                    textarea
                    placeholder="Any other notes or details about this project"
                    v-model="projectDetails.notes"
                ></base-input>
            </div>
        </div>
       <div class="footer">
            <div v-if="sections[currentSectionIdx] == 'deliverableSummary'" class="ml-3 mt-4 float-left form-control-label position-absolute">
                <span class="pr-2">Add deliverable:</span>
                <img class="pr-2" :style="{ 'cursor': 'pointer', 'width': v.toLowerCase() == 'facebook / instagram / threads' ? '5.5vw' : '2vw' }" style="width: 2vw; cursor: pointer" v-for="v of platforms" v-bind:key="v" v-bind:src="platformLogo(v).src" v-bind:title="platformLogo(v).title" @click="handleAddDeliverable(v)"/>
            </div>
            <div class="float-right p-3">
                <template v-if="progressLoading">
                    <loader :loading="true"></loader>
                </template>
                <template v-else>
                    <base-button type="link" class="ml-auto btn-radius" @click="closeDialog">{{ closeDialogCTA  }}</base-button>
                    <base-button type="primary" v-if="hasBackNavigation" class="ml-auto btn-radius" @click="handleBackNavigation">Back</base-button>
                    <base-button v-if="projectID && currentSectionIdx == 0" type="danger" class="ml-auto btn-radius" @click="handleDeleteOrder">Delete</base-button>
                    <base-button type="success" class="btn-radius" @click.prevent="handleNextAction">{{ CTA }}</base-button>
                </template>
            </div>
       </div>
    </div>
</template>
<script>
    import { mapState, mapGetters  } from "vuex";
    import { cloneDeep, isEmpty } from "lodash"
    import { v4 } from 'uuid';
    import Multiselect from 'vue-multiselect';
    import RequestCollaboratorSectionV3 from './RequestCollaboratorSectionV3.vue'
    import RequestUploadContainer from './RequestUploadContainer.vue'
    import VSwitch from 'v-switch-case'
    import Vue from 'vue'
    import { BTabs, BTab } from 'bootstrap-vue'
    
    Vue.use(VSwitch)
    Vue.component('b-tabs', BTabs)
    Vue.component('b-tab', BTab)

    const PLACEMENTS = {
        'Facebook / Instagram / Threads': [
            {id: 'fb-ig-threads-ffa-v', platformID: 'facebook', mediaType: 'Video', label: 'Facebook Feed Ad', dimensions: ['1:1', '4:5'], type: 'paid'}, 
            {id: 'fb-ig-threads-fisva-v', platformID: 'facebook', mediaType: 'Video', label: 'Facebook In-stream Ad', dimensions: ['1:1', '16:9'], type: 'paid'}, 
            {id: 'fb-ig-threads-fsa-v', platformID: 'facebook', mediaType: 'Video', label: 'Facebook Stories Ad', dimensions: ['9:16'], type: 'paid'}, 
            {id: 'fb-ig-threads-fvfa-v', platformID: 'facebook', mediaType: 'Video', label: 'Facebook Feeds Ad', dimensions: ['1:1', '4:5'], type: 'paid'}, 
            {id: 'fb-ig-threads-fra-v', platformID: 'facebook', mediaType: 'Video', label: 'Facebook Reels Ad', dimensions: ['9:16'], type: 'paid'},
            {id: 'fb-ig-threads-fieva-v', platformID: 'facebook', mediaType: 'Video', label: 'Facebook Instant Experience Ad', dimensions: ['1:1', '1.91:1'], type: 'paid'},

            {id: 'fb-ig-threads-fsvpl-v', platformID: 'facebook', mediaType: 'Video', label: 'Facebook Shared Post (landscape)', dimensions: ['2:3', '9:16', '16:9'], type: 'organic'},
            {id: 'fb-ig-threads-fsvpp-v', platformID: 'facebook', mediaType: 'Video', label: 'Facebook Shared Post (portrait)', dimensions: ['2:3', '9:16', '16:9'], type: 'organic'},
            {id: 'fb-ig-threads-fr-v', platformID: 'facebook', mediaType: 'Video', label: 'Facebook Reels', dimensions: ['9:16'], type: 'organic'},
            {id: 'fb-ig-threads-fsv-v', platformID: 'facebook', mediaType: 'Video', label: 'Facebook Stories', dimensions: ['1:91', '9:16'], type: 'organic'},

            {id: 'fb-ig-threads-ffa-g', platformID: 'facebook', mediaType: 'GIF', label: 'Facebook Feed Ad', dimensions: ['1:1', '4:5'], type: 'paid'}, 
            {id: 'fb-ig-threads-fisva-g', platformID: 'facebook', mediaType: 'GIF', label: 'Facebook In-stream Ad', dimensions: ['1:1', '16:9'], type: 'paid'}, 
            {id: 'fb-ig-threads-fsa-g', platformID: 'facebook', mediaType: 'GIF', label: 'Facebook Stories Ad', dimensions: ['9:16'], type: 'paid'}, 
            {id: 'fb-ig-threads-fvfa-g', platformID: 'facebook', mediaType: 'GIF', label: 'Facebook Feeds Ad', dimensions: ['1:1', '4:5'], type: 'paid'}, 
            
            {id: 'fb-ig-threads-ffa-s', platformID: 'facebook', mediaType: 'Static', label: 'Facebook Feed Ad', dimensions: ['1:1', '1.91:1'], type: 'paid'}, 
            {id: 'fb-ig-threads-frca-s', platformID: 'facebook', mediaType: 'Static', label: 'Facebook Right Column Ad', dimensions: ['1:1'], type: 'paid'}, 
            {id: 'fb-ig-threads-fsa-s', platformID: 'facebook', mediaType: 'Static', label: 'Facebook Stories Ad', dimensions: ['9:16'], type: 'paid'}, 
            {id: 'fb-ig-threads-fsra-s', platformID: 'facebook', mediaType: 'Static', label: 'Facebook Search Results Ad', dimensions: ['1:1', '1.91:1'], type: 'paid'}, 
            {id: 'fb-ig-threads-fca-s', platformID: 'facebook', mediaType: 'Static', label: 'Facebook Carousel Ad', dimensions: ['1:1'], type: 'paid'}, 
            
            {id: 'fb-ig-threads-isa-v', platformID: 'instagram', mediaType: 'Video', label: 'Instagram Stories Ad', dimensions: ['9:16'], type: 'paid'}, 
            {id: 'fb-ig-threads-ifa-v', platformID: 'instagram', mediaType: 'Video', label: 'Instagram Feed Ad', dimensions: ['4:5'], type: 'paid'}, 
            {id: 'fb-ig-threads-iea-v', platformID: 'instagram', mediaType: 'Video', label: 'Instagram Explore Ad', dimensions: ['4:5'], type: 'paid'},

            {id: 'fb-ig-threads-iifv-v', platformID: 'instagram', mediaType: 'Video', label: 'Instagram In-Feed', dimensions: ['1:1', '4:5', '9:16', '16:9'], type: 'organic'},
            {id: 'fb-ig-threads-isv-v', platformID: 'instagram', mediaType: 'Video', label: 'Instagram Stories', dimensions: ['9:16'], type: 'organic'},
            {id: 'fb-ig-threads-irv-v', platformID: 'instagram', mediaType: 'Video', label: 'Instagram Reels', dimensions: ['9:16'], type: 'organic'},

            {id: 'fb-ig-threads-isa-g', platformID: 'instagram', mediaType: 'GIF', label: 'Instagram Stories Ad', dimensions: ['9:16'], type: 'paid'}, 
            {id: 'fb-ig-threads-ifa-g', platformID: 'instagram', mediaType: 'GIF', label: 'Instagram Feed Ad', dimensions: ['4:5'], type: 'paid'}, 
            {id: 'fb-ig-threads-iea-g', platformID: 'instagram', mediaType: 'GIF', label: 'Instagram Explore Ad', dimensions: ['4:5'], type: 'paid'}, 
            
            {id: 'fb-ig-threads-isv-o', platformID: 'instagram', mediaType: 'GIF', label: 'Instagram Stories', dimensions: ['9:16'], type: 'organic'},

            {id: 'fb-ig-threads-isa-s', platformID: 'instagram', mediaType: 'Static', label: 'Instagram Stories Ad', dimensions: ['9:16'], type: 'paid'}, 
            {id: 'fb-ig-threads-ifa-s', platformID: 'instagram', mediaType: 'Static', label: 'Instagram Feed Ad', dimensions: ['1:1'], type: 'paid'}, 
            {id: 'fb-ig-threads-iea-s', platformID: 'instagram', mediaType: 'Static', label: 'Instagram Explore Ad', dimensions: ['9:16'], type: 'paid'},

            {id: 'fb-ig-threads-o-v', platformID: 'threads', mediaType: 'Video', label: 'Threads In-feed', dimensions: ['3:4'], type: 'organic'},
            {id: 'fb-ig-threads-o-s', platformID: 'threads', mediaType: 'Static', label: 'Threads In-feed', dimensions: ['3:4'], type: 'organic'}
        ],
        'TikTok': [
            {id: 'tiktok-ifa-v', mediaType: 'Video', label: 'In-feed Ad', dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},

            {id: 'tiktok-fv-v', mediaType: 'Video', label: 'Feed', dimensions: ['1:1', '9:16'], type: 'organic'},
        ],
        'YouTube': [
            {id: 'yt-sisa-v', mediaType: 'Video', label: 'Skippable In-stream Ads (previously TrueView in-stream Ads)', dimensions: ['4:3', '16:9'], type: 'paid'}, 
            {id: 'yt-nsisva-v', mediaType: 'Video', label: 'Non-skippable In-stream Ads', dimensions: ['4:3', '16:9'], type: 'paid'}, 
            {id: 'yt-ba-v', mediaType: 'Video', label: 'Bumper Ads', dimensions: ['4:3', '16:9'], type: 'paid'},

            {id: 'yt-sv-v', mediaType: 'Video', label: 'Standard', dimensions: ['4:3', '16:9'], type: 'organic'},
            {id: 'yt-ys-v', mediaType: 'Video', label: 'YouTube Shorts', dimensions: ['9:16'], type: 'organic'},
        ],
        'Snapchat': [
            {id: 'sc-sva-v', mediaType: 'Video', label: 'Single Ad', dimensions: ['9:16'], type: 'paid'},
            {id: 'sc-fsca-v', mediaType: 'Video', label: 'Full screen Canvas Ad', dimensions: ['9:16'], type: 'paid'},
            {id: 'sc-lfva-v', mediaType: 'Video', label: 'Long-form Ad', dimensions: ['9:16', '16:9'], type: 'paid'},

            {id: 'sc-fsca-s', mediaType: 'Static', label: 'Full screen Canvas Ad', dimensions: ['9:16'], type: 'paid'},

            {id: 'sc-fv-s', mediaType: 'Static', label: 'Feed', dimensions: ['9:16'], type: 'organic'}
        ],
        'Pinterest': [
            {id: 'p-swva-v', mediaType: 'Video', label: 'Standard-width Ad', dimensions: ['1:1', '2:3', '9:16'], type: 'paid'}, 
            {id: 'p-maa-v', mediaType: 'Video', label: 'Max. width Ad', dimensions: ['1:1', '16:9'], type: 'paid'},
            {id: 'p-ia-v', mediaType: 'Video', label: 'Idea Ad', dimensions: ['9:16'], type: 'paid'},

            {id: 'p-sta-s', mediaType: 'Static', label: 'Static Ad', dimensions: ['2:3'], type: 'paid'},
            {id: 'p-sha-s', mediaType: 'Static', label: 'Shopping Ad', dimensions: ['2:3'], type: 'paid'},
            {id: 'p-caa-s', mediaType: 'Static', label: 'Carousel Ad', dimensions: ['1:1', '2:3'], type: 'paid'},
            {id: 'p-coa-s', mediaType: 'Static', label: 'Collection Ad', dimensions: ['1:1', '2:3'], type: 'paid'},
            {id: 'p-ia-s', mediaType: 'Static', label: 'Idea Ad', dimensions: ['1:1', '2:3'], type: 'paid'},

            {id: 'p-sv-s', mediaType: 'Static', label: 'Shared', dimensions: ['1:1', '2:3', '4:5', '9:16'], type: 'organic'},
        ],
        'Twitter': [
            {id: 't-val-v', mediaType: 'Video', label: 'Ad (landscape)', dimensions: ['1:1', '16:9'], type: 'paid'}, 
            {id: 't-vap-v', mediaType: 'Video', label: 'Ad (portrait)', dimensions: ['1:1', '16:9'], type: 'paid'}, 
            {id: 't-vas-v', mediaType: 'Video', label: 'Ad (square)', dimensions: ['1:1', '16:9'], type: 'paid'},

            {id: 't-sv-v', mediaType: 'Video', label: 'Shared', dimensions: ['1:1', '16:9'], type: 'organic'},
        ],
        'LinkedIn': [
            {id: 'li-va-v', mediaType: 'Video', label: 'Ad', dimensions: ['4:3', '16:9'], type: 'paid'},

            {id: 'li-nv-v', mediaType: 'Video', label: 'Native', dimensions: ['1:2.4', '2.4:1'], type: 'organic'},
        ],
        'Google': [
            {id: 'g-v', mediaType: 'Video', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
            {id: 'g-g', mediaType: 'GIF', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
            {id: 'g-s', mediaType: 'Static', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
        ],
        'Amazon': [
            {id: 'a-v', mediaType: 'Video', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
            {id: 'a-g', mediaType: 'GIF', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
            {id: 'a-s', mediaType: 'Static', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
        ],
        'CTV': [
            {id: 'ctv-v', mediaType: 'Video', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
            {id: 'ctv-g', mediaType: 'GIF', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
            {id: 'ctv-s', mediaType: 'Static', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
        ],
        'Others': [
            {id: 'others-v', mediaType: 'Video', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
            {id: 'others-g', mediaType: 'GIF', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},
            {id: 'others-s', mediaType: 'Static', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'paid'},

            {id: 'others-o-v', mediaType: 'Video', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'organic'},
            {id: 'others-o-g', mediaType: 'GIF', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'organic'},
            {id: 'others-o-s', mediaType: 'Static', label: null, dimensions: ['1:1', '9:16', '16:9'], type: 'organic'},
        ]
    }

    const initDeliverablesFields = (deliverable) => ({
        id: deliverable?.id || v4(),
        key: v4(),
        platform: {
            value: deliverable?.platform || null,
            required: true,
            error: '',
            disabled: deliverable ? true : false,
        },
        type: {
            value: deliverable?.type || null,
            required: true,
            error: '',
            disabled: deliverable ? true : false,
        },
        placement: {
            value: deliverable?.placement || null,
            required: true,
            error: '',
            disabled: deliverable ? true : false,
        },
        length: {
            value: deliverable?.length || null,
            required: deliverable ? deliverable.type.toLowerCase() != 'static' : true,
            error: '',
        },
        dimension: {
            value: deliverable?.dimensions || null,
            required: true,
            error: '',
        },
        customDimension: {
            value: deliverable?.customDimensions || [],
            length:  deliverable?.customDimensions.length || '',
            required: false,
            error: '',
            valuesRequired: false,
            valuesValidator: {
                'required': (v) =>  !v ? 'This field is required' : null
            }
        },
        CTA: {
            value: deliverable?.CTA || null,
            required: false,
            error: '',
        },
        keyMessaging: {
            value: deliverable?.keyMessaging || null,
            required: true,
            error: '',
        },
        notes: {
            value: deliverable?.notes || null,
            required: false,
            error: ''
        }   
    })
    
    export default {
        name: "request-section-new",
        components: {
            Multiselect,
            RequestCollaboratorSectionV3,
            RequestUploadContainer,
        },
        props: {
            componentKey: {
                type: String,
                default: ''
            },
            errorMsgs: {
                type: Object,
                default: () => ({ required: 'This field is required'} )
            },
            sections: {
                type: Array,
                default: () => ['projectDetails', 'deliverables', 'deliverableSummary', 'assets', 'notes']
            },
            customDimensions: {
                type: Array,
                default: () => Array.from({length: Number(process.env.VUE_APP_DELIVERABLE_MAX_CUSTOM_DIMENSION)}, (_, i) => i + 1)
            },
            addDeliverableOnly: {
                type: Boolean,
                default: false
            },
            projectID: {
                type: String,
                default: ''
            }
        },  
        data: () => ({
            currentSectionIdx: 0,
            projectDetails: {
                title: {
                    value: '',
                    required: true,
                    error: '',
                },
                description: {
                    value: '',
                    required: false,
                },
                client: {
                    id: '',
                    collaborators: []
                },
                type: 'paid',
                notes: ''            
            },
            deliverables: initDeliverablesFields(),
            savedDrafDeliverableIDs: []
        }),
        methods: {
            validate(field) {
                // custom error validator for each custom dimension field.. for now
                if (field.required && field.valuesValidator) {
                    if (!field.value || (field.valuesRequired && (!field.value.length || field.value.length != field.length))) {
                        field.error = field.valuesValidator['required'](null)
                        return
                    }

                    if (!field.valuesRequired) {
                        return
                    }
                    
                    const unFilledField = field.value.find(v => !v)
                    if (unFilledField) {
                        field.error = field.valuesValidator['required'](null)
                        return
                    }

                    // custom dimension values has `empty` value in array and occupies length.. checking each element to prevent submission
                    for (let i = 0; i < field.value.length; i++) {
                        if (field.required) {
                            field.error = field.valuesValidator['required'](field.value[i])
                            if (field.error) {
                                break
                            }
                        } 
                    }

                    return
                }

                field.error = ''
                const value = (field.value && typeof field.value == 'object') ?  Object.keys(field.value) : field.value                
                if ((!value || !value.length) && field.required) {
                    field.error = this.errorMsgs.required
                }
            },   
            sort(data) {
                if (!data || data.length == 0) {
                    return []
                }

                // supports both number (length) and string (dimension)
                const dataMap = {}
                data.forEach((v) => {
                    let key = v
                    if (isNaN(v)) {
                        const components = v.split(':')
                        key = Number(components[0])
                    }
                    dataMap[key] = v
                })

                return Object.keys(dataMap).sort((a,b) =>  a-b).map(v => dataMap[v])
            },  
            handleSelectClient(clientID) {
                this.projectDetails.client.id = clientID
            },
            async closeDialog() {
                if (this.addDeliverableOnly) {
                    if (this.hasOrders && !confirm(`All changes will be discarded. Are you sure you want to close?`)) {
                        return
                    }

                    this.$emit('closeDialog')
                    return
                }

                if (this.currentSectionIdx > 0) {
                    if (!confirm(`Are you sure you want to close and save as draft?`)) {
                        return
                    }

                    const response = await this.saveOrder('Draft')
                    if (response && response.error) {
                        return
                    }

                    await this.handleSaveDeliverables(true)
                }
                this.$store.commit('REQUEST/clearState')
                this.$emit('closeDialog')
            },
            handleBackNavigation() {
                this.currentSectionIdx--
            },
            formatKeyMessaging(keyMessaging, forTooltip) {
                if (!keyMessaging.includes('--and--')) {
                    return keyMessaging
                }
                
                // fb carousel ad
                return keyMessaging.split('--and--').map((v, i) => `Key Messaging for Image ${++i}: ${v}`).join(forTooltip ? '\n\n' : '<br><br>')
            },
            deliverableHasLength(type) {
                return ['video', 'gif'].includes(type.toLowerCase())
            },
            handlePlatformChange() {
                this.deliverables.type.value = null
                this.handleDeliverableTypeChange()
            },
            handlePlacementChange() {
                this.deliverables.length.value = null
                this.deliverables.dimension.value = null
                this.deliverables.customDimension.value = []
                this.deliverables.customDimension.length = ''

                this.deliverables.dimension.required = true
                this.deliverables.customDimension.required = false

                this.deliverables.keyMessaging.required = true

                if (this.deliverables.placement.value.id == 'custom-dimensions') {
                    this.deliverables.dimension.required = false
                    this.deliverables.customDimension.required = true
                    this.deliverables.customDimension.valuesRequired = true
                }

                // special handling for fb carousel ad
                if (this.deliverables.placement.value?.label?.toLowerCase() == 'facebook carousel ad') {
                    this.deliverables.customDimension.required = true
                    this.deliverables.keyMessaging.required = false
                    this.deliverables.keyMessaging.error = ''
                }

                if (this.deliverables.placement?.value?.dimensions?.length == 1) {
                    // populate dimension if only 1 placement dimension 
                    this.deliverables.dimension.value = this.deliverables.placement.value.dimensions
                }
            },
            handleDeliverableTypeChange() {
                this.deliverables.placement.value = null
                this.deliverables.length.value = null
                this.deliverables.dimension.value = null
                this.deliverables.customDimension.value = []
                this.deliverables.customDimension.length = ''
                this.deliverables.customDimension.required = false

                this.deliverables.dimension.required = true
                this.deliverables.length.required = true

                if (this.deliverables.type.value && this.deliverables.type.value.toLowerCase() == 'static') {
                    this.deliverables.length.required = false
                }

                this.deliverables.placement.required = this.platformPlacements.length ? true : false
                this.deliverables.keyMessaging.required = true
            },
            handleAddCustomLength(length) {
                const lengthNum = Number(length)

                if (isNaN(lengthNum)) {
                    this.$notify({
                        icon: 'fa fa-times',
                        type: 'danger',
                        title: 'Error',
                        message: `Only numeric values allowed`,
                    })
                    return
                }

                this.deliverables.length.value = this.deliverables.length.value ? [...this.deliverables.length.value, lengthNum] : [lengthNum]
            },
            isDeliverableSelected(id, deliverable) {
                const selected = deliverable.summary.filter(v => v.selected).find(v => v.selected.id == id)
                return selected ? true : false
            },
            handleDeliverableSelected(selected, params) {
                let { summary, deliverable, dimension, id } = params
                if (deliverable.type.toLowerCase() != 'static') {
                    const selectedSummary = {
                        id,
                        platform: deliverable.platform,
                        placement: deliverable.placement.label,
                        type: deliverable.type,
                        length: summary.row,
                        dimension,
                        CTA: deliverable.CTA,
                        keyMessaging: deliverable.keyMessaging,
                        notes: deliverable.notes,
                    }
                    if (selected) {
                        const summaryByRow = deliverable.summary.find(v => v.row == summary.row)
                        if (summaryByRow.selected) {
                            const newSummaryByRow = { ...summaryByRow }
                            newSummaryByRow.id = id
                            newSummaryByRow.skipDimension = true
                            const summariesByRow = deliverable.summary.filter(v => v.row == summary.row)
                            const summaryByRowLastIndx = deliverable.summary.indexOf(summariesByRow[summariesByRow.length-1])
                            deliverable.summary.splice(summaryByRowLastIndx+1, 0, newSummaryByRow);
                            summary = newSummaryByRow
                        }
                        summary.selected = selectedSummary
                        summary.isDuplicate = false
                        // this.deliverablesSelectedCount++
                    }  else {
                        const selectedSummaries = deliverable.summary.filter(v => v.selected && v.selected.id === id)
                        selectedSummaries.forEach((selectedSummary) => {
                            selectedSummary.selected = null
                            if (selectedSummary.skipDimension) {
                                const summariesByRowIdx = deliverable.summary.findIndex(v => v.id == selectedSummary.id)
                                if (summariesByRowIdx > -1) {
                                    deliverable.summary.splice(summariesByRowIdx, 1);
                                } else {
                                    const copiedSummary = deliverable.summary.find(v => v.referenceSummaryID == selectedSummary.id)
                                    if (copiedSummary) {
                                        copiedSummary.selected = null
                                        const summaryRows = deliverable.summary.filter(v => v.row == copiedSummary.row)
                                        if (summaryRows.length > 1) {
                                            copiedSummary.selected = summaryRows[1].selected
                                            copiedSummary.referenceSummaryID = summaryRows[1].id
                                            copiedSummary.isDuplicate = false
                                            const summariesByRowIdx = deliverable.summary.findIndex(v => v.id == summaryRows[1].id)
                                            deliverable.summary.splice(summariesByRowIdx, 1);
                                        }
                                    }
                                }
                                // this.deliverablesSelectedCount--

                            } else {
                                const summaryRows = deliverable.summary.filter(v => v.row == summary.row)
                                if (summaryRows.length > 1) {
                                    summary.selected = summaryRows[1].selected
                                    summary.referenceSummaryID = summaryRows[1].id
                                    summary.isDuplicate = false
                                    const summariesByRowIdx = deliverable.summary.findIndex(v => v.id == summaryRows[1].id)
                                    deliverable.summary.splice(summariesByRowIdx, 1);
                                }
                                // this.deliverablesSelectedCount--    
                            }
                        })
                    }
                } else {
                    // handle static deliverable separately 
                    const selectedSummary = {
                        id,
                        platform: deliverable.platform,
                        placement: deliverable.placement.label,
                        type: deliverable.type,
                        length: null,
                        dimension: summary.row,
                        CTA: deliverable.CTA,
                        keyMessaging: deliverable.keyMessaging,
                        notes: deliverable.notes,
                    }
                    summary.isDuplicate = false
                    summary.selected = selected ? selectedSummary : null
                    if (!selected) {
                        const selectedSummaries = deliverable.summary.filter(v => v.selected && v.selected.id === id)
                        selectedSummaries.forEach((selectedSummary) => {
                            selectedSummary.selected = null
                            if (selectedSummary.skipDimension) {
                                const summariesByRowIdx = deliverable.summary.findIndex(v => v.id == selectedSummary.id)
                                deliverable.summary.splice(summariesByRowIdx, 1);
                                // this.deliverablesSelectedCount--
                            }
                        })
                    }

                    // this.deliverablesSelectedCount = selected ? ++this.deliverablesSelectedCount : --this.deliverablesSelectedCount
                }
                this.$store.commit('REQUEST/setRequestDeliverableOrder', deliverable)
            },
            handleDuplicateDeliverable(params) {
                const { summary, deliverable } = params
                const selectedSummary = deliverable.summary.find(v => v.id == summary.id)
                const duplicateSummary = cloneDeep(selectedSummary)
                duplicateSummary.id = `${selectedSummary.id}-duplicate-${v4()}` 
                duplicateSummary.isDuplicate = true
                duplicateSummary.skipDimension = true

                const selectedSummaryIndex = deliverable.summary.findIndex(v => v.id === selectedSummary.id)
                deliverable.summary.splice(selectedSummaryIndex+1, 0, duplicateSummary);
                // this.deliverablesSelectedCount++

                this.$store.commit('REQUEST/setRequestDeliverableOrder', deliverable)
            },
            handleUnselectDeliverable(params) {
                const { summary, deliverable } = params
                if (summary.skipDimension) {
                    const summariesByRowIdx = deliverable.summary.findIndex(v => v.id == summary.id)
                    deliverable.summary.splice(summariesByRowIdx, 1);
                } else {
                    summary.selected = null
                    const summaryRows = deliverable.summary.filter(v => v.row == summary.row)
                    if (summaryRows.length > 1) {
                        summary.selected = summaryRows[1].selected
                        summary.referenceSummaryID = summaryRows[1].id
                        summary.isDuplicate = summaryRows[1].isDuplicate
                        const summariesByRowIdx = deliverable.summary.findIndex(v => v.id == summaryRows[1].id)
                        deliverable.summary.splice(summariesByRowIdx, 1);
                    }
                }
                // this.deliverablesSelectedCount-- 
                this.$store.commit('REQUEST/setRequestDeliverableOrder', deliverable)   

            },
            handleSaveEditDuplicate(params) {
                const { summary, deliverable } = params
                const summaryByRowIdx = deliverable.summary.find(v => v.id == summary.id)
                summaryByRowIdx.isDuplicate = false
                this.$store.commit('REQUEST/setRequestDeliverableOrder', deliverable)   
            },
            handleEditSelectedDetails(params) {
                const { deliverable, summary, field, index } = params
                let { value } = params
                const deliverableSumary = deliverable.summary.find(v => v.id == summary.id)
                if (field == 'keyMessaging') {
                    const messages = summary.selected.keyMessaging.split('--and--')
                    messages[index] = value || messages[index]
                    value = messages.join('--and--')
                }
                deliverableSumary.selected[field] = value

                this.$store.commit('REQUEST/setRequestDeliverableOrder', deliverable)
            },
            handleAddDeliverable(platform) {
                this.deliverables = initDeliverablesFields()
                if (platform) {
                    this.deliverables.platform.value = platform
                }
                this.currentSectionIdx--
            },
            handleEditDeliverable(deliverable) {
                // const selectedCount = this.deliverableSummary[deliverable.type][deliverable.id].summary.filter(v => v.selected).length
                // this.deliverablesSelectedCount -= selectedCount
                this.deliverables = initDeliverablesFields(deliverable)
                if (this.deliverables.placement.value.id == 'custom-dimensions') {
                    this.deliverables.dimension.required = false
                    this.deliverables.customDimension.required = true
                    this.deliverables.customDimension.valuesRequired = true
                }

                if (this.deliverables.placement.value?.label?.toLowerCase() == 'facebook carousel ad') {
                    this.deliverables.customDimension.required = true
                    this.deliverables.keyMessaging.required = false
                    this.deliverables.keyMessaging.value = ''
                }

                this.currentSectionIdx--
            },
            handleRemoveDeliverable(deliverable) {
                const { id, type } = deliverable
                // const selectedCount = this.deliverableSummary[type][id].summary.filter(v => v.selected).length
                // this.deliverablesSelectedCount -= selectedCount

                delete this.deliverableSummary[type][id]

                if (!Object.keys(this.deliverableSummary[type]).length) {
                    delete this.deliverableSummary[type]
                }

                this.$store.commit('REQUEST/setRequestDeliverableOrder', {deliverableOrders: this.deliverableSummary})

                if (!Object.keys(this.deliverableSummary).length) {
                    this.handleAddDeliverable()
                }
            },
            handleOrderMenuClick() {
                this.deliverables = initDeliverablesFields()
            },
            async handleSaveDeliverables(isDraft) {
                if (!isDraft && this.deliverablesSelectedCount == 0) {
                    this.$notify({
                        icon: 'fa fa-times',
                        type: 'danger',
                        title: 'Error',
                        message: `No orders selected`,
                    })
                    return { error: true }
                }

                const requests = []
                Object.values(this.deliverableSummary).forEach(type => {
                    Object.values(type).forEach(v => {
                        if (!isDraft) {
                            v.summary.filter(s => s.selected).forEach(x => {
                                const { length, dimension, platform, placement, keyMessaging, CTA, notes, type  } = x.selected
                                requests.push({
                                    request_id: this.requestID,
                                    status: 'Submitted',
                                    properties:  {
                                        platform,
                                        placement,
                                        type,
                                        length: length || null,
                                        dimension: dimension,
                                        keyMessaging,
                                        CTA,
                                        notes
                                    }
                                })
                            })
                        } else {
                            // Video and GIF
                            if (v.length) {
                                this.sort(v.length).forEach(length => {
                                    this.sort(v.dimensions).concat(v.customDimensions.filter(c => c)).forEach(dimension => {
                                        requests.push({
                                            request_id: this.requestID,
                                            status: 'Draft',
                                            properties:  {
                                                platform: v.platform,
                                                placement: v.placement.label,
                                                type: v.type,
                                                length,
                                                dimension,
                                                keyMessaging: v.keyMessaging,
                                                CTA: v.CTA,
                                                notes: v.notes,
                                            }
                                        })
                                    })
                                })
                            } else {
                                // Static
                                let customDimensions = v.customDimensions
                                // handle special fb carousel ad placement
                                if (v.placement.label.toLowerCase() == 'facebook carousel ad') {
                                    customDimensions = []
                                }

                                this.sort(v.dimensions).concat(customDimensions.filter(c => c)).forEach(dimension => {
                                        requests.push({
                                            request_id: this.requestID,
                                            status: 'Draft',
                                            properties:  {
                                                platform: v.platform,
                                                placement: v.placement.label,
                                                type: v.type,
                                                length: null,
                                                dimension,
                                                keyMessaging: v.keyMessaging,
                                                CTA: v.CTA,
                                                notes: v.notes,
                                            }
                                        })
                                })
                            }
                        }
                    })
                })

                await this.$store.dispatch("REQUEST/createDeliverable", requests)
                // delete previously created DRAFT deliverables
                if (this.savedDrafDeliverableIDs && this.savedDrafDeliverableIDs.length) {
                    await this.$store.dispatch("REQUEST/deleteBulkDeliverables", {
                        id: 'bulk',
                        ids: this.savedDrafDeliverableIDs
                    })
                }
                return
            },
            platformLogo(platform, placement) {
                const platformLogoMap = {
                    'facebook / instagram / threads': { title: 'Facebook / Instagram / Threads', src: '/img/platforms/fb-ig-threads.png', width: '15%' },
                    'facebook': { title: 'Facebook', src: '/img/platforms/fb.png' },
                    'instagram': { title: 'Instagram', src: '/img/platforms/ig.png' },
                    'threads': { title: 'Threads', src: '/img/platforms/threads.png'},
                    'youtube': { title: 'YouTube', src: '/img/platforms/youtube.png' },
                    'pinterest': { title: 'Pinterest', src: '/img/platforms/pinterest.png' },
                    'tiktok': { title: 'TikTok', src: '/img/platforms/tiktok.png' },
                    'twitter': { title: 'Twitter', src: '/img/platforms/twitter.png' },
                    'linkedin': { title: 'LinkedIn', src: '/img/platforms/linkedin.png' },
                    'snapchat': { title: 'Snapchat', src: '/img/platforms/snapchat.png' },
                    'google': { title: 'Google', src: '/img/platforms/google.png' },
                    'amazon': { title: 'Amazon', src: '/img/platforms/amazon.png' },
                    'ctv': { title: 'CTV', src: '/img/platforms/ctv.png'},
                    'others': { title: 'Custom Dimensions', src: '/img/platforms/others.png'},
                }

                if (placement && (platform.toLowerCase().includes('facebook / instagram / threads')
                    || platform.toLowerCase().includes('facebook / instagram'))) {
                        const placementPlatform = placement.split(' ')
                        if (placementPlatform.length) {
                            return platformLogoMap[placementPlatform[0].toLowerCase()] || platformLogoMap[platform.toLowerCase()]
                        }
                }

                return platformLogoMap[platform.toLowerCase()]
            },
            async handleNextAction() {
                // validations
                let validSection = true
                const currentSection = this.sections[this.currentSectionIdx]
                if (currentSection != 'deliverableSummary' && this[currentSection]) {
                    Object.keys(this[currentSection]).forEach(v => {
                        if (typeof this[currentSection][v] != 'object') {
                            return
                        }
                        this.validate(this[currentSection][v])
                        if (this[currentSection][v].error) {
                            validSection = false
                        }
                    })
                }

                if (!validSection) {
                    return
                }
                
                switch(this.sections[this.currentSectionIdx]) {
                    case 'projectDetails': {
                        try {
                            const response = await this.saveOrder('Draft')
                            if (response && response.error) {
                                return
                            }

                            // adding setTimeout before moving to and enabling next tab;
                            // for some reasons, b-tab won't set to active immediately if coming from disabled state
                            setTimeout(() => {
                                this.currentSectionIdx++
                            }, 100)
                        } catch (err) {
                            console.log('err', err)
                        }
                        return
                    }
                    case 'deliverables':  {
                        if (this.deliverables.placement?.value?.label.toLowerCase() == 'facebook carousel ad' &&
                            this.deliverables.customDimension?.value?.length != this.deliverables.customDimension?.length) {
                            this.deliverables.customDimension.value = [...Array(this.deliverables.customDimension.length).keys()].map((_, i) => this.deliverables.customDimension.value[i] || '')
                        }

                        const deliverable = {
                            id: this.deliverables.id,
                            key: this.deliverables.key,
                            platform: this.deliverables.platform.value,
                            placement: this.deliverables.placement.value || { label: this.deliverables.platform.value },
                            type: this.deliverables.type.value,
                            length: this.deliverables.length.value,
                            dimensions: this.deliverables.dimension.value,
                            customDimensions: this.deliverables.customDimension.value,
                            keyMessaging: this.deliverables.placement.value?.label.toLowerCase() == 'facebook carousel ad' ?
                                this.deliverables.customDimension.value.join('--and--') 
                                : this.deliverables.keyMessaging.value.trim(),
                            CTA: this.deliverables.CTA.value?.trim() || null,
                            notes: this.deliverables.notes.value?.trim(),
                            summary: this.sort(this.deliverables.length.value).map(v => ({
                                id: v4(),
                                row: v,
                                rowType: 'length',
                                selected: null
                            }))
                        }

                        if ('static' == deliverable.type.toLowerCase()) {
                            const customDimensions = this.deliverables.placement.value?.label.toLowerCase() == 'facebook carousel ad' ? [] : this.deliverables.customDimension.value
                            deliverable.summary = this.sort(this.deliverables.dimension.value).concat(customDimensions || []).map(v => ({
                                id: v4(),
                                row: v,
                                rowType: 'dimension',
                                selected: null
                            }))
                        }

                        // // auto select if one deliverable 
                        // const dimensions = this.sort(deliverable.dimensions || []).concat(
                        //     this.deliverables.placement.value?.label.toLowerCase() == 'facebook carousel ad' ? [] : (deliverable.customDimensions || []))
                        // if (deliverable.summary.length == 1 && dimensions.length == 1) {
                        //     const details = deliverable.summary[0]
                        //     const dimension = details.rowType == 'length' ? dimensions[0] : null 
                        //     this.handleDeliverableSelected(true, {
                        //         summary: details,
                        //         deliverable,
                        //         dimension,
                        //         id: details.rowType == 'length' ? `${details.id}x${details.row}x${dimension}` : `${details.id}x${details.row}`
                        //     })
                        // } else {
                        //     this.$store.commit('REQUEST/setRequestDeliverableOrder', deliverable)
                        // }
                        this.$store.commit('REQUEST/setRequestDeliverableOrder', deliverable)
                        this.deliverables = initDeliverablesFields()

                        this.currentSectionIdx++ 
                        break
                    }
                    case 'deliverableSummary': {
                        if (this.deliverablesSelectedCount == 0) {
                            this.$notify({
                                icon: 'fa fa-times',
                                type: 'danger',
                                title: 'Error',
                                message: `No orders selected`,
                            })
                            return 
                        }

                        if (this.addDeliverableOnly) {
                            const response = await this.handleSaveDeliverables()
                            if (response && response.error) {
                                return
                            }

                            this.$emit('closeDialog')
                            return
                        }

                        this.currentSectionIdx++;
                        break
                    }
                    case 'notes': {
                        // submit all data
                        const response = await this.handleSaveDeliverables()
                        if (response && response.error) {
                            return
                        }

                        if (this.$root.$refs.deliverableAssets.filtered_documents?.length) {
                            await this.$root.$refs.deliverableAssets.handleStartUpload()
                            this.$store.commit('DOCUMENT/clearState')
                        }
                        
                        try {
                            await this.saveOrder('Submitted')
                            
                            this.$notify({
                                icon: "ni ni-check-bold",
                                type: "success",
                                title: "Success",
                                message: `Order created`,
                            })

                            this.$store.commit('REQUEST/clearState')
                            this.$emit('closeDialog')

                        } catch (err) {
                            console.log('err', err)
                        }
                        return
                    }
                    default:
                        this.currentSectionIdx++ 
                }
            },
            async saveOrder(status) {
                // save project on project details `next`; status: `DRAFT`
                // validate client and collaborators
                const data = this.requestData || {}
                const { client_id, members } = data
                if (this.isAdmin && (!client_id || !members || !this.projectDetails.title.value)) {
                    this.$notify({
                        icon: 'fa fa-times',
                        type: 'danger',
                        title: 'Error',
                        message: `Order must have a title, client and collaborator(s)`,
                    })
                    return {error: true}
                }

                if (this.requestID) {
                    await this.updateOrder(status)
                    return
                }

                const { notifyCollaborators, stakeholder } = data?.properties

                await this.$store.dispatch("REQUEST/createRequestV3", {
                    name: this.projectDetails.title.value,
                    description: this.projectDetails.description.value,
                    client_id,
                    collaborators: members,
                    properties: {
                        notifyCollaborators,
                        type: this.projectDetails.type,
                        stakeholder: {
                            id: stakeholder.id,
                            first_name: stakeholder.first_name,
                            last_name: stakeholder.last_name,
                            name: stakeholder.name || `${stakeholder.first_name} ${stakeholder.last_name}`,
                            email: stakeholder.email,
                            role: stakeholder.role
                        }
                    }
                });
            },
            async updateOrder(status) {
                const data = this.requestData || {}
                const { client_id, members } = data

                const { notifyCollaborators, stakeholder } = data?.properties

                const requestData = {
                    id: this.requestID,
                    name: this.projectDetails.title.value,
                    description: this.projectDetails.description.value,
                    client_id,
                    collaborators: members,
                    status,
                    properties: {
                        notifyCollaborators,
                        type: this.projectDetails.type,
                        notes: this.projectDetails.notes,
                        stakeholder: {
                            id: stakeholder.id,
                            first_name: stakeholder.first_name,
                            last_name: stakeholder.last_name,
                            name: stakeholder.name || `${stakeholder.first_name} ${stakeholder.last_name}`,
                            email: stakeholder.email,
                            role: stakeholder.role
                        }
                    }
                }

                await this.$store.dispatch("REQUEST/updateRequestV3", requestData);
            },
            async handleDeleteOrder() {
                if(confirm(`Are you sure to delete ${this.projectDetails.title.value}?`)) {
                    await this.$store.dispatch('REQUEST/deleteRequest', this.projectID)
                    this.$notify({
                        timeout: 3000,
                        icon: 'ni ni-check-bold',
                        type: 'success',
                        title: 'Deleted!',
                        message: 'Successfully deleted project'
                    })
                    this.$store.commit('REQUEST/clearState')
                    this.$emit('closeDialog')   
                }
            },
            async getProjectData() {
                const data = await this.$store.dispatch('REQUEST/getRequestData', this.projectID)
                if (!data) {
                    return
                }

                const { name, description, client_id, members, deliverables } = data
                const { type, notes } = data.properties || {}
                this.projectDetails.title.value = name
                this.projectDetails.description.value = description
                this.projectDetails.client.id = client_id || ''
                this.projectDetails.client.collaborators = members || []
                this.projectDetails.type = type
                this.projectDetails.notes = notes

                if (this.addDeliverableOnly || !deliverables || !deliverables.length || this.hasOrders) {
                    return
                }

                const platformPlacement = (platform, placement) => {
                    const platformPlacements = PLACEMENTS[platform]
                    if (!platformPlacements || !platformPlacements.length) {
                        return null
                    }

                    if (placement.toLowerCase() === 'custom dimensions') {
                        return {id: 'custom-dimensions', label: 'Custom Dimensions'}
                    }

                    if (placement.toLowerCase() == platform.toLowerCase()) {
                        return { label: platform }

                    }

                    return platformPlacements?.find(v => v.label == placement)
                }
                
                const platformDimensions = (platform, placement) => {
                    const platformPlacements = PLACEMENTS[platform]
                    if (!platformPlacements || !platformPlacements.length) {
                        return []
                    }

                    const dimensions = platformPlacements?.filter(v => v.label == placement).map(v => ([...v.dimensions])).flat(1)
                    return [...new Set(this.sort(dimensions))]
                }
                
                this.savedDrafDeliverableIDs = deliverables.filter(v => v.status.toUpperCase() == 'DRAFT').map(v => v.id)
                let mediaTypes = deliverables.map(v => v.properties.type)
                mediaTypes = [...new Set(mediaTypes)]
                mediaTypes.forEach(mediaType => {
                    const mediaTypeDeliverables = deliverables.filter(v => v.properties.type === mediaType)
                    let platforms = mediaTypeDeliverables.map(v => v.properties.platform)
                    platforms = [...new Set(platforms)]
                    platforms.forEach(platform => {
                        let platformPlacements = mediaTypeDeliverables.filter(v => v.properties.platform == platform).map(v => v.properties.placement)
                        platformPlacements = [...new Set(platformPlacements)]
                        platformPlacements.forEach(placement => {
                            const platfromPlacementDeliverables = mediaTypeDeliverables.filter(v => v.properties.platform == platform && v.properties.placement == placement)
                            let lengths = platfromPlacementDeliverables.map(v => v.properties.length)
                            lengths = [...new Set(lengths)]
                            let dimensions = platfromPlacementDeliverables.map(v => v.properties.dimension)
                            dimensions = [...new Set(dimensions)]

                            const platformPlacementDimensions = platformDimensions(platform, placement)
                            let customDimensions = dimensions.filter(v => !platformPlacementDimensions.includes(v))
                            customDimensions = [...new Set(customDimensions)]
                            customDimensions.forEach(customDimension => {
                                const index = dimensions.findIndex(v => v === customDimension)
                                dimensions.splice(index, 1);
                            })

                            const deliverable = platfromPlacementDeliverables[0].properties
                            const order = {
                                id: v4(),
                                key: v4(),
                                platform: platform,
                                placement: platformPlacement(platform, placement),
                                type: mediaType,
                                length: lengths,
                                dimensions,
                                customDimensions,
                                keyMessaging: deliverable.keyMessaging?.trim(),
                                CTA: deliverable.CTA?.trim() || null,
                                notes: deliverable.notes?.trim() || null,
                                summary: this.sort(lengths).map(v => ({
                                    id: v4(),
                                    row: v,
                                    rowType: 'length',
                                    selected: null
                                }))
                            }

                            if ('static' == order.type.toLowerCase()) {
                                order.summary = this.sort(dimensions).concat(customDimensions || []).map(v => ({
                                    id: v4(),
                                    row: v,
                                    rowType: 'dimension',
                                    selected: null
                                }))
                            }

                            this.$store.commit('REQUEST/setRequestDeliverableOrder', order)
                        })

                    })
                })
            }
        },
        async created() {
            console.log('deliverable modal created', this.componentKey)
            this.$root.$refs.createProject = this;
            this.$store.commit('REQUEST/setRequestDeliverableOrder', null)
            this.$store.commit('REQUEST/setRequest', null)
            if (this.addDeliverableOnly) {
                this.currentSectionIdx = 1
            }

            // saved projects; continuing DRAFT projects or add deliverable
            if (this.projectID) {
                await this.getProjectData()
            }
        },
        computed: {
            ...mapGetters("AUTH", [
                'isAdmin',
                'isUser'
            ]),
            ...mapState('AUTH', {
                user: state => state.user,
            }),
            ...mapState("REQUEST", {
                requestData: (state) => state.request,
                progressLoading: (state) => state.progressLoading,
                deliverableSummary: (state) =>  cloneDeep(state.deliverableOrders)
            }),
            v4: () => {
                return v4()
            },
            title() {
                const sectionTitleMap = {
                    'projectDetails': 'Order Details',
                    'deliverables': 'Order Deliverables',
                    'deliverableSummary': 'Order summary',
                    'assets': 'Assets',
                    'notes': 'Anything else'
                }
                const currentSection = this.sections[this.currentSectionIdx]
                return sectionTitleMap[currentSection]
            },
            infoMessage(){
                const messsgesMap = {
                    'deliverableSummary': 'Use the check boxes to let us know what dimensions you need each length of video in'
                }

                return messsgesMap[this.sections[this.currentSectionIdx]] || null
            },
            hasBackNavigation(){
                return this.currentSectionIdx > 0 && this.sections[this.currentSectionIdx] != 'deliverableSummary' && !this.addDeliverableOnly
            },
            deliverableTypeLength() {
                const lengths = {
                    'video': process.env.VUE_APP_DELIVERABLE_VIDEO_LENGTH.split(',').map(v => Number(v.trim())),
                    'gif': process.env.VUE_APP_DELIVERABLE_GIF_LENGTH.split(',').map(v => Number(v.trim()))
                }

                return lengths[this.deliverables.type.value.toLowerCase()]
            },
            hasOrders() {
                return !isEmpty(this.deliverableSummary)
            },
            CTA() {
                return this.currentSectionIdx == (this.sections.length-1) ? 'Submit' : this.sections[this.currentSectionIdx] == 'deliverableSummary' ? 'Place Order' : 'Next'
            },
            closeDialogCTA() {
                return this.sections[this.currentSectionIdx] == 'deliverableSummary' && !this.addDeliverableOnly ? 'Save and Close' : 'Close'
            },
            customDimensionsCTA() {
                return this.deliverables.placement.value?.label?.toLowerCase() == 'facebook carousel ad' ?
                    'How many images?' : 'Custom dimensions'
            },
            disableDimensions() {
                return (!this.deliverables.placement.value && this.deliverables.placement.required) 
                        || !this.deliverables.type.value || this.deliverables.placement?.value?.label.toLowerCase() == 'facebook carousel ad'
            },
            platforms() {
                const platformList = {
                    'organic': ['Facebook / Instagram / Threads', 'YouTube', 'Pinterest', 'TikTok', 'Twitter', 'LinkedIn', 'Snapchat'],
                    'paid': ['Google', 'Amazon', 'CTV']
                }

                const list = platformList[this.projectDetails.type]
                if (this.projectDetails.type.toLowerCase() == 'paid') {
                    list.unshift(...platformList['organic'])
                } 
                
                list.push('Others')

                return list
            },
            platformPlacements() {
                const platformPlacements = PLACEMENTS[this.deliverables.platform.value]
                if (!platformPlacements || !platformPlacements.length) {
                    return []
                }
                
                const placements = platformPlacements?.filter(v => (v.type == this.projectDetails.type && v.mediaType == this.deliverables.type.value && v.label)).map(x => x) || []
                if (placements.length) {
                    placements.push({id: 'custom-dimensions', label: 'Custom Dimensions'})
                }

                return placements
            },
            platformTypes() {
                const platformPlacements = PLACEMENTS[this.deliverables.platform.value]
                if (!platformPlacements || !platformPlacements.length) {
                    return []
                }

                const types = platformPlacements?.map(v => v.mediaType)
                return [...new Set(types)]
            },
            platformDimensions() {
                const platformPlacements = PLACEMENTS[this.deliverables.platform.value]
                if (!platformPlacements || !platformPlacements.length) {
                    return []
                }

                let dimensions = []
                if (this.deliverables.placement.value?.id) {
                    dimensions = platformPlacements?.filter(v => v.id == this.deliverables.placement.value?.id).map(v => ([...v.dimensions])).flat(1)
                } else {
                    dimensions = platformPlacements?.filter(v => v.mediaType == this.deliverables.type.value).map(v => ([...v.dimensions])).flat(1)
                }

                return [...new Set(this.sort(dimensions))]
            },
            requestID() {
                return this.requestData?.id
            },
            deliverablesSelectedCount() {
                let count = 0
                Object.values(this.deliverableSummary).forEach(type => {
                    Object.values(type).forEach(v => {
                        count += v.summary.filter(s => s.selected).length
                    })
                })
               return count
            }
        }
    };
</script>
<style scoped>
    /* hide scrollbar */
    .page-request-details::-webkit-scrollbar,
    .page-request-details .body::-webkit-scrollbar,
    .deliverable-container::-webkit-scrollbar,
    .deliverable-container .summary-details::-webkit-scrollbar,
    .body .deliverables .custom-dimensions-container::-webkit-scrollbar {
        display: none;
    }
    
    .page-request-details .body {
        max-height: 72vh !important;
        min-width: 30vw !important;
        overflow-y: scroll;
    }

    .body .project-details {
        width: 40vw !important;
    }

    .body .deliverables {
        min-width: 70vw !important;
    }

    .body .deliverables .custom-dimensions-container {
        border: 1px solid #cad1d7;
        border-radius: 10px;
        padding: 1em;
        max-height: 25vh;
        overflow-y: scroll;
    }

    .body .deliverables-summary {
        max-width: 94vw !important;
        min-width: 52vw !important;
    }

    .body .deliverables-summary .deliverable-container {
        border: 1px solid #cad1d7;
        border-radius: 10px;
        padding: 1em;
        max-height: 65vh;
        overflow-y: scroll;
        background-color: #f8f8f8;
    }

    .body .deliverables-summary .row {
        margin: unset !important
    }

    .body .deliverables-summary .summary-details {
        max-height: 35vh;
        overflow-y: scroll;
        max-width: 50vw;
        overflow-x: scroll;
    }

    .body .deliverables-summary .summary-details-platform-info {
        max-width: 50vw;
    }

    .body .deliverables-summary .summary-details-platform-info img {
        width: 4%
    }

    .body .deliverables-summary .summary-details-platform-info span {
        font-size: 12px
    }

    .body .deliverables-summary .summary-details table {
        width: 100%;
    }
    
    .body .deliverables-summary .summary-details th,
    .body .deliverables-summary .summary-details td {
        padding: 3px;
        font-size: 12px;
    }

    .body .deliverables-summary .summary-details td.checkbox {
        width: 1vw !important;
    }

    .body .deliverables-summary .summary-details th {
        background-color: #f8f8f8;
        position: sticky;
        top: 0;
        z-index: 1
    }

    .body .deliverables-summary .summary-details .details-cell {
        padding-right: 1em !important;
    }

    .body .deliverables-summary .summary-details .length-cell {
        position: sticky;
        left: 0em;
        z-index: 1;
        background-color: #f8f8f8;
        padding-right: 0.5em !important;
    }

    .body .deliverables-summary .summary-details .long-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 18em; 
    }

    .body .deliverables-summary .summary-details td .editable {
        margin: 0 auto
    }

    .footer {
        background: unset !important;
        padding: unset !important
    }

    .footer .back-navigation a {
        cursor: pointer !important;
        color: #5e72e4 !important;
        font-size: 12px !important;
    }

    .info-circle {
        padding-left: 0.5em;
    }

    hr {
        margin-top: 1em !important;
        margin-bottom: unset !important;
    }
</style>
<style>
    body::-webkit-scrollbar {
        display: none;
    }

    .project-dialog .modal-dialog {
        max-width: fit-content !important;
    }

    .project-dialog .modal-content {
        max-height: 92vh !important;
        overflow-y: scroll !important;
    }

    .project-dialog .modal-content::-webkit-scrollbar{
        display: none;
    }

    .nav-tabs {
        border: unset !important
    }

    .nav-tabs .nav-link {
        border-bottom: 3px solid #dee2e6 !important;
        font-size: 13px !important;
    }

    .nav-tabs .nav-link.active {
        background-color: unset !important;
        border-bottom: 3px solid #2dce89 !important;
    }

    .nav-link:hover {
       font-weight: bold !important;
       color: unset !important
    }

    .body .project-details .type .custom-control-label::before {
        position: unset !important;
        margin-right: 1em !important;
    }

    .body .project-details .type .custom-control-label::after {
        background: unset !important;
    }

    .body .project-details .type .custom-control-label {
        display: flex !important;
    }

    .body .project-details .type .custom-control {
        padding-left: 1vw !important;
    }

    .body .deliverables-summary .summary-details td .editable input.form-control {
        height: 0vh !important;
        font-size: 12px !important;
    }

    .collaborators-section {
        margin-top: -1.5em;
    }

    .collaborators-section 
        .client-selected {
            margin-top: 2em !important;
        }
    
    .collaborators-section .custom-control-label::before {
        position: unset !important;
        margin-right: 1em !important;
    }

    .collaborators-section .custom-control-label {
        display: flex !important;
    }
    
    .body .assets .upload-container {
        background: unset !important;
    }

    .body .assets .upload-content {
        border: 1px solid #cad1d7 !important;
        border-radius: 10px !important;
    }

    .body .assets .upload-body {
        border-radius: 10px !important;
    }

    .body .assets .upload-drop-area {
        margin-bottom: unset !important;
        height: unset !important
    }

    .body .assets .upload-instructions-container {
        height: unset !important
    }
</style>