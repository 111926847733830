<template>
    <div>
        <div v-if="hasParent">
            <div class="card shadow">
                <div class="card-header bg-transparent no-border">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0">Projects</h3>
                        </div>
                        <div class="col text-right">
                            <!-- <base-button type="primary" size="sm">See all</base-button> -->
                        </div>
                    </div>
                </div>

                <loader :loading="loading" class="mt-4"/>
                <div class="table-responsive" v-if="!loading" >
                    <base-table
                        class="table align-items-center table-flush table-hover"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                        :columns="columns"
                        :sortDirection="sort.direction"
                        :sortColumn="sort.column"
                        v-model="sort"
                    >
                        <template slot-scope="{row}">
                            <tr @click.ctrl.exact.prevent="handleRowClick('new', row)" @click.prevent.exact="handleRowClick('redirect', row)">
                                <td class="">{{$moment(row.created_date).format('L')}}</td>
                                <td class="wrap-cell">
                                    <a :href="`/request/${row.id}`" :style="{'color': 'unset'}" v-if="row.request_status.toUpperCase() != 'DRAFT'">{{ row.name }}</a>
                                    <template v-else>{{ row.name }}</template>
                                    <br>
                                    <small :style="{'color': '#FA903E'}"><i>{{ needFollowup(row) }}</i></small>
                                </td>
                                <td class="text-left">
                                    <div class="progress-shell position-relative" v-if="row.request_status.toUpperCase() != 'DRAFT' && row.deliverables.length">
                                        <div class="bar d-flex align-items-center text-center" :style="{ width: getProgress(row) + '%' }">
                                            <small class="progress-cta position-absolute"><b>{{  getDeliverablesProgressCTA(row) }}</b></small>
                                        </div>
                                    </div>  
                                    <template v-else>
                                        <!-- <badge class="badge-dot mr-4" :type="row.statusType">
                                            <i :class="`bg-${row.statusType}`"></i>
                                            <span class="status">{{row.request_status}}</span>
                                        </badge> -->
                                        <span class="status">{{row.request_status}}</span>
                                    </template> 
                                </td>
                                <td class="text-center">
                                    <template v-if="isAdmin">
                                        <div v-if="row.request_status.toUpperCase() != 'DRAFT'"
                                            class="popup" 
                                            @click.stop="$event.preventDefault(); $event.stopPropagation()">
                                            <img @click="showLinkOptions(row.id)" v-bind:src="'/img/brand/vidsprint_logo.png'" style="width: 15%"/> 
                                            <div class="options d-flex justify-content-between" :ref="row.id"
                                                :style="{'left': row.review_link ? '-10px' : '-5px'}">
                                                <template v-if="row.review_link">
                                                    <small @click="openFramePortal(row.review_link); hideLinkOptions()" :style="{'width': '59px'}">View Link</small>
                                                    <small class="px-2">|</small>
                                                </template>
                                                <small @click="handleUpdateReviewLink(row); hideLinkOptions()">
                                                    Update Link
                                                </small>
                                            </div>
                                        </div>
                                        <img v-else v-bind:src="'/img/brand/vidsprint_logo.png'" :style="{'width': '15%', 'opacity': '0.2'}"/> 
                                    </template>
                                    <div v-else>
                                        <div v-if="row.review_link"
                                            class="popup" 
                                            @click.stop="$event.preventDefault(); $event.stopPropagation()">
                                            <img @click="showLinkOptions(row.id)" v-bind:src="'/img/brand/vidsprint_logo.png'" style="width: 15%"/> 
                                            <div class="options d-flex justify-content-between" :ref="row.id">
                                                <small @click="openFramePortal(row.review_link); hideLinkOptions()">View Link</small>
                                                <small class="px-2">|</small>
                                                <small v-clipboard:copy="row.review_link" @click="hideLinkOptions">Copy Link</small>
                                            </div>
                                        </div>
                                        <img v-else v-bind:src="'/img/brand/vidsprint_logo.png'" :style="{'width': '15%', 'opacity': '0.2'}"/> 
                                    </div>
                                </td>
                                <td class="">{{ $tools.capitalize(row.client_name) }}</td>
                                <td class="">{{ $lodash.startCase(row.request_by) }}</td>
                                <td>
                                    <div class="avatar-group">
                                        <template>
                                            <div
                                                class="avatar avatar-md rounded-circle"
                                                :class="getColors(index)"
                                                v-for="(collaborator, index) in row.collaborators"
                                                :key="index"
                                            >
                                                <img :src="getPhoto(collaborator.id)" v-if="collaborator.profile_image" :title="getName(collaborator)" />
                                                <span class="collab-member-initial" v-if="!collaborator.profile_image"  :title="getName(collaborator)">
                                                    {{ $tools.getInitial(getName(collaborator) || collaborator.email) }}
                                                </span>
                                            </div>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </base-table>
                </div>
                <div class="card-footer d-flex justify-content-between bg-transparent">
                    <div class="">
                        <select v-model="size" class="form-control px-1 text-center">
                            <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
                        </select>
                    </div>
                    <base-pagination :total="Number(total)" :value="Number(pagination)" :perPage="Number(table.size)" v-model="pagination" />
                </div>
            </div>
        </div>

        <div :class="`page-${$route.name}`" v-if="!hasParent" >
            <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8" />
         
            <loader :loading="loading" class="mt-4"/>
            <div class="container-fluid mt--8" v-if="!loading" >
                    <div class="row justify-content-end mb-2">
                    <div class="col col-xl-3 col-md-6">
                        <base-input placeholder="Search" class="input-group-alternative" alternative="" addon-right-icon="fas fa-search" v-model="search" />
                    </div>
                    <div class="col col-xl-3 col-md-5" v-if="((isUser && isClientActive) || isAdmin)">
                        <base-button block class="" icon="fa fa-bolt" type="success" @click="handleToggleDialog()">New order</base-button>
                    </div>
                </div>

                <div class="row" >
                    <div class="col">
                        <div class="card shadow">
                            <div class="card-header bg-transparent no-border">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h3 class="mb-0">Projects</h3>
                                    </div>
                                    <div class="col-3 text-right">
                                        <div class="row"> 
                                            <multiselect
                                                v-model="fields.status"
                                                :options="statusList"
                                                :close-on-select="true"
                                                :clear-on-select="false"
                                                placeholder="Filter Status"
                                                label="name"
                                                track-by="id"
                                                selectLabel="Status"
                                                deselectLabel=""
                                                @input="() => { resetSort(); handleTableChange() }"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div class="table-responsive mt-2">
                                <base-table
                                    class="table align-items-center table-flush table-hover"
                                    thead-classes="thead-light"
                                    tbody-classes="list"
                                    :data="records"
                                    :columns="columns"
                                    :sortDirection="sort.direction"
                                    :sortColumn="sort.column"
                                    v-model="sort"
                                >
                                    <template slot-scope="{row}">
                                        <tr @click.ctrl.exact.prevent="handleRowClick('new', row)" @click.prevent.exact="handleRowClick('redirect', row)">
                                            <td class="">{{$moment(row.created_date).format('L')}}</td>
                                            <td class="wrap-cell">
                                                <a :href="`/request/${row.id}`" :style="{'color': 'unset'}" v-if="row.request_status.toUpperCase() != 'DRAFT'">{{ row.name }}</a>
                                                <template v-else>{{ row.name }}</template>
                                                <br>
                                                <small :style="{'color': '#FA903E'}"><i>{{ needFollowup(row) }}</i></small>
                                            </td>
                                            <td class="text-left">
                                                <div class="progress-shell position-relative" v-if="row.request_status.toUpperCase() != 'DRAFT' && row.deliverables.length">
                                                    <div class="bar d-flex align-items-center text-center" :style="{ width: getProgress(row) + '%' }">
                                                        <small class="progress-cta position-absolute"><b>{{  getDeliverablesProgressCTA(row) }}</b></small>
                                                    </div>
                                                </div>   
                                                <template v-else>
                                                    <!-- <badge class="badge-dot mr-4" :type="row.statusType">
                                                        <i :class="`bg-${row.statusType}`"></i>
                                                        <span class="status">{{row.request_status}}</span>
                                                    </badge> -->
                                                    <span class="status">{{row.request_status}}</span>
                                                </template> 
                                            </td>
                                            <td class="text-center">
                                                <template v-if="isAdmin">
                                                    <div v-if="row.request_status.toUpperCase() != 'DRAFT'"
                                                        class="popup" 
                                                        @click.stop="$event.preventDefault(); $event.stopPropagation()">
                                                        <img @click="showLinkOptions(row.id)" v-bind:src="'/img/brand/vidsprint_logo.png'" style="width: 15%"/> 
                                                        <div class="options d-flex justify-content-between" :ref="row.id"
                                                            :style="{'left': row.review_link ? '-10px' : '-5px'}">
                                                            <template v-if="row.review_link">
                                                                <small @click="openFramePortal(row.review_link); hideLinkOptions()" :style="{'width': '59px'}">View Link</small>
                                                                <small class="px-2">|</small>
                                                            </template>
                                                            <small @click="handleUpdateReviewLink(row); hideLinkOptions()">
                                                                Update Link
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <img v-else v-bind:src="'/img/brand/vidsprint_logo.png'" :style="{'width': '15%', 'opacity': '0.2'}"/> 
                                                </template>
                                                <div v-else>
                                                    <div v-if="row.review_link"
                                                        class="popup" 
                                                        @click.stop="$event.preventDefault(); $event.stopPropagation()">
                                                        <img @click="showLinkOptions(row.id)" v-bind:src="'/img/brand/vidsprint_logo.png'" style="width: 15%"/> 
                                                        <div class="options d-flex justify-content-between" :ref="row.id">
                                                            <small @click="openFramePortal(row.review_link); hideLinkOptions()">View Link</small>
                                                            <small class="px-2">|</small>
                                                            <small v-clipboard:copy="row.review_link" @click="hideLinkOptions">Copy Link</small>
                                                        </div>
                                                    </div>
                                                    <img v-else v-bind:src="'/img/brand/vidsprint_logo.png'" :style="{'width': '15%', 'opacity': '0.2'}"/> 
                                                </div>
                                            </td>
                                            <td class="">{{ $tools.capitalize(row.client_name) }}</td>
                                            <td class="">{{ $lodash.startCase(row.request_by) }}</td>
                                            <td>
                                                <div class="avatar-group">
                                                    <template>
                                                        <div
                                                            class="avatar avatar-md rounded-circle"
                                                            :class="getColors(index)"
                                                            v-for="(collaborator, index) in row.collaborators"
                                                            :key="index"
                                                        >
                                                            <img :src="getPhoto(collaborator.id)" v-if="collaborator.profile_image" :title="getName(collaborator)" />
                                                            <span class="collab-member-initial" v-if="!collaborator.profile_image"  :title="getName(collaborator)">
                                                                {{ $tools.getInitial(getName(collaborator) || collaborator.email) }}
                                                            </span>
                                                        </div>
                                                    </template>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </base-table>
                            </div>
                            <div class="card-footer d-flex justify-content-between bg-transparent">
                                <div class="">
                                    <select v-model="size" class="form-control px-1 text-center">
                                        <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
                                    </select>
                                </div>
                                <base-pagination :total="Number(total)" :value="Number(pagination)" :perPage="Number(table.size)" v-model="pagination" />
                            </div>
                        </div>
                    </div>
                </div>
                <router-view />
            </div>
        </div>
        <modal v-if="showProjectDialog" :show="showProjectDialog" :clickToClose="false" :key="projectDialogKey" class="project-dialog">
            <request-section-new 
                @closeDialog="handleToggleDialog()" 
                :componentKey="projectDialogKey" 
                :key="projectDialogKey"
                :projectID="selectedProject ? selectedProject.id : null"
            ></request-section-new>
        </modal>
        <modal :show="showModalReviewLink" :clickToClose="false">
            <h3 slot="header" class="modal-title" id="modal-title-request-receive-date">Review Link</h3>
            <base-input
                type="text"
                required
                v-model="fields.reviewLink.value"
                :error="fields.reviewLink.error"
                :valid="!fields.reviewLink.error ? true : false"
                @input="validate(fields.reviewLink)"
                @blur="validate(fields.reviewLink)"
            />
            <loader :loading="loading" />
            <template slot="footer" v-if="!loading">
                <base-button type="link" @click="showModalReviewLink = false">Close</base-button>
                <base-button type="primary" @click.prevent="handleReviewLinkUpdate">Save changes</base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    import qs from "qs";
    import startCase from "lodash/startCase";
    import debounce from "lodash/debounce";
    import omit from "lodash/omit";
    import { v4 } from 'uuid';
    import { columns } from "@/container/request/model";
    import { mapState, mapGetters } from "vuex";
    import Multiselect from 'vue-multiselect'
    import RequestSectionNew from '../request-details/component/RequestSectionV3.vue';
    import VueClipboard from 'vue-clipboard2';
    import Vue from 'vue'

    Vue.use(VueClipboard);

    export default {
        name: "request-container",
        components: {
            Multiselect,
            RequestSectionNew,
        },
        props: {
            change_id: {
                type: String,
                default: '',
                description: 'to determine the parent has changes and force the list to update'
            },
            client_ids: {
                type: Array,
                default: () => [],
                description: 'request filter by client id'
            },
            hasParent: {
                type: Boolean,
                description: "Whether there's a parent or not",
                default: false,
            },
            user_id: {
                type: String,
                description: "specific request by user",
            }
        },
        data: () => ({
            loading: false,
            showProjectDialog: false,
            projectDialogKey: v4(),
            showModalReviewLink: false,
            selectedProject: null,
            fields: {
                name: "",
                description: "",
                status: "",
                reviewLink: {
                    value: '',
                    error: '',
                    required: true
                },
            },
            errors: {
                name: "",
                description: "",
            },
            columns: columns,
            client_profile_table:{
                search: {
                    fields: ['name', 'request_by'],
                    value: ''
                },
               
                sort: [{
                    column: 'has_activity_last_5_days',
                    direction: 'desc'
                },
                {
                    column: 'last_activity_date',
                    direction: 'desc'
                },
                {
                    column: 'days_from_last_activity',
                    direction: 'desc'
                }],
                page:  0,
                size: localStorage.getItem('size') || 10,
                client_ids: [],
                sizeOptions: [5, 10, 50, 100, 250, 500],
            },
            table: {
                search: {
                    fields: ['name', 'request_by'],
                    value: ''
                },
                //sort:(localStorage.getItem('sort')) ? localStorage.getItem('sort') : [{column:"name",direction:"asc"}],
                sort: [{
                    column: 'has_activity_last_5_days',
                    direction: 'desc'
                },
                {
                    column: 'last_activity_date',
                    direction: 'desc'
                },
                {
                    column: 'days_from_last_activity',
                    direction: 'desc'
                }],
                status: [(localStorage.getItem('filterStatus')) ? localStorage.getItem('filterStatus') : ''],
                page:  0,
                size: localStorage.getItem('size') || 10,
                client_ids: [],
                sizeOptions: [5, 10, 50, 100, 250, 500],
            },
        }),
        created() {
            console.log('table sort',this.table);

            this.client_profile_table.client_ids = [...(this?.client_ids || [])]

            if(!localStorage.getItem('size')){
                 localStorage.setItem('size', this.table.size)
            }
            
            // if(!localStorage.getItem('sortColumn') && this.filterSort?.length){
            //     localStorage.setItem('sortColumn',this.filterSort[0].column)
            //     localStorage.sortColumn = this.filterSort[0].column;
            // }
            //  if(!localStorage.getItem('sortDirection') && this.filterSort?.length){
            //     localStorage.setItem('sortDirection',this.filterSort[0].direction)
            //     localStorage.sortDirection = this.filterSort[0].direction;
            // }
            //  if(!localStorage.getItem('filterStatus')){
            //     localStorage.setItem('filterStatus',this.filterStatus)
            //     localStorage.filterStatus = this.filterStatus;
            // }
            
            // console.log('table sort after',this.table);
            this.handleTableChange();
        },
        mounted() {
            document.addEventListener('click', () => {
                // close all open popup options
                Object.keys(this.$refs).forEach(v => {
                    if (this.$refs[v]?.className.match('show')) {
                        this.$refs[v].classList.toggle('show')
                    }
                })
            });
            this.$store.commit('REQUEST/setRequest', null)
        },
        methods: {
            getColors(index) {
                const colors = ['bg-success','bg-purple','bg-warning','bg-info','bg-primary','bg-danger']
                if (!colors[index])
                    return colors[this.$tools.randomNumber(5)]
                return colors[index]
            },
            getName(user) {
                return user?.name || `${user?.first_name} ${user?.last_name}`
            },
            async handleToggleDialog(existingProject = null) {
                this.showProjectDialog = !this.showProjectDialog;
                this.selectedProject = existingProject
                this.projectDialogKey = v4()
                // prevent body from scrolling while model is open
                document.body.classList.add("modal-open");
                if (!this.showProjectDialog) {
                    document.body.classList.remove("modal-open");
                    await this.handleTableChange()  
                }
            },
            getPhoto(id) {
                let result 

                this.collabs.forEach(item => {
                    if(item?.id == id) {
                        result = item?.profile_image
                    }
                   
                });
                
                return result
               
            },
            handleRowClick(type, row) {
                if (!row.id)
                    return;

                if (row.request_status.toLowerCase() == 'draft') {
                    this.selectedProject = row
                    this.handleToggleDialog(row)
                    return
                }

                this.$router.push(`/request/${row.id}`)
            },
            getProgress(row) {
                const totalDeliverables = row.deliverables.length
                const approvedDelivables = row.deliverables.filter(v => v.status.toLowerCase() == 'approved').length

                return approvedDelivables == 0 ? 0 : 100 / (totalDeliverables / approvedDelivables)
            },
            openFramePortal(requestReviewLink) {
                window.open(requestReviewLink, "LatestVersion", "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,width=4000,height=4000");
            },
            showLinkOptions(element) {
                Object.keys(this.$refs).forEach(v => {
                    if (this.$refs[v]?.className.match('show')) {
                        this.$refs[v].classList.toggle('show')
                    }
                })

                this.$refs[element].classList.toggle('show')
            },
            hideLinkOptions() {
                Object.keys(this.$refs).forEach(v => {
                   this.$refs[v].classList.remove('show')
                })
            },
            async handleTableChange() {
                this.loading = true
                this.table.status = [];
                if(this.fields.status.name !== '[All]') {
                    if(this.isUser && (this.fields.status.name == 'In progress' || this.fields.status.name == 'Storyboard - In progress')) {
                        this.table.status.push(this.fields.status.name)
                        this.table.status.push('Ready for review')

                    } else {
                        this.table.status.push(this.fields.status.name)
                    }
                    
                }

                if (this.user?.id) {
                    console.log('Entry Here');
                    if(this.$route.name == 'request'){
                        console.log('ddd');
                        await this.$store.dispatch("REQUEST/getUserRequestList", {
                            user_id: this.user_id || this.user?.id,
                            payload: qs.stringify(omit(this.table, ["sizeOptions"])),
                            is_user: this.isUser
                         })
                    }else{
                        console.log('client-profile',this.client_profile_table);
                        await this.$store.dispatch("REQUEST/getUserRequestList", {
                            user_id: this.user_id || this.user?.id,
                            payload: qs.stringify(omit(this.client_profile_table, ["sizeOptions"])),
                            is_user: this.isUser
                         })
                    }
                    
                } else {
                    console.log('Entry Here 2');
                    if(this.$route.name == 'request'){
                        await this.$store.dispatch("REQUEST/getRequestList", qs.stringify(omit(this.table, ["sizeOptions"])))
                    }else{
                        await this.$store.dispatch("REQUEST/getRequestList", qs.stringify(omit(this.client_profile_table, ["sizeOptions"])))
                    }
                    
                }
                this.loading = false
            },
            getDeliverablesProgressCTA(row) {
                const deliverablesCount = row.deliverables.length
                const submittedCount = row.deliverables.filter(v => v.status?.toLowerCase() == 'submitted').length
                const assignedCount = row.deliverables.filter(v => v.status?.toLowerCase() == 'assigned').length
                const inProgressCount = row.deliverables.filter(v => v.status?.toLowerCase() == 'storyboard - in progress' 
                    || v.status?.toLowerCase() == 'in production' || v.status?.toLowerCase() == 'storyboard - ready for internal review' 
                    || v.status?.toLowerCase() == 'ready for internal review').length
                const sentForReviewCount = row.deliverables.filter(v => v.status?.toLowerCase() == 'storyboard - ready for review').length
                const latestVersionReadyCount = row.deliverables.filter(v => v.status?.toLowerCase() == 'latest version ready').length
                const approvedCount = row.deliverables.filter(v => v.status?.toLowerCase() == 'approved').length
                
                // 1. if nothing is in progress and nothing is approved
                if ((submittedCount + assignedCount) == deliverablesCount) {
                        return 'Submitted'
                }

                // 2. if all approved
                if (approvedCount == deliverablesCount) {
                    return 'Completed'
                }

                // 3. if there's an approved
                if (approvedCount > 0) {
                    let text = `${approvedCount} Approved`
                    if ((sentForReviewCount + latestVersionReadyCount) > 0) {
                        text = `${text} / ${(sentForReviewCount + latestVersionReadyCount)} Latest version ready`
                    } else if (inProgressCount > 0) {
                        text = `${text} / ${inProgressCount} In progress`
                    } else if (assignedCount > 0) {
                        text = `${text} / ${assignedCount} Assigned`
                    }
                    return text
                }

                // 3. if there's a sent for review or latest version ready
                if ((sentForReviewCount + latestVersionReadyCount) > 0) {
                    let text = `${(sentForReviewCount + latestVersionReadyCount)} Latest version ready`
                    if (inProgressCount > 0) {
                        text = `${text} / ${inProgressCount} In progress`
                    } else if (assignedCount > 0) {
                        text = `${text} / ${assignedCount} Assigned`
                    }
                    return text
                }

                // 4. if there's an in progress
                if (inProgressCount > 0) {
                    let text = `${inProgressCount} In progress`
                    if (assignedCount > 0) {
                        text = `${text} / ${assignedCount} Assigned`
                    }
                    return text
                }
                
                return 'Submitted'
            },
            validate(field) {
                field.error = ''
                if (field.required && !field.value) {
                    field.error = 'This field is required'
                }
            },
            async handleReviewLinkUpdate() {
                this.validate(this.fields.reviewLink)
                if (this.fields.reviewLink.error) {
                    return
                }

                await this.$store.dispatch("REQUEST/updateRequestReviewLink", {
                    id: this.selectedProject.id,
                    reviewLink: this.fields.reviewLink.value
                })

                this.showModalReviewLink = false
                this.fields.reviewLink.value = ''
                await this.handleTableChange()  
                this.$notify({
                    icon: "ni ni-check-bold",
                    type: "success",
                    title: "Success",
                    message: `Review link successfully updated`,
                })
            },
            needFollowup(request) {
                if (request.request_status.toUpperCase() == 'APPROVED') {
                    return ''
                }

                const lastActivityDate = request.last_activity_date || request.created_date 
                const last5Days = this.$moment().subtract(5,'d')
                if (this.$moment(lastActivityDate).isBefore(last5Days) && request.has_activity_last_5_days) {
                    const daysDiff = this.$moment(lastActivityDate).fromNow()
                    return `Updated ${daysDiff}, follow up?`
                }
               
                return ''
            },
            handleUpdateReviewLink(row) {
                this.selectedProject = row; 
                this.fields.reviewLink.value = row.review_link, 
                this.showModalReviewLink = true
            },
            resetSort() {
                this.table.sort =  [{
                    column: 'has_activity_last_5_days',
                    direction: 'desc'
                },
                {
                    column: 'last_activity_date',
                    direction: 'desc'
                },
                {
                    column: 'days_from_last_activity',
                    direction: 'desc'
                }]
            }
        },
        computed: {
            ...mapGetters("AUTH", [
                'isUser',
                'isAdmin',
                'client',
                'isClientActive',
                'searchValue',
                'filterSize',
                'filterSort',
                'filterSettings'
            ]),
            ...mapState("AUTH", {
                loggedUser: (state) => state.user 
            }),
            ...mapState("REQUEST", {
                records: (state) => state.data,
                total: (state) => state.total,
                collabs: (state) => state.collaborators
            }),
            collaborators() {
                return this.collabs;
            },
            resourceName() {
                return this.$route.params.id;
            },
            user() {
                return this.loggedUser;
            },
            debounceSetTableLonger() {
                return debounce(this.handleTableChange, 700);
            },
            debounceSetTable() {
                return debounce(this.handleTableChange, 250);
            },
            startCase: () => startCase,
            search: {
                get() {
                    return this.table.search.value;
                },
                set(keyword) {
                    this.table.search.value  = keyword;
                    this.table.page = 0;
                    
                    this.debounceSetTableLonger();
                },
            },
            size: {
                get() {
                    // localStorage.setItem('size',this.table.size);
                    return this.table.size;
                },
                set(size) {
                    // if(localStorage.getItem('size')){
                    //     this.table.size = localStorage.getItem('size');
                    // }else{
                    //      this.table.size = size;       
                    // }
                    // console.log('localstorage',localStorage.getItem('size'));
                    localStorage.size = size;
                   
                    this.table.page = 0;
                    this.table.size = size;
                    this.debounceSetTable();
                },
            },
          sort: {
            get() {
                if(this.$route.name == 'request'){
                    return this.table.sort[0]
                }else{
                    return this.client_profile_table.sort[0]
                }
            
            },
            set(sort) {
                if(this.$route.name == 'request'){
                   this.table.sort = [sort]
                    this.debounceSetTableLonger()
                }else{
                    this.client_profile_table.sort = [sort]
                    this.debounceSetTableLonger()
                    
                }
            
            }
           
            },
            status:{
                get() {
                    return ['test'];
                },
                set(status){
                    console.log(status);
                }
            },
            pagination: {
                get() {
                   
                   
                    return parseInt(this.table.page) + 1;
                },
                set(page) {
                   
                    this.table.page = parseInt(page) - 1;
                    this.debounceSetTable();
                },
            },
            statusList() {
                let statuses = ['[All]', 'Draft', 'Submitted', 'Assigned', 'In progress', 'Storyboard - In progress', 'Ready for review', 'Sent for review', 'Amendments needed', 'Latest version ready', 'Approved'];

                if(this.isUser) {
                    statuses.splice(4,1)
                }

                return statuses
                    .map((each, i) => (
                        { name: each, id: i }  
                    ));
            }
        },
        watch: {
            async change_id() {
                if (this.hasParent)
                   await this.handleTableChange()
            }
            
        }
    };
</script>
<style scoped>
    .table .wrap-cell {
        white-space: pre-wrap; 
        word-wrap: break-word
    }
    .status-label {
        font-size: smaller;
    }

    .progress-shell {
        width: 16em;
        border: 1px solid #aaa;
        border-radius: 13px;
        padding: 2px;
    }

    .progress-shell .bar {
        background: linear-gradient(to right, #B993D6, #8CA6DB);
        height: 20px;
        width: 15px;
        border-radius: 9px;
    }

    .progress-shell .progress-cta {
        left: 0;
        right: 0
    }

    .popup {
        position: relative;
        display: inline-block;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* The actual popup */
    .popup .options {
        visibility: hidden;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 3px 10px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        right: 0;
        margin: auto;
        width:fit-content;
    }

    /* Popup arrow */
    .popup .options::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }

    /* Toggle this class - hide and show the popup */
    .popup .show {
        visibility: visible;
        -webkit-animation: fadeIn 1s;
        animation: fadeIn 1s;
    }

    /* Add animation (fade in the popup) */
    @-webkit-keyframes fadeIn {
        from {opacity: 0;} 
        to {opacity: 1;}
    }

    @keyframes fadeIn {
        from {opacity: 0;}
        to {opacity:1 ;}
    }
</style> 
