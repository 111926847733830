<template>
  <div :class="`page-${$route.name}`">
    <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="header-body " v-if="true === false">
        <div class="row">
          <div class="col-xl-3 col-lg-6">
            <stats-card
                title="Total Request"
                type="gradient-orange"
                sub-title="2,356"
                icon="ni ni-chart-pie-35"
                class="mb-4 mb-xl-0"
            />
          </div>
          <div class="col-xl-3 col-lg-6">
            <stats-card
                title="Open Request"
                type="gradient-orange"
                sub-title="2,356"
                icon="ni ni-chart-pie-35"
                class="mb-4 mb-xl-0"
            />
          </div>
          <div class="col-xl-3 col-lg-6">
            <stats-card
                title="Active Request"
                type="gradient-orange"
                sub-title="2,356"
                icon="ni ni-chart-pie-35"
                class="mb-4 mb-xl-0"
            />
          </div>
          <div class="col-xl-3 col-lg-6">
            <stats-card
                title="Total Members"
                type="gradient-orange"
                sub-title="2,356"
                icon="ni ni-chart-pie-35"
                class="mb-4 mb-xl-0"
            />
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row report">
        <div class="col col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">User Information</h3>
                </div>
                <template v-if="allowed_edit">
                  <div class="col text-right">
                    <base-button type="primary" size="sm" @click.prevent="handleToggleEdit">
                      {{ toggleEdit ? 'Cancel' : 'Edit' }}
                    </base-button>
                  </div>
                </template>
              </div>
            </div>
            <div class="card-body">
              <form action="">
                <div class="mt-2">
                  <div class="row mt-4 mb-4">
                    <div class="col-lg-4">
                      <div class="card-profile" @click.prevent="handleDialogToggle(true)">
                        <img :src="user_photo" class="rounded-circle" @load.prevent="handleImageLoad">
                        <span v-if="!user_photo" >
                          <img src="../../assets/images/default-profile.jpg" alt="default profile picture" class="default-pic">
                        </span>
                      </div>
                    </div>
                    <div class="col-md-8 p-0">
                      <div class="row">
                        <div class="col-lg-6">
                          <base-input
                              label="First Name"
                              @keyup="handleChange"
                              v-model="fields.first_name"
                              :readOnly="!this.toggleEdit"
                              :error="errors.first_name"
                              @input="validate('first_name')"
                              required
                          />
                        </div>
                        <div class="col-lg-6">
                          <base-input
                              label="Last Name"
                              @keyup="handleChange"
                              v-model="fields.last_name"
                              :readOnly="!this.toggleEdit"
                              :error="errors.last_name"
                              @input="validate('last_name')"
                              required
                          />
                        </div>
                        <div class="col-lg-4">
                          <base-input
                              label="Email"
                              @keyup="handleChange"
                              v-model="fields.email"
                              :readOnly="!this.toggleEdit"
                              :error="errors.email"
                              @input="validate('email')"
                              required
                          />
                        </div>
                        <div class="col-lg-4">
                          <base-input
                              label="Password"
                              @keyup="handleChange"
                              v-model="fields.password"
                              :readOnly="!this.toggleEdit"
                              
                          />
                        </div>
                        <div class="col-md-4" v-if="this.toggleEdit  && auth_user.role === 'Admin'">
                          <div class="form-group">
                            <label class="form-control-label">Status</label>
                            <multiselect
                                v-model="status"
                                :options="statusList"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :preserve-search="true"
                                :hide-selected="true"
                                :searchable="false"
                                :disabled="!this.toggleEdit"
                                placeholder="Select Request"
                                label="name"
                                track-by="id"
                                selectLabel=""
                                deselectLabel=""
                            />
                          </div>
                        </div>
                        <div class="col-md-4" v-if="!this.toggleEdit || !(auth_user.role === 'Admin')">
                          <base-input
                              label="Status"
                              readOnly
                              :value="status.id"
                          />
                        </div>
                        <div class="col-md-4" v-if="!this.toggleEdit || !(auth_user.role === 'Admin')">
                          <base-input
                              label="Role"
                              readOnly
                              :value="user && user.role"
                          />
                        </div>
                        <div class="col-md-4" v-if="this.toggleEdit && auth_user.role === 'Admin'">
                          <div class="form-group">
                            <label class="form-control-label">Role</label>
                            <multiselect
                                v-model="role"
                                :options="roleList"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :preserve-search="true"
                                :hide-selected="true"
                                :searchable="false"
                                :disabled="!this.toggleEdit"
                                placeholder="Select Role"
                                label="name"
                                track-by="id"
                                selectLabel=""
                                deselectLabel=""
                            />
                          </div>
                        </div>
                        <div class="col-md-4" v-if="!this.toggleEdit || !(auth_user.role === 'Admin')" >
                          <base-input
                              label="Client"
                              readOnly
                              :value="user && user.client_name"
                          />
                        </div>
                        <div class="col-md-4" v-if="this.toggleEdit  && auth_user.role === 'Admin'">
                          <div class="form-group">
                              <label class="form-control-label">
                                Client <span class="text-red">*</span>
                              </label>
                              <multiselect
                                v-model="client"
                                :options="clientList"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :searchable="false"
                                placeholder="Select Request"
                                label="name"
                                track-by="id"
                                selectLabel=""
                                deselectLabel=""
                              />
                              <div class="text-danger invalid-feedback" style="display: block;" v-if="errors.client_id">
                                {{ errors.client_id }}
                              </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                           <base-input
                              label="Hubstaff ID"
                              @keyup="handleChange"
                              v-model="fields.hubstaff_id"
                              :readOnly="!this.toggleEdit || !(auth_user.role === 'Admin')"
                              :error="errors.hubstaff_id"
                              @input="validate('hubstaff_id')"
                              required
                          />
                        </div>
                        <div class="col-md-4">
                          <base-input
                              label="Created date"
                              readOnly
                              :value="user && $moment(user.created_date).format('L')"
                          />
                        </div>
                        <div class="col-md-4">
                          <base-input
                              label="Updated date"
                              readOnly
                              :value="user && $moment(user.updated_date).format('L')"
                          />
                        </div>
                        <div class="col-md-4 add-to-projects" v-if="isAdmin">
                          <base-checkbox class="mt-4" v-model="fields.alwaysAddedToNewProjects" :disabled="!this.toggleEdit || !isAdmin">Always added to new projects</base-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <template v-if="toggleEdit">
              <div class="card-footer d-flex justify-content-end bg-transparent">
                <base-button type="link" class="ml-auto btn-radius" @click="handleCancelEdit">Cancel</base-button>
                <base-button type="purple" class="btn-radius" @click.prevent="handleUpdateClient">Save changes
                </base-button>
              </div>
            </template>
          </div>
        </div>
        <div class="col col-md-5 d-none">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Report</h3>
                </div>
                <div class="col text-right">
                  <!-- <base-button type="primary" size="sm">See all</base-button> -->
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <base-table
                    class="table align-items-center table-hover table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="reportData"
                    :columns="[]"
                >
                  <template slot-scope="{row}">
                    <tr>
                      <td class="">{{ $tools.capitalize(row.key) }}</td>
                      <td class="">{{ row.value }}</td>
                    </tr>
                  </template>
                </base-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col col-md-12">
          <request
              :hasParent="true"
              :client_ids="[]"
              :user_id="$route.params.id || ''"
              :change_id="change_id"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col col-md-12">
          <userInvite
              :hasParent="true"
              :invited_by="$route.params.id || ''"
              :change_id="change_id"
          />
        </div>
      </div>

      <router-view />
    </div>
    <RequestUpload
        :imageOnly="true"
        :maxFiles="1"
        :show="showUploadDialog"
        :resource_id="this.user_id"
        resource="user"
        resource_type="profile"
        @toggle="handleDialogToggle"
        @uploaded="handleUploaded"
        :client_id="client_id"
    />
  </div>
</template>
<script>
import qs from 'qs'
import upperFirst from 'lodash/upperFirst'
import request from '@/container/request';
import { RequestUpload, } from '@/container/request-details/component';
import userInvite from '@/container/user-invite';
import { userInitialState, validateInput, validateSchema } from '@/container/user/model';
import { v4 } from 'uuid';
import Multiselect from 'vue-multiselect';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'user-profile-container',
  components: {
    request,
    RequestUpload,
    userInvite,
    Multiselect
  },
  data: () => userInitialState(),
  async created() {
    await this.getUserData();
    this.getClientList(); 
  },
  methods: {
    handleUploaded() {
      setTimeout(() => {
        this.getUserData();
        this.handleRefreshData();
      }, 1500);

      this.$notify({
        icon: 'ni ni-check-bold',
        type: 'success',
        title: 'Success',
        message: 'Profile photo successfully updated!'
      });
    },

    handleDialogToggle(show) {
      if (!this.allowed_edit)
        return;

      this.showUploadDialog = show;
    },

    handleImageLoad(event) {
      if (event?.target?.src?.includes('blob'))
        URL.revokeObjectURL(event?.target?.src);
    },

    getColors(index) {
      const colors = ['bg-success', 'bg-purple', 'bg-warning', 'bg-info', 'bg-primary', 'bg-danger'];
      if (!colors[index])
        return colors[this.$tools.randomNumber(5)];
      return colors[index];
    },
    getName(user) {
      return user?.name || `${user?.first_name} ${user?.last_name}`;
    },
    validate(field) {
      this.errors = validateInput(field, this.fields, this.errors);
    },
    handleChange() {
      if (!this.$lodash.isEqual(this.refFields, this.fields))
        return this.hasChanges = true;

      if (!this.$lodash.isEqual(this.refAnswers, this.answers))
        return this.hasChanges = true;

      return this.hasChanges = false;
    },
    handleToggleEdit() {
      if (!this.allowed_edit)
        return;

      this.toggleEdit = !this.toggleEdit;

      if (!this.toggleEdit)
        this.handleCancelEdit();
    },
    handleCancelEdit() {
      this.errors = {};
      this.toggleEdit = false;
      this.fields = this.$lodash.pick(this.refFields, ['first_name', 'last_name', 'email', 'status']);
    },
    handleRefreshData() {
      this.errors = {};
      this.toggleEdit = false;
      this.change_id = v4();
      this.getUserData();
    },
    async handleUpdateClient() {
      let request;
      const { isValid, errors } = validateSchema(this.fields);
      if (!isValid)
        return this.errors = errors;

      request = await this.$store
          .dispatch('USER/updateUser', { 
            id: this.user?.id,
            first_name: this.fields?.first_name,
            last_name: this.fields?.last_name,
            email: this.fields?.email,
            status: this.fields?.status,
            role: this.fields?.role,
            new_client: this.fields?.client?.id,
            old_client: this.user?.client?.id,
            hubstaff_id: this.fields?.hubstaff_id,
            password: this.fields?.password
          });

      // update app settings only for admins
      if (this.isAdmin) {
        const appSettings = this.user.app_settings
        await this.$store.dispatch('AUTH/updateAppSettings', {
            user_id: this.user.id,
            preferred_email: this.user.email, 
            activity_updates: appSettings.activity_updates,
            mention_only: appSettings.mentions_only,
            daily_summaries: appSettings.daily_summaries,
            in_app_notif: appSettings.in_app_notification,
            email_notif: appSettings.email_notification,
            emails: [],
            always_added_to_new_projects: this.fields.alwaysAddedToNewProjects
        });
      }
      
      if (request) {
        this.handleRefreshData();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `User successfully updated!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    async getUserData() {
      try {
        await this.$store.dispatch('USER/getUserData', this?.$route?.params?.id);
      } catch (err) {
        this.$router.push({ name: 'user' });
      }
    },
    getClientList() {
      const params = qs.stringify({
        status: 'Active'
      })
      this.$store.dispatch('CLIENT/getAllClientListData', params)
    },
  },
  computed: {
    ...mapGetters("AUTH", [
        'isAdmin'
    ]),
    ...mapState('USER', {
      user: state => state.user,
    }),
    ...mapState('AUTH', {
      auth_user: state => state.user
    }),
    ...mapState('CLIENT', {
      clients: state => state.data,
    }),
    clientList() {
      return this.clients.map(each => ({
        ...each,
        name: upperFirst((each?.name).trim())
      }))
    },
    user_id() {
      return this?.$route?.params?.id;
    },
    client_id() {
      return this?.user?.client_id || this?.auth_user?.client_id || '';
    },
    user_photo() {
      return this.user?.profile_image
    },
    allowed_edit() {
      return this?.user_id === this?.auth_user?.id || this?.auth_user?.role === 'Admin';
    },
    reportData() {
      const data = {
        active_user: this?.user?.user_ids || 0,
        pending_invite: 0
      };

      const fields = this.$lodash.pick({ ...this?.user, ...data }, [
        'open_request', 'active_request', 'active_user', 'pending_invite'
      ]);

      return this.$lodash.entries(fields).map(([key, value]) => ({
        value: Array.isArray(value) ? value?.length : value,
        key: this.$lodash
            ?.upperFirst(key)
            ?.replace(/_/g, ' ')
      }));
    },
    status: {
      get() {
        return ({
          name: this?.fields?.status,
          id: this?.fields?.status
        });
      },

      set(object) {
        this.fields.status = object?.id;
      }
    },
    role: {
      get() {
        return ({
          name: this?.fields?.role,
          id: this?.fields?.role
        });
      },

      set(object) {
        this.fields.role = object?.id;
      }
    },
    client: {
      get() {
        return ({
          name: this?.fields?.client?.name,
          id: this?.fields?.client?.id
        });
      },

      set(object) {
        this.fields.client = object;
      }
    },
    statusList() {
      return ['Active', 'Inactive']
          .map(each => ({ name: each, id: each }));
    },
    roleList() {
      return ['Admin', 'Editor', 'User', 'Creative Director']
          .map(each => ({ name: each, id: each }));
    },
  },
  watch: {
    user(latest) {
      if (!latest?.id)
        return;

      this.fields = this.$lodash.pick(latest, ['first_name', 'last_name', 'email', 'status', 'client', 'hubstaff_id','role']);
      this.fields.alwaysAddedToNewProjects = latest.app_settings.always_added_to_new_projects == undefined ? false : latest.app_settings.always_added_to_new_projects
      this.refFields = this.$lodash.pick(latest, ['first_name', 'last_name', 'email', 'status']);
      this.hasChanges = false;
      
    },

  },
};
</script>
<style  scoped>
.default-pic{
  max-width: 100%;
  max-height: 100%;
}
</style>
<style>
  .add-to-projects .custom-control-label::before {
      position: unset !important;
      margin-right: 1em !important;
  }

  .add-to-projects .custom-control-label {
      display: flex !important;
  }
</style>
