<template>
  <div>
    <div v-if="hasParent">
      <div class="card shadow">
        <div class="card-header bg-transparent no-border">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Users Invited</h3>
            </div>
            <div class="col text-right">
              <!-- <base-button type="primary" size="sm">See all</base-button> -->
            </div>
          </div>
        </div>

        <loader :loading="loading" class="mt-4"/>
        <div class="table-responsive" v-if="!loading">
          <base-table
            class="table align-items-center table-flush table-hover"
            thead-classes="thead-light"
            tbody-classes="list"
            :data="records"
            :columns="columns"
            :sortDirection="sort.direction"
            :sortColumn="sort.column"
            v-model="sort"
          >
            <template slot-scope="{row}">
              <tr>
                <td class="">
                  <a
                    @click.ctrl.exact.prevent="handleUserRowClick('new', row.invited_by)"
                    @click.prevent.exact="handleUserRowClick('redirect', row.invited_by)"
                    href="#"
                  >
                    {{ row.invited_by_user }}
                  </a>
                </td>
                <td class="">{{ row.invitee_email }}</td>
                <td class="">{{ row.invitee_role }}</td>
                <td class="">{{ row.client }}</td>
                 <td class="">
                  <a
                    v-if="row.user_id"
                    @click.ctrl.exact.prevent="handleUserRowClick('new', row.user_id)"
                    @click.prevent.exact="handleUserRowClick('redirect', row.user_id)"
                    href="#"
                  >
                    {{ row.invited_user }}
                  </a>
                  <span v-if="!row.user_id">N/A</span>
                </td>
                <td class="">
                  <a
                    v-for="(request, index) in row.requests"
                    :key="index"
                    @click.ctrl.exact.prevent="handleRequestRowClick('new', request.id)"
                    @click.prevent.exact="handleRequestRowClick('redirect', request.id)"
                    href="#"
                    style="display: block"
                  >
                    {{ request.name }}
                  </a>
                  <span v-if="!row.requests">N/A</span>
                </td>
                <td class="">{{ $tools.capitalize(row.status) }}</td>
                <td class="">
                  <span v-if="row.expiry_date">
                    {{$moment(row.expiry_date).format('L')}}
                  </span>
                  <span v-if="!row.expiry_date">N/A</span>
                </td>
                <td class="">
                  <span v-if="row.send_date">
                    {{$moment(row.send_date).format('L')}}
                  </span>
                  <span v-if="!row.send_date">N/A</span>
                </td>
                <td class="">
                  <span v-if="row.created_date">
                    {{$moment(row.created_date).format('L')}}
                  </span>
                  <span v-if="!row.created_date">N/A</span>
                </td>
                <td class="">
                  <span v-if="row.accept_date">
                    {{$moment(row.accept_date).format('L')}}
                  </span>
                  <span v-if="!row.accept_date">N/A</span>
                </td>
                <td class="table-custom-action">
                    <template  v-if="auth_user_id !== row.invited_by && auth_user.role === 'Admin'">
                      <base-button
                        type="neutral"
                        rounded
                        icon="fa fa-trash-alt"
                        @click.prevent="handleDelete(row)"
                      />
                    </template>
                </td>
              </tr>
            </template>
          </base-table>
        </div>
        <div class="card-footer d-flex justify-content-between bg-transparent">
          <div class="col-md-1 col-sm-12">
            <select v-model="size" class="form-control">
              <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
            </select>
          </div>
          <base-pagination
            :total="Number(total)"
            :value="Number(pagination)"
            :perPage="Number(table.size)"
            v-model="pagination"
          />
        </div>
      </div>
    </div>
    <div :class="`page-${$route.name}`" v-if="!hasParent">
      <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8"/>

      <loader :loading="loading" class="mt-4"/>
      <div class="container-fluid mt--8" v-if="!loading">
        <div class="row justify-content-end mb-2">
          <div class="col col-xl-3 col-md-6">
            <base-input
              placeholder="Search"
              class="input-group-alternative"
              alternative=""
              addon-right-icon="fas fa-search"
              v-model="search"
            />
          </div>
          <div class="col col-xl-2 col-md-5">
            <base-button
              block
              class=""
              icon="fa fa-plus"
              type="success"
              @click="handleToggleInviteDialog(true)"
            >Invite User</base-button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card shadow">
              <div class="card-header bg-transparent no-border">
                <div class="row align-items-center">
                  <div class="col">
                    <h3 class="mb-0">Users Invited</h3>
                  </div>
                  <div class="col text-right">
                    <!-- <base-button type="primary" size="sm">See all</base-button> -->
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <base-table
                  class="table align-items-center table-flush table-hover"
                  thead-classes="thead-light"
                  tbody-classes="list"
                  :data="records"
                  :columns="columns"
                  :sortDirection="sort.direction"
                  :sortColumn="sort.column"
                  v-model="sort"
                >
                  <template slot-scope="{row}">
                    <tr>
                      <td class="">
                        <a
                          @click.ctrl.exact.prevent="handleUserRowClick('new', row.invited_by)"
                          @click.prevent.exact="handleUserRowClick('redirect', row.invited_by)"
                          href="#"
                        >
                          {{ row.invited_by_user }}
                        </a>
                      </td>
                      <td class="">{{ row.invitee_email }}</td>
                      <td class="">{{ row.invitee_role }}</td>
                      <td class="">{{ row.client }}</td>
                       <td class="">
                        <a
                          v-if="row.user_id"
                          @click.ctrl.exact.prevent="handleUserRowClick('new', row.user_id)"
                          @click.prevent.exact="handleUserRowClick('redirect', row.user_id)"
                          href="#"
                        >
                          {{ row.invited_user }}
                        </a>
                        <span v-if="!row.user_id">N/A</span>
                      </td>
                      <td class="">
                        <a
                          v-for="(request, index) in row.requests"
                          :key="index"
                          @click.ctrl.exact.prevent="handleRequestRowClick('new', request.id)"
                          @click.prevent.exact="handleRequestRowClick('redirect', request.id)"
                          href="#"
                          style="display: block"
                        >
                          {{ request.name }}
                        </a>
                        <span v-if="!row.requests">N/A</span>
                      </td>
                      <td class="">{{ $tools.capitalize(row.status) }}</td>
                      <td class="">
                        <span v-if="row.expiry_date">
                          {{$moment(row.expiry_date).format('L')}}
                        </span>
                        <span v-if="!row.expiry_date">N/A</span>
                      </td>
                      <td class="">
                        <span v-if="row.send_date">
                          {{$moment(row.send_date).format('L')}}
                        </span>
                        <span v-if="!row.send_date">N/A</span>
                      </td>
                      <td class="">
                        <span v-if="row.created_date">
                          {{$moment(row.created_date).format('L')}}
                        </span>
                        <span v-if="!row.created_date">N/A</span>
                      </td>
                      <td class="">
                        <span v-if="row.accept_date">
                          {{$moment(row.accept_date).format('L')}}
                        </span>
                        <span v-if="!row.accept_date">N/A</span>
                      </td>
                      <td class="table-custom-action" v-if="auth_user.role === 'Admin'">
                        <base-button
                            class="ml-1"
                            type="neutral"
                            rounded
                            title="Show Details"
                            icon="fa fa-tasks"
                            @click.prevent="handleShowDetails(row)"/>
                      </td>
                    </tr>
                  </template>
                </base-table>
              </div>
              <div class="card-footer d-flex justify-content-between bg-transparent">
                <div class="col-md-1 col-sm-12">
                  <select v-model="size" class="form-control">
                    <option v-for="(page, index) in table.sizeOptions" :key="index">{{ page }}</option>
                  </select>
                </div>
                <base-pagination
                  :total="Number(total)"
                  :value="Number(pagination)"
                  :perPage="Number(table.size)"
                  v-model="pagination"
                />
              </div>
            </div>
          </div>
        </div>
        <router-view/>
      </div>
        <InviteDialog
          :show="showInviteDialog"
          @sent="handleOnSendInvite"
          @toggle="handleToggleInviteDialog"
        />
        <modal v-if="showDetailsModal" :show="showDetailsModal" @close="handleCloseDetailsModal">
          <h3 slot="header" class="modal-title">Invitation Details</h3>
          <div class="d-flex flex-row justify-content-between details-container">
            <div class="d-flex flex-column">
                <div class="mb-2">
                  <small>Invitee email </small><br>
                  <label class="form-control-label">
                      {{ selectedInvite.invitee_email }}
                  </label>
                </div>
                <div class="mb-2">
                  <small>Invitee Role </small><br>
                  <label class="form-control-label">
                      {{ selectedInvite.invitee_role }}
                  </label>
                </div>
                <div class="mb-2">
                  <small>Client </small><br>
                  <label class="form-control-label">
                      {{ selectedInvite.client }}
                  </label>
                </div>
                <div class="mb-2">
                  <small>Project(s) Invited </small><br>
                  <label class="form-control-label" 
                    v-html="selectedInvite.requests ? 
                      selectedInvite.requests.map(v => v.name).join(',<br>') 
                      : 'N/A'">
                  </label>
                </div>
            </div>
            <div class="d-flex flex-column" :style="{'width': '40%'}">
              <div class="mb-2">
                <small>Invite Status </small><br>
                <label class="form-control-label">
                    {{ selectedInvite.status }}
                </label>
              </div>
              <div class="mb-2">
                <small>Invited By </small><br>
                <label class="form-control-label">
                    {{ selectedInvite.invited_by_user }}
                </label>
              </div>
              <div class="mb-2">
                <small>Invited Date </small><br>
                <label class="form-control-label">
                  {{$moment(selectedInvite.created_date).format('L')}}
                </label>
              </div>
              <div class="mb-2">
                <small>Accepted Date </small><br>
                <label class="form-control-label">
                  {{selectedInvite.accept_date ? $moment(selectedInvite.accept_date).format('L') : 'N/A'}}
                </label>
              </div>
            </div>
          </div>
          <loader :loading="loading" v-if="loading"/>
          <template slot="footer" v-else>
            <base-button v-if="selectedInvite.status === 'Pending Approval'"
              type="success" 
              @click="handleApprove(selectedInvite)">
              Approve
            </base-button>
            <base-button type="primary" @click="handleResend(selectedInvite); handleCloseDetailsModal()">Resend</base-button>
            <base-button type="danger" @click="handleDelete(selectedInvite); handleCloseDetailsModal()">Delete</base-button>
          </template>
        </modal>
    </div>
  </div>
</template>
<script>
  import qs from 'qs'
  import startCase from 'lodash/startCase'
  import debounce from 'lodash/debounce'
  import omit from 'lodash/omit'
  import InviteDialog from '@/container/user-invite/component/InviteDialog'
  import { columns } from '@/container/user-invite/model'
  import { mapState } from "vuex";

  export default {
    name: 'user-invite-container',
    components: {
      InviteDialog
    },
    props: {
      change_id: {
        type: String,
        default: '',
        description: 'to determine the parent has changes and force the list to update'
      },
      client_id: {
        type: String,
        default: '',
        description: 'request filter by client id'
      },
      invited_by: {
        type: String,
        default: '',
        description: 'request filter by invited_by id'
      },
      hasParent: {
        type: Boolean,
        description: "Whether there's a parent or not",
        default: false,
      },
    },
    data: () => ({
      UserDialog: false,
      showInviteDialog: false,
      showDetailsModal: false,
      selectedInvite: null,
      fields: {
        question: '',
        comment: ''
      },
      errors: {
        question: '',
        comment: ''
      },
      columns: columns,
      table: {
        search: {
          fields: ['client', 'status', 'invited_by_user', 'invitee_role', 'invitee_email'],
          value: ''
        },
        sort: [
          {
            column: 'created_date',
            direction: 'desc'
          }
        ],
        page: 0,
        size: 10,
        sizeOptions: [
          10, 50, 100, 250, 500
        ]
      }
    }),
    created() {
      if (this.client_id)
        this.table.client_id = this.client_id

      if (this.invited_by)
        this.table.invited_by = this.invited_by

      this.handleTableChange()

      this.$root.$refs.userInviteComponent = this
    },
    methods: {
      handleUserRowClick(type, id) {
        if (!id)
          return;

        if (type === 'new')
          return window.open(`/user/${id}`, "_blank")

        this.$router.push(`/user/${id}`)
      },
      handleRequestRowClick(type, id) {
        if (!id)
          return;

        if (type === 'new')
          return window.open(`/request/${id}`, "_blank")

        this.$router.push(`/request/${id}`)
      },
      handleToggleInviteDialog(show = false) {
        this.showInviteDialog = show
      },

      handleClearUserForm() {
        this.fields = {}
        this.errors = {}
        this.UserDialog = false
      },
      handleToggleDialog() {
        if (this.UserDialog) {
          this.fields = {}
          this.errors = {}
        }

        this.UserDialog = !this.UserDialog
      },
      handleEdit(row) {
        if (!row.id)
          return

        this.fields = {...row}
        this.handleToggleDialog()
      },

      handleOnSendInvite() {
        this.handleTableChange()
      },

      async handleApprove(row) {
        if (!row.id)
          return

        const request = await this.$store
          .dispatch('INVITE/approveInvite', {
            invitation_id: row?.id
          })

        if (request) {
          this.handleTableChange()
          this.$notify({
            icon: 'ni ni-check-bold',
            type: 'success',
            title: 'Success',
            message: `Invitation sent to ${row?.invitee_email}`
          })
        } else {
          this.$notify({
            icon: 'fa fa-times',
            type: 'danger',
            title: 'Error',
            message: `Something went wrong!`
          })
        }
      },

      async handleDelete(row) {
        if (!row.id)
          return

        if (!confirm('Are you sure you want to Delete this invite record?'))
          return

        const request = await this.$store
          .dispatch('INVITE/deleteInvite', row)

        if (request) {
          this.handleTableChange()
          this.handleClearUserForm()
          this.$notify({
            icon: 'ni ni-check-bold',
            type: 'success',
            title: 'Success',
            message: `User successfully deleted!`
          })
        } else {
          this.$notify({
            icon: 'fa fa-times',
            type: 'danger',
            title: 'Error',
            message: `Something went wrong!`
          })
        }
      },
      async handleResend(row) {
        if (!row.id)
          return

        if (!confirm('Are you sure you want to Resend an invite to this record?'))
          return
        
        const request = await this.$store
          .dispatch('INVITE/resendInvite', row)

        if (request) {
          this.handleTableChange()
          this.handleClearUserForm()
          this.$notify({
            icon: 'ni ni-check-bold',
            type: 'success',
            title: 'Success',
            message: `Invitation successfully resent!`
          })
        } else {
          this.$notify({
            icon: 'fa fa-times',
            type: 'danger',
            title: 'Error',
            message: `Something went wrong!`
          })
        }
      },
      async handleArchive(row) {
        if (!row.id)
          return

        if (!confirm('Are you sure you want to archive this invite record?'))
          return

        const request = await this.$store
          .dispatch('INVITE/updateInvite', { ...row, status: 'Archived'})

        if (request) {
          this.handleTableChange()
          this.handleClearUserForm()
          this.$notify({
            icon: 'ni ni-check-bold',
            type: 'success',
            title: 'Success',
            message: `User successfully archived!`
          })
        } else {
          this.$notify({
            icon: 'fa fa-times',
            type: 'danger',
            title: 'Error',
            message: `Something went wrong!`
          })
        }
      },
      async handleTableChange() {
        await this.$store.dispatch('INVITE/getInviteList', qs.stringify(omit(this.table, ['sizeOptions'])))
      },
      async getInvitationDetails(inviteID) {
        return await this.$store.dispatch('INVITE/getInviteData', inviteID)
      },
      handleShowDetails(row) {
        document.body.classList.add("modal-open");
        this.showDetailsModal = true
        this.selectedInvite = row
      },
      handleCloseDetailsModal() {
        document.body.classList.remove("modal-open");
        this.showDetailsModal = false
        this.selectedInvite = null
        if (this.$route?.hash) {
          this.$router.push({ name: 'user invite'})
        }
      }
     },
    computed: {
      ...mapState('INVITE', {
        records: state => state.data,
        total: state => state.total,
        loading: state => state.loading
      }),

      ...mapState('AUTH', {
        auth_user: state => state.user,
      }),

      auth_user_id() {
        return this.auth_user?.id
      },

      debounceSetTableLonger() {
        return debounce(this.handleTableChange, 700)
      },
      debounceSetTable() {
        return debounce(this.handleTableChange, 250)
      },
      startCase: () => startCase,
      search: {
        get() {
          return this.table.search.value
        },
        set(keyword) {
          this.table.page = 0
          this.table.search.value = keyword
          this.debounceSetTableLonger()
        }
      },
      size: {
        get() {
          return this.table.size
        },
        set(size) {
          this.table.size = size
          this.debounceSetTable()
        }
      },
      sort: {
        get() {
          return this.table.sort[0]
        },
        set(sort) {
          this.table.sort = [sort]
          this.debounceSetTableLonger()
        }
      },
      pagination: {
        get() {
          return this.table.page + 1
        },
        set(page) {
          this.table.page = page - 1
          this.debounceSetTable()
        }
      }
    },
    watch: {
      change_id() {
        if (this.hasParent)
          this.handleTableChange()
      }
    }
  };
</script>
<style scope>
  .details-container {
    max-height: 70vh;
    overflow-y: scroll;
  }

  .details-container::-webkit-scrollbar {
    display: none;
  }
</style>
