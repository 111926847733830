<template>
  <div :class="`page-request`">
    <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8" />

    <loader :loading="loading" class="mt-4"/>
    <div class="container-fluid mt--8" v-if="!loading">
      <div class="row justify-content-end mb-2">
        <div class="col col-xl-3 col-md-6">
          <base-input
              placeholder="Search"
              class="input-group-alternative"
              alternative=""
              addon-right-icon="fas fa-search"
              v-model="search"
          />
        </div>
        <div class="col col-xl-2 col-md-5">
          <base-button
              block
              class=""
              icon="fa fa-plus"
              type="success"
              @click="handleToggleDialog"
          >Add Question
          </base-button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9 ">
          <TemplateQuestionSection />
        
        </div>
        <div class="col-lg-3">
           <template-client-section />
         
        </div>
      </div>
      <router-view />
    </div>
    <modal :show="TemplateDialog" @close="handleToggleDialog" type="mini">
      <h3 slot="header" class="modal-title" id="modal-title-default">Question</h3>
      <div>
        <base-input
            required
            autoresize
            textarea
            label="Question"
            v-model="fields.question"
            :error="errors.question"
            :valid="!errors.question ? true : false"
            @input="validate('question')"
        />
        <base-input
            autoresize
            textarea
            label="Comment"
            placeholder="e.g. Just let us know where this content will appear"
            v-model="fields.comment"
            :error="errors.comment"
            :valid="!errors.comment ? true : false"
            @input="validate('comment')"
        >
        </base-input>
        <base-input
            number
            label="Order"
            v-model="fields.order"
            :error="errors.order"
            :valid="!errors.order ? true : false"
            @input="validate('order')"
        >
        </base-input>
        <base-checkbox
            v-model="fields.required"
            :error="errors.required"
            :valid="!errors.required ? true : false"
            :checked="fields.required"
            @input="validate('required')"
        >Required this field?
        </base-checkbox>
      </div>
      <template slot="footer">
        <base-button type="link" class="ml-auto btn-radius" @click="handleToggleDialog">Close</base-button>
        <base-button type="purple" class="btn-radius" @click.prevent="handleCreateQuestion">Save changes</base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { columns, validateInput, validateSchema } from '@/container/template-details/model';
import debounce from 'lodash/debounce';
//import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
//import qs from 'qs';
import {mapState } from 'vuex';
import {
  TemplateClientSection,
  TemplateQuestionSection,
} from '@/container/template-details/component';


export default {
  name: 'template-details',
  components: {
    TemplateClientSection,
    TemplateQuestionSection,
  },
  data: () => ({
    TemplateDialog: false,
    fields: {
        question: '',
        comment: '',
        required: false,
        order: '1',
        template_id: '',
       
    },
    errors: {
      question: '',
      comment: '',
      required: '',
      order: '',
    },
    
    columns: columns,
    table: {
      search: {
        fields: ['question', 'comment'],
        value: ''
      },
      sort: [
        {
          column: 'question',
          direction: 'asc'
        }
      ],
      page: 0,
      size: 10,
      sizeOptions: [
        10, 50, 100, 250, 500
      ]
    }
  }),
  created() {
    this.handleGetDetails();
    this.$root.$refs.TemplateDetails = this
  },
  methods: {
    handleClearTemplateForm() {
     this.fields = {  
        question: '',
        comment: '',
        required: false,
      };
      this.errors = {
        question: '',
        comment: '',
        required: '',
      };
      this.TemplateDialog = false;
    },
    handleToggleDialog() {
      this.TemplateDialog = !this.TemplateDialog;
    },
    validate(field) {
      this.errors = validateInput(field, this.fields, this.errors);
    },
    async handleCreateQuestion() {
      let request;
      const { isValid, errors } = validateSchema(this.fields);
       console.log(this.fields);
       console.log(isValid);
        console.log(errors);
      if (!isValid)
        return this.errors = errors;

      if (!this.fields.id){
        this.fields.template_id = this.templateId;
         request = await this.$store
            .dispatch('TEMPLATE/createTemplateQuestion', this.fields);
      }else{
        request = await this.$store
            .dispatch('TEMPLATE/updateTemplateQuestion', this.fields);
      }        
      console.log("THIS",this.fields);
      if (request) {
        this.handleGetDetails();
        this.handleClearTemplateForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Template successfully ${this.fields.id ? 'updated' : 'created'}!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleEdit(row) {
      if (!row.id)
        return;

      this.fields = { ...row };
      this.handleToggleDialog();
    },
    async handleDelete(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to Delete this question?'))
        return;

      const request = await this.$store
          .dispatch('TEMPLATE/deleteTemplateQuestion', row);

      if (request) {
        this.handleGetDetails();
        this.handleClearTemplateForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Question successfully deleted!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
   

    async handleArchive(row) {
      if (!row.id)
        return;

      if (!confirm('Are you sure you want to archive this question?'))
        return;

      const request = await this.$store
          .dispatch('TEMPLATE/updateTemplateQuestion', { ...row, status: 'Archived' });

      if (request) {
        this.handleGetDetails();
        this.handleClearTemplateForm();
        this.$notify({
          icon: 'ni ni-check-bold',
          type: 'success',
          title: 'Success',
          message: `Question successfully archived!`
        });
      } else {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });
      }
    },
    handleGetDetails() {  
       this.$store
          .dispatch('TEMPLATE/getTemplateData', this.$route.params.id);
    },
    
    
  },
  computed: {
    ...mapState('TEMPLATE', {
      template: state => state.template,
      records: state => state.questions,
     //  total: state => state.total,
       loading: state => state.loading,
    }),

    debounceSetTableLonger() {
      return debounce(this.handleGetDetails, 700);
    },
    debounceSetTable() {
      return debounce(this.handleGetDetails, 250);
    },
    templateId() {
      return this.$route.params.id;
    },
    startCase: () => startCase,
    search: {
      get() {
        return this.table.search.value;
      },
      set(keyword) {
        this.table.page = 0;
        this.table.search.value = keyword;
        this.debounceSetTableLonger();
      }
    },
    size: {
      get() {
        return this.table.size;
      },
      set(size) {
        this.table.size = size;
        this.debounceSetTable();
      }
    },
    sort: {
      get() {
        return this.table.sort[0];
      },
      set(sort) {
        this.table.sort = [sort];
        this.debounceSetTableLonger();
      }
    },
    pagination: {
      get() {
        return this.table.page + 1;
      },
      set(page) {
        this.table.page = page - 1;
        this.debounceSetTable();
      }
    }
  }
};
</script>
<style></style>
