import { v4 } from 'uuid';
import * as yup from 'yup';

const userScheme = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  status: yup.string().required(),
  client: yup.object().required(),
  hubstaff_id: yup.string().required()
});

export const validateSchema = data => {
  try {
    userScheme.validateSync(data, { abortEarly: false, allowUnknown: true });
    return {
      errors: {},
      isValid: true,
    };
  } catch (error) {
    const errors = error.inner.reduce((acc, el) => {
      const { path, message } = el;
      acc[path] = message;
      return acc;
    }, {});
    return { isValid: false, errors };
  }
};

export const validateInput = (key, fields, errors) => {
  const { errors: vErrors } = validateSchema(fields);
  return { ...errors, [key]: vErrors[key].replace(/_/g, '').replace('a required field', 'required') };
};

export const columns = [
  {
    name: 'Join Date',
    key: 'created_date',
    sort: true,
  },
  {
    name: 'Firstname',
    key: 'first_name',
    sort: true,
  },
  {
    name: 'Lastname',
    key: 'last_name',
    sort: true,
  },
  {
    name: 'Email',
    key: 'email',
    sort: true,
  },
  {
    name: 'Client',
    key: 'client_name',
    sort: true,
  },
  {
    name: 'Role',
    key: 'role',
    sort: true,
  },
  {
    name: 'Open Project',
    key: 'open_request',
    sort: true,
  },
  {
    name: 'Active Project',
    key: 'active_request',
    sort: true,
  },
  {
    name: 'User Project',
    key: 'total_request_user',
    sort: true,
  },
  {
    name: 'Status',
    key: 'status',
    sort: true,
  },
  {
    name: 'Verified',
    key: 'verified',
    sort: true,
  },
  {
    name: '',
    key: '',
    sort: false,
  },
];

export function userInitialState() {
  return {
    change_id: v4(),
    toggleEdit: false,
    hasChanges: false,
    showUploadDialog: false,
    base64Image: '',
    refFields: {},
    fields: {
      first_name: '',
      last_name: '',
      email: '',
      status: '',
      role: '',
      client: null,
      hubstaff_id: '',
      alwaysAddedToNewProjects: false
    },
    errors: {
      first_name: '',
      last_name: '',
      email: '',
      status: '',
      role: '',
      client: null,
      hubstaff_id: '',
    },
  };
}
